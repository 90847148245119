import React, { useState } from 'react'
import { Col,Row} from "react-bootstrap";
import logo from '../Assets/images/adroitlogo.png'
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { handleError } from "../util/HandleErrors";
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
const Login = () => {
    const [email2, setEmail2] = useState('')
    const [passwordInput, setPasswordinput] = useState('');
    const [loading1, setLoading1] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);

    let navigate = useNavigate()
    const togglePasswordVisibility = () => {
        setPasswordVisible(prev => !prev); // Step 3: Toggle the visibility state
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading1(true);
        if (email2 === 'sales@adroit.com' && passwordInput === 'qwerty123') {
            navigate('/sales')
            localStorage.setItem('token')
        }
        else {
            let body = {
                email: email2,
                password: passwordInput,
            };
            let url = process.env.REACT_APP_BASEURL+ "login/";
            const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json",
                },
            };
            axios
                .post(url, body, config)
                .then((res) => {
                    setLoading1(false);

                    localStorage.setItem(
                        'token', res.data.data.access_token
                    );
                    navigate("/app/dashboard");
                    toast.success("Login successful!", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000,
                        theme: "colored",
                    })

                })
                .catch((error) => {
                    setLoading1(false);
                    if (error !== {}) {
                        const getErrRes = handleError(error);
                        toast.error(getErrRes ? getErrRes : "Something went wrong.", {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 1000,
                            theme: "colored",
                        });
                    }
                })
        }

    };
    return (
        <div style={{ marginBottom: '120px', marginTop: "100px" ,fontFamily:'roboto'}}>
            <div className="d-flex justify-content-center ">
                <Col lg={6} >
                    <div className="card card-body p-5 " style={{ boxShadow: '-1px 1px 15px 4px #9289897a' }}>
                        <form className='p-3' onSubmit={handleSubmit}>
                            <img src={logo} style={{ marginTop: '-40px', height: '60px' }} alt="title" ></img>
                            {/* <h3 className='mt-4'>  Login</h3> */}
                            <div className="form-group d-flex flex-column justify-content-start " style={{ marginTop: '20px' }}>
                                <label style={{ textAlign: 'left' }}>Email:</label>
                                <input
                                    type="email"
                                    required
                                    name='email2'
                                    className="form-control col-12 p-2"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="Enter email"
                                    style={{ height: '50px', }}
                                    value={email2}
                                    onChange={(e) => { setEmail2(e.target.value) }}
                                />
                            </div>
                            <div className="form-group d-flex flex-column  justify-content-start" style={{ marginTop: '-10px' }}>
                                <label style={{ textAlign: 'left' }}>Password:</label>
                                <div className="input-group">
                                    <input
                                        type={passwordVisible ? "text" : "password"}
                                        className="form-control col-12 p-3"
                                        required
                                        id="exampleInputPassword1"
                                        placeholder="Password"
                                        style={{ height: '50px',borderRight:'none' }}
                                        name='passwordInput'
                                        value={passwordInput}
                                        onChange={(e) => {
                                            setPasswordinput(e.target.value);
                                        }}

                                    />
                                    <div className="input-group-append" >
                                        <span className="input-group-text " style={{ height: '50px',borderLeft:'none',marginLeft:'-5px',backgroundColor:'transparent' }} onClick={togglePasswordVisibility}>
                                            {/* Show eye icon based on visibility state */}
                                            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {loading1 ? <button className="btn col-12" disabled style={{ background: '#4CB5E2', color: "white" }} type="submit">  Login...<span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            /></button> : <button className="btn col-12" style={{ background: '#4CB5E2', color: "white" }} type="submit">Login</button>}
                            <Row>
                            <Col style={{ marginTop: '10px', textAlign: 'left' }}>
                                Need an account? <Link to='/register' style={{textDecoration:'none' }}>Sign Up</Link>
                            </Col>
                            <Col style={{ marginTop: '10px', textAlign: 'right'}}>
                                 <Link to='/forgetpassword' style={{textDecoration:'none' }}>Forget Password</Link>
                            </Col>
                            </Row>
                        </form>
                    </div>
                </Col>
            </div>
            <div style={{ zIndex: '9999' }}>

                <ToastContainer />
            </div>
        </div>
    )
}

export default Login
