import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Image_0 from '../../Assets/images/category_0.png'
import Image_1 from '../../Assets/images/category_1.png'
import Image_2 from '../../Assets/images/category_2.png'
import Image_3 from '../../Assets/images/category_3.png'
import { useNavigate } from "react-router";
import { Col } from "react-bootstrap";
import CategorySkeleton from "./skeleton/CategorySkeleton";
import { handleError } from "../../util/HandleErrors";
function AllCategory() {
    const [imageList, seImageList] = useState({
        image0: Image_0,

        image1: Image_1,

        image2: Image_2,

        image3: Image_3

    })
    let [loading, setLoading] = useState(false)
    let navigate = useNavigate()
    let GoToProduct = () => {
        navigate('/productdetail')
    }
    const [categoryList, setCategoryList] = useState([])
    useEffect(() => {
        categoryListData();
    }, [])
    const categoryListData = () => {
        setLoading(true)
        if (localStorage.getItem('token')) {
            let url = process.env.REACT_APP_BASEURL_TWO + "category/"
            const config = {
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json",
                }
            };
            axios.get(url, config)
                .then(res => {

                    setCategoryList(res.data.data)
                    setLoading(false)
                }
                ).catch(err => {
                    setLoading(false)
                    const getErrRes = handleError(err)

                })
        }
        else {
            navigate('/home')
            toast.error(' your session has been expired login again.', {
                autoClose: 1000,
                theme: "colored",
            });
        }
    }

    return (
        <>
            <div>

                {loading ? <CategorySkeleton /> : <div className='row mt-5' >
                    <div class=" flex-wrap d-flex justify-content-start " >
                        {

                            categoryList.length === 0 ? <div className="d-flex justify-content-center mx-auto mt-5"><div className="text-center fs-4 fw-bold">No Data Found</div></div> :
                                categoryList.slice(0, 5).map((prdouct, i) => {
                                    return (<>

                                        <Col lg={2} md={2} sm={3} style={{ margin: '0px', cursor: 'pointer' }} onClick={() => navigate(`/all/${prdouct.public_id}`)} className='d-flex flex-column align-items-center' key={prdouct.public_id} >
                                            <img src={require('../../Assets/images/product_image_5.jpeg')} style={{ background: '#F5F5F5', borderRadius: '50%', padding: '15px', height: '120px', maxWidth: '120px', boxShadow: "0px 97px 39px rgba(0, 0, 0, 0.01), 0px 54px 33px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.04), 0px 6px 13px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)" }}
                                                onClick={() => navigate(`/all/${prdouct.public_id}`)} ></img>
                                            <span style={{ textAlign: 'left', margin: '10px 10px 10px 35px', fontWeight: '500' }}>{prdouct.name}</span>
                                        </Col>


                                    </>)
                                })
                        }
                    </div>
                </div>}
            </div>

        </>
    )
}
export default AllCategory;