import React, { useState } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { IoLocationSharp } from "react-icons/io5";
import { GoMail } from "react-icons/go";
import { FiPhoneCall } from "react-icons/fi";
import Form from 'react-bootstrap/Form';
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';
// import '../style.css'
function Contact() {
    const [loading,setLoading] = useState(false)
    const [addData, setAddData] = useState({
        name: '', nameerr: '',
        email: '', emailerr: '',
        number: null,
        phone_number: '', phone_numbererr: '',
        message: '', messageerr: ''
    })
    const handleSubmit = (e) => {
        e.preventDefault()
        let error = { status: 0, name: '', password: '', email: '' }
        if (addData.name === '') { error.name = 'Please enter name'; error.status = 1 } else { error.name = '' }
        if (addData.phone_number ==='') { error.phone_number = 'Please enter Phone no'; error.status = 1 } else { error.phone_number = '' }
        if (addData.email <= 0 || addData.email === '') { error.email = 'Please enter email'; error.status = 1 } else { error.email = '' }
        setAddData({ ...addData, nameerr: error.name, emailerr: error.email, phone_numbererr: error.phone_number })
        if (error.status === 0) {
            setLoading(true)

            let url = process.env.REACT_APP_BASEURL_TWO +"contact-us/"
            const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json"
                }
            };
            let data = {
                "full_name": addData.name,
                "email": addData.email,
                "phone_number": addData.phone_number,
                "message": addData.message
            };
            axios.post( url,data, config)
                .then(res => {
                    setLoading(false)
                    toast.success("Form Submit successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                        theme: "colored",
                    })
                    window.location.reload();
                })
                .catch(err => {
                    setLoading(false)
                    toast.error(`${err.response.data.error}`, {
                        autoClose: 1000,
                        theme: "colored",
                    });
                })
        }
    }

    return (
        <>
            {/* <div className='pt-5'>

                <h2 className='text-center '>Contact Us</h2>
            </div> */}
            <div className='background_contain'>
                <div className="content-block">
                    <Container className='mt-5 pt-3' style={{fontFamily:'roboto'}}>
                        <div className='d-flex justify-content-center mt-5'>
                            <Row >
                                <Col className='d-flex justify-content-center' >
                                    <Card style={{ width: '18rem', margin: '10px', boxShadow: '1px 2px 9px 0px #808080db' }} >
                                        <div className='d-flex justify-content-center mt-3'>
                                            <IoLocationSharp style={{ color: '#08338f', fontSize: '50px', border: '4px dotted #4cb5e2', borderRadius: '50%', padding: '8px' }}></IoLocationSharp>
                                        </div>

                                        <Card.Body className='text-center'>
                                            <Card.Title style={{fontSize:'16px', fontWeight:'500'}}>Our Plants</Card.Title>
                                            {/* <Card.Text> */}
                                            <li style={{color:'#767676', fontSize:'14px'}}>44-59, Sector-D2, Sanwer Road,Indore- 452015,</li>
                                            <li style={{color:'#767676', fontSize:'14px'}}>  5 B & C, Industrial Area No.2, Dewas- 455002,</li>
                                            <li style={{color:'#767676', fontSize:'14px'}}>99 Smart Industrial Park Near NATRIP , Dhar,454775</li>
                                            {/* </Card.Text> */}

                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className='d-flex justify-content-center' >


                                    <Card style={{ width: '18rem', margin: '10px', boxShadow: '1px 2px 9px 0px #808080db' }} >


                                        <Card.Body className='text-center d-flex flex-column justify-content-center w-100 align-items-center'>
                                            <div className='d-flex justify-content-center'>
                                                <GoMail style={{ color: '#08338f', fontSize: '50px', border: '4px dotted #4cb5e2', borderRadius: '50%', padding: '8px' }} />
                                            </div>


                                            <Card.Title  style={{fontSize:'16px', fontWeight:'500'}}>Email Us</Card.Title>
                                            {/* <Card.Text> */}
                                           <span style={{color:'#767676', fontSize:'14px'}}>emarketing@groupsignet.com</span> 
                                            {/* </Card.Text> */}

                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className='d-flex justify-content-center' >


                                    <Card style={{ width: '18rem', margin: '10px', boxShadow: '1px 2px 9px 0px #808080db' }} >


                                        <Card.Body className='text-center d-flex flex-column justify-content-center w-100 align-items-center'>
                                            <div className='d-flex justify-content-center'>
                                                <FiPhoneCall style={{ color: '#08338f', fontSize: '50px', border: '4px dotted #4cb5e2', borderRadius: '50%', padding: '8px' }} />
                                            </div>


                                            <Card.Title  style={{fontSize:'16px', fontWeight:'500'}}>Call Us</Card.Title>
                                            {/* <Card.Text> */}
                                           <span style={{color:'#767676', fontSize:'14px'}}>+91 90987 06668</span> 
                                            {/* </Card.Text> */}

                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>


                        </div>
                        <div>
                            <Row className='mt-5 p-0' >
                                <Col lg={6} md={12} className='mt-3 mb-5'>
                                    
                                    <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1nCtaiEvshtKcUGRZ7xMKX4rkNrnmp_M&ehbc=2E312F&hl=en" frameborder="0" title="map" style={{ border: "0", width: "100%", height: "530px", boxShadow: '1px 2px 9px 0px #808080db' }} allowfullscreen></iframe>
                                    {/* <iframe class="mb-4 me-md-3 me-sm-0  mb-lg-0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d377565.5946626543!2d77.04157704532086!3d28.454198800142823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x52c2b7494e204dce!2sNew%20Delhi%2C%20Delhi!5e0!3m2!1sen!2sin!4v1677491129413!5m2!1sen!2sin"
                             frameborder="0" style={{ border: "0", width: "100%", height: "530px", boxShadow: '1px 2px 9px 0px #808080db' }} allowfullscreen></iframe> */}


                                </Col>

                                <Col lg={6} md={12} className='mt-3 mb-5'>
                                    <Card className='ms-md-4 ms-sm-0' style={{ boxShadow: '1px 2px 9px 0px #808080db' }}>
                                        <Card.Body className='text-left'>
                                            <h5>Get in Touch</h5>
                                            <Form onSubmit={handleSubmit}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label style={{fontSize:'16px', fontWeight:'500'}}>Full Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter full name"
                                                        defaultValue={addData.name} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, name: e.target.value, nameerr: '' }) : setAddData({ ...addData, name: '', nameerr: 'name  is required.' }) }} />
                                                    <small className="text-danger">{addData.nameerr}</small>
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label style={{fontSize:'16px', fontWeight:'500'}}>Email address</Form.Label>
                                                    <Form.Control type="email" placeholder="Enter email" 
                                                        defaultValue={addData.email} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, email: e.target.value, emailerr: '' }) : setAddData({ ...addData, email: '', emailerr: 'email  is required.' }) }} />
                                                    <small className="text-danger">{addData.emailerr}</small>
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                                    <Form.Label style={{fontSize:'16px', fontWeight:'500'}}>Phone Number</Form.Label>
                                                    <Form.Control type="number" placeholder="Enter phone number"
                                                     maxLength={10} value={addData.phone_number} onChange={(e) => { e.target.value !== '' || e.target.value.length > e.target.maxLength ? setAddData({ ...addData, phone_number: e.target.value.slice(0, e.target.maxLength), phone_numbererr: '' }) : setAddData({ ...addData, phone_number: '', phone_numbererr: "Number is required" }) }} />
                                                    <small className="text-danger">{addData.phone_numbererr}</small>
                                                  
                                                </Form.Group>

                                                <Form.Group className="mb-3" >
                                                    <Form.Label style={{fontSize:'16px', fontWeight:'500'}}>Message</Form.Label>
                                                    <textarea defaultValue={addData.message} onChange={(e) => { setAddData({ ...addData, message: e.target.value }) } } className='form-control p-4' placeholder='Enter message'></textarea>
                                                </Form.Group>
                                                <div className='d-flex justify-content-center'>
                                                    <Button style={{ background: '#0F328E', border: '#4cb5e2', }} type="submit">
                                                        Submit
                                                    </Button>
                                                </div>

                                            </Form>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div></div>
        </>
    )

}
export default Contact;