import Button from 'react-bootstrap/Button';
import React from "react";
import { RxDoubleArrowRight } from "react-icons/rx";
import { BsFillEyeFill } from "react-icons/bs";
import { Card, Col, Row, Table, } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup'
import { Input } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

function DashboardSales() {
    let navigate = useNavigate()
    return (<>
        <div >


            <div className="d-flex justify-content-between col-10 mt-3" style={{ paddingLeft: "30px" }}>
                <div style={{ fontWeight: 'bold', color: '#0F328E', width: '200px', textAlign: 'left' }}>Sales Dashboard</div>
                <div style={{ fontWeight: 'bold', display: 'flex', cursor: 'pointer' }} ><Button style={{ background: "#0F328E", boder: '#0F328E' }} onClick={() => { navigate('/adroitmainsales') }}>Continue <RxDoubleArrowRight /></Button></div>
            </div>
            <Row className='col-10  mt-5 ' style={{ padding: '0px 0px 0px 22px' }} >

                <Col  >
                    <Card className='d-flex flex-row p-0' style={{ width: '248px', height: '90px', boxShadow: 'rgb(0 0 0 / 20%) 2px 3px 7px 1px', borderRadius: '10px' }}>
                        <div style={{ textAlign: "left" }} className='d-flex flex-column col-8'>
                            <span style={{ fontWeight: 'bold', fontSize: '14px', marginTop: '15px' }}>Sales</span>
                            <span style={{
                                fontWeight: 'bold', color: "#FF7555", fontSize: '22px'
                            }}>${Math.floor(Math.random() * 100)}.00</span>
                        </div>
                        <div className='col-4 mt-1'>
                            <img src={require('../../../Assets/images/Icon_1.png')}></img>
                        </div>


                    </Card>
                </Col>
                <Col >
                    <Card className='d-flex flex-row p-0' style={{ width: '248px', height: '90px', boxShadow: 'rgb(0 0 0 / 20%) 2px 3px 7px 1px', borderRadius: '10px' }}>
                        <div style={{ textAlign: "left" }} className='d-flex flex-column col-8'>
                            <span style={{ fontWeight: 'bold', fontSize: '14px', marginTop: '15px' }}>Sales Target </span>
                            <span style={{
                                fontWeight: 'bold', color: "#FF7555", fontSize: '22px'
                            }}>${Math.floor(Math.random() * 100)}.00 </span>
                        </div>
                        <div className='col-4 mt-1'>
                            <img src={require('../../../Assets/images/icon_2.png')}></img>
                        </div>


                    </Card>
                </Col>
                <Col >
                    <Card className='d-flex flex-row p-0' style={{ width: '248px', height: '90px', boxShadow: 'rgb(0 0 0 / 20%) 2px 3px 7px 1px', borderRadius: '10px' }}>
                        <div style={{ textAlign: "left" }} className='d-flex flex-column col-8'>
                            <span style={{ fontWeight: 'bold', fontSize: '14px', marginTop: '15px' }}>Target Achieved </span>
                            <span style={{
                                fontWeight: 'bold', color: "#FF7555", fontSize: '22px'
                            }}>70%</span>
                        </div>
                        <div className='col-4 mt-1'>
                            <img src={require('../../../Assets/images/icon_3.png')}></img>
                        </div>


                    </Card>
                </Col>
                <Col>
                    <Card className='d-flex flex-row p-0' style={{ width: '248px', height: '90px', boxShadow: 'rgb(0 0 0 / 20%) 2px 3px 7px 1px', borderRadius: '10px' }}>
                        <div style={{ textAlign: "left" }} className='d-flex flex-column col-8'>
                            <span style={{ fontWeight: 'bold', fontSize: '14px', marginTop: '15px' }}>Profit</span>
                            <span style={{
                                fontWeight: 'bold', color: "#FF7555", fontSize: '22px'
                            }}>${Math.floor(Math.random() * 100)}.00</span>
                        </div>
                        <div className='col-4 mt-1'>
                            <img src={require('../../../Assets/images/icon_4.png')}></img>
                        </div>


                    </Card>
                </Col>
            </Row>
            <div className='mt-5 col-10'>
                <div className='text-left fw-bold'>Order Details</div>
                <Table
                    className="no-wrap mt-3 align-middle border-top"
                    responsive
                    borderless
                >
                    <thead>
                        <tr>
                            <th>Select</th>
                            <th>Part Number</th>
                            <th>Quantity</th>
                            <th>Order Date</th>
                            <th>Shipping Date</th>
                            <th>Order Status </th>
                            <th>View More</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr className="border-top">
                            <td>  <Input  type="checkbox" style={{position:'static'}} /> </td>
                            <td>H0014443433</td>
                            <td>50</td>
                            <td>02 Feb 2023</td>
                            <td>05 Feb 2023</td>
                            <td style={{ color: "#FF3F8E" }}>Placed</td>
                            <td><BsFillEyeFill /></td>
                        </tr>
                        <tr className="border-top">
                            <td>     <Input  type="checkbox" style={{position:'static'}} /> </td>
                            <td>H0014443433</td>
                            <td>100</td>
                            <td>02 Feb 2023</td>
                            <td>05 Feb 2023</td>
                            <td style={{ color: '#F79B4E' }}>In transit</td>
                            <td><BsFillEyeFill /></td>
                        </tr>
                        <tr className="border-top">
                            <td>     <Input  type="checkbox" style={{position:'static'}} /> </td>
                            <td>H0014443433</td>
                            <td>80</td>
                            <td>02 Feb 2023</td>
                            <td>05 Feb 2023</td>
                            <td style={{ color: '#4ADBBD' }}>Delivered</td>
                            <td><BsFillEyeFill /></td>
                        </tr>
                        <tr className="border-top">
                            <td>     <Input  type="checkbox" style={{position:'static'}} /> </td>
                            <td>H0014443433</td>
                            <td>70</td>
                            <td>02 Feb 2023</td>
                            <td>05 Feb 2023</td>
                            <td style={{ color: "#FF3F8E" }}>Placed</td>
                            <td><BsFillEyeFill /></td>
                        </tr>



                    </tbody>
                </Table>
            </div>
        </div>
    </>)
}
export default DashboardSales;