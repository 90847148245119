import logo from "./logo.svg";
import "./App.css";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Content from "./Content/Content";
import { Route, Routes, useRoutes } from "react-router-dom";
import Register from "./Content/Register";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./Components/Profile/Profile";
import PrivateRoutes from "./util/PrivateRoutes";
import About from "./Content/About";
import Categories from "./WebApp/WebComponets/Categories";
import AdroitMainPage from "./WebApp/WebComponets/AdroitMainPage";
import ProductDetail from "./WebApp/WebComponets/ProductDetail";
import Themeroutes from "./WebApp/WebComponets/Router/Router";
import ScrollToTop from "./Content/ScrollToTop";
import { Provider } from "react-redux";
import { store } from "./Redux/app/store";



function App() {
  const routing = useRoutes(Themeroutes);
  return (
    <Provider store={store}>
    <div className="App">
      <ScrollToTop />
      {routing}

      <div style={{ zIndex: '9999' }}>

        <ToastContainer />
      </div>


    </div>
    </Provider>
  );
}

export default App;
