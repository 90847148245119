import React from 'react'
import { Row, Col } from 'react-bootstrap'


const Manufacturing = () => {
    return (
        <div>
            <div style={{ marginTop: '40px', fontFamily: 'roboto' }} className='container-fluid'>
                <div className='background_contain2'>
                    <div className="content-block">
                        <div>
                            <div className="section-full call-action " >



                                <div style={{ padding: '20px', }}>
                                    <div className="col-lg-12 section-head text-center mt-2 mb-4">
                                        <h4 className="title">Manufacturing</h4>
                                        {/* <div className="dlab-separator-outer">
                                            <div className="dlab-separator bg-primary style-skew" />
                                        </div> */}
                                    </div>
                                    <div>
                                        <p style={{ color: '#0F328E', fontSize: '21px', fontFamily: 'roboto' }}>Adroit Industries has the advantage of being backward integrated with a forging facility.</p>
                                    </div>
                                    {/* <div className="col-lg-12 section-head text-center mt-3">
                                        <h4 className="title">Forging Division</h4>
                                        <div className="dlab-separator-outer">
                                            <div className="dlab-separator bg-primary style-skew" />
                                        </div>
                                    </div> */}
                                    <Row className='d-flex justify-content-center col-12 p-5'>
                                        <Col lg={4} md={10} className='d-flex justify-content-center '  >
                                            <img src={require("../../Assets/images/forgingdivision.png")} style={{ aspectRatio: '16/9' }}></img>

                                        </Col>
                                        <Col lg={6} md={12} className='d-flex flex-column justify-content-center  ' >
                                            <div className=" d-flex flex-column justify-content-center text-justify " >
                                                <p style={{ fontSize: '24px', fontFamily: 'roboto', fontWeight: '500', marginLeft: '20px' }}>Forging Division</p>
                                                <p className="about-inner-content" style={{ color: '#767676', fontSize: '14px' }}>
                                                    <ul>
                                                        <li style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }} className='mt-2'> <span style={{ color: '#4A4949', fontWeight: '500', fontSize: '18px' }}> Induction Billet Heating:</span> <br></br>
                                                            Adroit utilizes induction billet heating technology. This
                                                            process involves heating the raw material (billet) using an induction heating
                                                            system. The controlled heating ensures uniform temperature distribution, which
                                                            is crucial for the forging process.</li>
                                                        <li className='mt-2' style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }} ><span style={{ color: '#4A4949', fontWeight: '500', fontSize: '18px' }}>Belt-Drop Hammers and Pneumatic Hammers:</span> <br></br>
                                                            Adroit&#39;s forging facility is
                                                            equipped with belt-drop hammers and pneumatic hammers. The hammers allow
                                                            for precise forging and can handle a range of billet sizes and weights.</li>
                                                        <li className='mt-2' style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }} > <span style={{ color: '#4A4949', fontWeight: '500', fontSize: '18px' }}>Presses: </span> <br></br>
                                                            Adroit&#39;s forging division is equipped with presses. Press forging offers
                                                            excellent control and accuracy, making it suitable for complex shapes and tight
                                                            tolerances.</li>

                                                    </ul>
                                                </p>
                                            </div>
                                        </Col>
                                        <Col className='col-10 '>
                                            <ul style={{ textAlign: 'justify' }}>
                                                <li className='mt-2' style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }}><span style={{ color: '#4A4949', fontWeight: '500', fontSize: '18px' }}>n-House Forging Die Manufacturing: </span><br></br>
                                                    Adroit has in-house capabilities for
                                                    manufacturing forging dies.By having own die manufacturing facility, Adroit has
                                                    greater control over the quality and timeline of die production, ensuring efficient
                                                    forging operations.</li>
                                                <li className='mt-2' style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }}><span style={{ color: '#4A4949', fontWeight: '500', fontSize: '18px' }}>Heat Treatment Facility:</span><br></br>
                                                    Adroit&#39;s forging division includes a heat treatment
                                                    facility. Heat treatment is a critical process that alters the material properties of
                                                    the forged components, improving their strength, hardness, and durability. The
                                                    facility is equipped with normalizing furnaces, quenching, stress relieving etc.
                                                    which provide controlled heating and cooling cycles to achieve the desired
                                                    material properties.</li>
                                                <li className='mt-2' style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }}><span style={{ color: '#4A4949', fontWeight: '500', fontSize: '18px' }}>Shot Blasting Facilities:</span><br></br>
                                                    Adroit&#39;s forging division also features shot blasting
                                                    facilities. This process removes scale, oxides, and other surface impurities,
                                                    resulting in a clean and smooth surface finish.</li>
                                                <li className='mt-2' style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }}> <span style={{ color: '#4A4949', fontWeight: '500', fontSize: '18px' }}>Testing Facility: </span><br></br>By conducting these rigorous tests and inspections, Adroit
                                                    maintains stringent quality control throughout the forging process. The testing of
                                                    input steel, crack detection, microstructure testing, and hardness testing help
                                                    identify any potential issues and ensure that the final forged products meet the
                                                    required quality standards.</li>
                                            </ul></Col>
                                    </Row>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', }}>
                                    <div className='col-10'>
                                        <div style={{ background: '#F2F7FA', }} >
                                            <div className="col-lg-12 section-head text-center mt-3 pt-5 mb-2">
                                                <div style={{ fontSize: '32px', fontWeight: '500', fontFamily: 'roboto' }}>Machining and Assembly Division</div>

                                            </div>
                                            <div>
                                                <div style={{
                                                    color: '#0F328E', fontSize: '22px', fontFamily: 'roboto'
                                                }}>Adroit Industries boasts an impressive infrastructure that enables the manufacturing of
                                                    a wide range of forged products.</div>
                                            </div>
                                            <Row className='d-flex justify-content-center col-12 mt-4'>

                                                <Col lg={7} md={12} className='d-flex flex-column justify-content-center ' >
                                                    <div className=" d-flex flex-column justify-content-center text-justify " >
                                                        {/* <h4 className="title">Forging Division</h4> */}
                                                        <p className="about-inner-content" style={{ fontSize: '18px' }}>
                                                            <ul>
                                                                <li className='mt-2' style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }}> <span style={{ color: '#4A4949', fontWeight: '500', fontSize: '18px' }}> Machinery:</span> <br></br>
                                                                    Adroit utilizes a combination of state-of-the-art modern CNC
                                                                    machines, Special Purpose Machines (SPMs), and traditional machines. This
                                                                    diverse set of machinery allows them to successfully produce over4000
                                                                    dimensionally different parts. Adroit have various Horizontal Machining Centers
                                                                    (HMCs), Vertical Machining Centers (VMCs), 4-spindle CNC machines, CNC
                                                                    Special Purpose Machines (SPMs), broaching/hobbing machines, CNC cylindrical
                                                                    grinders, and a Grob Machine for spline rolling, CNC controlled Induction
                                                                    hardening machines.</li>
                                                                <li className='mt-2' style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }}><span style={{ color: '#4A4949', fontWeight: '500', fontSize: '18px' }}>Assembly and Balancing:</span> <br></br>
                                                                    Adroit performs assembly, straightening, and balancing
                                                                    of its shafts to G-6.3 standards at speeds of up to 4500 rpm. Adroit also
                                                                    balances its individual components for its clients to save time during assembly
                                                                    the process of the driveshafts. This ensures that the shafts are properly aligned,
                                                                    balanced, and meet the required specifications.</li>


                                                            </ul>

                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col lg={5} md={10} className='d-flex justify-content-center '  >
                                                    <img src={require("../../Assets/images/Assembly.png")}></img>

                                                </Col>
                                                <Col>
                                                    <ul style={{ textAlign: 'justify', fontSize: '15px', fontFamily: 'roboto', color: '#767676' }}>
                                                        <li className='mt-2'> <span style={{ color: '#4A4949', fontWeight: '500', fontSize: '18px' }}>Quality Control: </span> <br></br>
                                                            Adroit maintains a remarkable record of 99.8% quality approval
                                                            from its clients. This high level of quality is achieved through rigorous quality
                                                            control measures. Adroit employs various tools and instruments such as
                                                            Coordinate Measuring Machines (CMM), TRIMOS, Magnetic Particle Inspection
                                                            (MPI), dynamic balancing equipment, straightening equipment, fatigue testing
                                                            machines, and a wide range of measuring instruments and functional gauges.
                                                            These measures ensure that the products meet the required quality standards.
                                                            By combining advanced machinery, precision assembly and balancing processes, and
                                                            stringent quality control measures, Adroit Industries ensures the production of high-
                                                            quality forged products. The commitment to maintaining high standards is reflected in
                                                            its impressive quality approval ratings from clients.</li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                                {/* <div>
                                    <h4 style={{ color: '#4A4949', marginTop: '35px' }}>Propeller Shaft Assembly Unit-2 Nos. Special purpose</h4>
                                </div>
                                <div className="col-lg-12 section-head text-center">
                                    <h4 className="title">VMCS/CNCS/HMCS</h4>
                                    <div className="dlab-separator-outer">
                                        <div className="dlab-separator bg-primary style-skew" />
                                    </div>
                                </div> */}
                                {/* <Row className="">
                                    <Col lg={6} md={12} className='d-flex flex-column justify-content-center p-3' >
                                        <div className=" d-flex flex-column justify-content-center text-justify " >
                                            <p className="about-inner-content" style={{ fontSize: '18px' }}>
                                                <div className='d-flex justify-content-between col-sm-12'>
                                                    <div className='col-sm-12 col-md-6 col-lg-6'>
                                                        <ul>
                                                            <li className='mt-2'>Special purpose machines </li>
                                                            <li className='mt-2'>Boring machines </li>
                                                            <li className='mt-2'>Induction hardening system </li>
                                                            <li className='mt-2'>Copy turning lathes </li>
                                                            <li className='mt-2'>Glide coating system</li>
                                                            <li className='mt-2'>Broaching/hobbing machines</li>
                                                            <p>Cylindrical grinders</p>
                                                        </ul>
                                                    </div>
                                                    <div className='col-sm-12 col-md-6 col-lg-6'>
                                                        <ul >
                                                            <li className='mt-2'>CNC machines</li>
                                                            <li className='mt-2'> Dynamic balancing machines</li>
                                                            <li className='mt-2'>Tracer lathes</li>
                                                            <li className='mt-2'>Milling & drilling machines</li>
                                                            <li className='mt-2'>Grob spline rolling machine</li>
                                                            <li className='mt-2'> Special purpose 4-spindle CNCs.</li>
                                                        </ul>
                                                    </div>

                                                </div>
                                            </p>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={10} className='d-flex justify-content-center '  >
                                        <img src={require("../../Assets/images/manufacturing3.jpg")}></img>

                                    </Col>
                                </Row> */}
                            </div>
                            {/* <div style={{ backgroundColor: '#d1cdcd3d', padding: '30px', marginTop: '20px' }}>
                                <ManufactureTabs />
                            </div> */}
                        </div>
                    </div>
                </div>
                {/* <div style={{ padding: '20px' }}>
                    <i style={{ color: '#4A4949', fontWeight: 'bold', fontSize: '18px' }}>We have fully accredited ISO9001 status and produce shafts which are dynamically balanced using ISO1940 specifications</i>
                </div> */}

            </div>
        </div>
    )
}

export default Manufacturing
