import React from 'react'
import { Row, Col } from 'react-bootstrap'


const Quality = () => {
    return (
        <div>
            <div style={{ marginTop: '40px', fontFamily: 'roboto' }} className='container-fluid'>
                <div className=''>
                    <div className="content-block">
                        <div>
                            <div className="section-full call-action " >



                                <div style={{ padding: '20px', }}>
                                    <div className="col-lg-12 section-head text-center mt-2 mb-4">
                                        <h4 className="title">Quality</h4>
                                        {/* <div className="dlab-separator-outer">
                                            <div className="dlab-separator bg-primary style-skew" />
                                        </div> */}
                                    </div>
                                    <div className='container'>
                                        <p style={{ color: '#0F328E', fontSize: '21px', fontFamily: 'roboto' }}>Adroit Industries (India) Ltd. is an IATF 16949 certified company, indicating its commitment to quality and continuous improvement. Adroit prioritizes meeting or exceeding customer expectations by implementing established standards and measurements. Here are key points about quality processes:</p>
                                    </div>
                                    {/* <div className="col-lg-12 section-head text-center mt-3">
                                        <h4 className="title">Forging Division</h4>
                                        <div className="dlab-separator-outer">
                                            <div className="dlab-separator bg-primary style-skew" />
                                        </div>
                                    </div> */}
                                    <Row className='d-flex justify-content-center col-12 p-5'>
                                        <Col lg={4} md={10} className='d-flex justify-content-center '  >
                                            <img src={require("../../Assets/images/quality_1.png")} style={{ width: '90%' }}></img>

                                        </Col>
                                        <Col lg={6} md={12} className='d-flex flex-column justify-content-center  ' >
                                            <div className=" d-flex flex-column justify-content-center text-justify " >
                                                <p className="about-inner-content" style={{ fontSize: '18px' }}>
                                                    <ul>
                                                        <li style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }} className='mt-2'> <span style={{ color: '#4A4949', fontWeight: '500', fontSize: '18px' }}>Quality Awareness and Process Improvement:</span> <br></br>
                                                            Adroit develops quality awareness through continuous process improvements, employee training, and measurement techniques. We aim to enhance the level of quality in products and processes, ensuring customer satisfaction.</li>
                                                        <li className='mt-2' style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }} ><span style={{ color: '#4A4949', fontWeight: '500', fontSize: '18px' }}>Verifying and Validating Manufacturing Processes:</span> <br></br>
                                                            Adroit places importance on verifying and validating both existing and new manufacturing processes. This ensures that the processes are reliable, efficient, and meet the required quality standards.</li>
                                                        <li className='mt-2' style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }} > <span style={{ color: '#4A4949', fontWeight: '500', fontSize: '18px' }}>Researching Improvements: </span> <br></br>
                                                            The company actively engages in researching improvements to enhance the quality of it’s products and processes. This demonstrates our commitment to staying at the forefront of advancements in their industry and continually raising the quality bar.</li>

                                                    </ul>
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div style={{ display: 'flex', justifyContent: 'center', }}>
                                        <div className='col-10'>
                                            <div style={{ background: '#F2F7FA', }} >
                                                <Row className='d-flex justify-content-center col-12 mt-4'>

                                                    <Col lg={7} md={12} className='d-flex flex-column justify-content-center ' >
                                                        <div className=" d-flex flex-column justify-content-center text-justify " >
                                                            {/* <h4 className="title">Forging Division</h4> */}
                                                            <p className="about-inner-content" style={{ color: '#767676', fontSize: '14px' }}>
                                                                <ul>
                                                                    <li className='mt-2' style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }}> <span style={{ color: '#4A4949', fontWeight: '500', fontSize: '18px' }}>Involvement of Employees and Customers :</span> <br></br>
                                                                        Adroit involves its employees and customers in defining and improving their products. This collaborative approach helps gather valuable insights and perspectives, leading to better products and customer satisfaction.</li>
                                                                    <li className='mt-2' style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }}><span style={{ color: '#4A4949', fontWeight: '500', fontSize: '18px' }}>Internal Audits and Management Reviews :</span> <br></br>
                                                                        Adroit conducts regular internal quality audits and management reviews. These activities enable the ongoing evaluation and improvement of our quality system. By proactively identifying areas for enhancement, we ensure the effectiveness of their quality processes.</li>
                                                                    <li className='mt-2' style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }}><span style={{ color: '#4A4949', fontWeight: '500', fontSize: '18px' }}>Comprehensive Quality Planning :</span> <br></br>
                                                                        Adroit incorporates quality planning into its processes. This includes tooling and process development, product-specific control plans, and training plans for all employees. We also conduct verification and validation of all products before shipment to ensure they meet the required quality standards.</li>

                                                                </ul>

                                                            </p>
                                                        </div>
                                                    </Col>
                                                    <Col lg={5} md={10} className='d-flex justify-content-center '  >
                                                        <img src={require("../../Assets/images/quality_2.jpg")} className='p-4' ></img>

                                                    </Col>
                                                    <Col>
                                                        <p className='text-justify ' style={{ color: '#767676', fontFamily: 'roboto', fontSize: '15px' }}>
                                                            Overall, Adroit Industries (India) Ltd. demonstrates commitment to quality through its
                                                            certified quality management system, focus on continuous improvement, involvement
                                                            of employees and customers, and comprehensive quality planning. These measures
                                                            contribute to its ability to deliver world-class products and customer service.
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Quality
