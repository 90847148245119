import React, { useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';
const Career = () => {
    const [loading, setLoading] = useState(false)
    const [addData, setAddData] = useState({
        name: '', nameerr: '',
        post: '', posterr: '',

    })
    const [sendImage2, setSendImage2] = useState(false);
    let [sendImage, setSendImage] = useState(false);
    let [image, setImage] = useState("");
    const handleSubmit = (e) => {

        e.preventDefault()
        let error = { status: 0, name: '', resume: '', post: '' }
        if (addData.name === '') { error.name = 'Please enter name'; error.status = 1 } else { error.name = '' }
        if (addData.post === '') { error.post = 'Please enter post'; error.status = 1 } else { error.post = '' }
        if (addData.resume === '') { error.resume = 'Please enter resume'; error.status = 1 } else { error.resume = '' }
        setAddData({ ...addData, nameerr: error.name, posterr: error.post, resumeerr: error.resume })

        if (error.status === 0) {
            setLoading(true)
            const formData = new FormData();
            if (!(sendImage2 === false)) {

                formData.append("resume", sendImage2);
            }
            formData.append("name", addData.name);
            formData.append("position", addData.post);
            let url = process.env.REACT_APP_BASEURL_TWO + "career/"
            const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "multipart/form-data",

                }
            };
            axios.post(url, formData, config)
                .then(res => {
                    setLoading(false)
                    toast.success("Form Submit successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                        theme: "colored",
                    })
                    window.location.reload();
                })
                .catch(err => {
                    setLoading(false)
                    toast.error(`${err.response.data.error}`, {
                        autoClose: 1000,
                        theme: "colored",
                    });
                })
        }

    }
    let imageHandler2 = (e) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setImage(reader.result);
            }
            setSendImage2(e.target.files[0])
        }
        reader.readAsDataURL(e.target.files[0])
    };
    return (
        <>
            <ToastContainer></ToastContainer>
            <div style={{ marginTop: '100px', fontFamily: 'roboto' }}>
                <div className='background_contain'>
                    <div className="">
                        <div className="col-lg-12 section-head text-center mt-5">
                            <h5 style={{ fontSize: '30px' }}>Career</h5>
                            {/* <div className="dlab-separator-outer">
                            <div className="dlab-separator bg-primary style-skew" />
                        </div> */}

                        </div>
                        <div>

                        </div>
                        <div className='d-flex justify-content-center'>
                            <Row className="d-flex align-items-center p-0 justify-content-center col-10" style={{ marginBottom: '80px' }}>
                                <Col lg={4} className='d-flex justify-content-center  align-items-center' >
                                    <img src={require('../../Assets/images/career.jpeg')} style={{  aspectRatio: '' }}></img>
                                </Col>
                               
                                <Col lg={6} className='d-flex flex-column justify-content-center p-4'>

                                    <p className='text-justify' style={{ color: '#767676', fontSize: '14px' }}>
                                        <span style={{ color: '#4A4949', fontSize: '18px', textAlign: 'justify' }}> Why You Want to Choose Adroit</span><br></br>
                                        Adroit takes great pride in delivering to its customers the utmost quality service and the latest advancements in innovation. To ensure the fulfilment of this commitment, Adroit places a strong emphasis on attracting and hiring
                                        exceptional talent to fulfill a diverse range of core business roles. These roles encompass various areas such as engineering,design, production, and business support.
                                        The Adroit team pays a crucial role in an expanding business, actively utilizing new and emerging technologies to create intricate solutions for customers who have earned esteemed reputations in the industry. Our contributions to their achievements  throughout the last century have elevated us beyond the status of a mere employer, as we provide team members with gratifying experiences that they can take pride in. We firmly believe that our people are our most valuable resource, and we are dedicated to treating them as such on a daily basis.
                                        Adroit takes pride in offering team members a comprehensive range of employment benefits that enrich their professional lives. These perks include competitive compensation packages, engaging programs to foster team member involvement and recognition, as well as community outreach initiatives that allow team members to make a positive impact beyond the workplace.</p>
                                </Col>
                            
                            </Row>
                        </div>
                        {/* <div className='d-flex justify-content-center mt-5'>
                            <Row sclassName='d-flex align-items-center p-0 justify-content-center col-10 '>
                                <Col lg={4} md={10} className='d-flex justify-content-center  align-items-center'  >
                                    <img src={require('../../Assets/images/career.jpeg')} alt="history" style={{ maxHeight: '360px', aspectRatio: '16/9' }}></img>

                                </Col>

                                <Col lg={6} md={12} className='d-flex flex-column justify-content-center p-3'  >
                                    <span style={{ fontSize: '20px', fontWeight: '500', color: '#4a4949', textAlign: 'left' }}>Adroit History</span>
                                    <div className=" d-flex flex-column justify-content-center text-justify " >
                                        <p style={{ fontSize: '14px', color: '#767676' }}>
                                            Adroit takes great pride in delivering to its customers the utmost quality service and the latest advancements in innovation. To ensure the fulfilment of this commitment, Adroit places a strong emphasis on attracting and hiring exceptional talent to fulfill a diverse range of core business roles. These roles encompass various areas such as engineering,design, production, and business support. The Adroit team pays a crucial role in an expanding business, actively utilizing new and emerging technologies to create intricate solutions for customers who have earned esteemed reputations in the industry. Our contributions to their achievements throughout the last century have elevated us beyond the status of a mere employer, as we provide team members with gratifying experiences that they can take pride in. We firmly believe that our people are our most valuable resource, and we are dedicated to treating them as such on a daily basis. Adroit takes pride in offering team members a comprehensive range of employment benefits that enrich their professional lives. These perks include competitive compensation packages, engaging programs to foster team member involvement and recognition, as well as community outreach initiatives that allow team members to make a positive impact beyond the workplace.
                                        </p>
                                    </div>
                                </Col>

                            </Row>
                        </div> */}

                        <Form onSubmit={handleSubmit} className='d-flex justify-content-center '>
                            <Row className='col-10 p-5' style={{ background: '#f2f7fa', marginBottom: '50px' }}>


                                <Col sm={12} lg={4}>
                                    <Form.Group className="m-1  text-justify" >
                                        <Form.Label style={{ fontSize: '16px', fontWeight: '500' }}>Full Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter full name"
                                            defaultValue={addData.name} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, name: e.target.value, nameerr: '' }) : setAddData({ ...addData, name: '', nameerr: 'name  is required.' }) }} />
                                        <small className="text-danger ">{addData.nameerr}</small>
                                    </Form.Group>
                                </Col>
                                <Col sm={12} lg={4}>
                                    <Form.Group className="m-1 text-justify" controlId="formBasicEmail">
                                        <Form.Label style={{ fontSize: '16px', fontWeight: '500' }}>Post Applied For</Form.Label>
                                        <Form.Control type="text" placeholder="Enter post for applied"
                                            defaultValue={addData.post} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, post: e.target.value, posterr: '' }) : setAddData({ ...addData, post: '', posterr: 'post  is required.' }) }} />
                                        <small className="text-danger">{addData.posterr}</small>
                                    </Form.Group>
                                </Col>
                                <Col sm={12} lg={4}>
                                    <Form.Group className="m-1 text-justify" controlId2="formBasicEmail">
                                        <Form.Label style={{ fontSize: '16px', fontWeight: '500' }}>Upload Resume Here</Form.Label>
                                        <Form.Control onChange={imageHandler2} accept=".png,.pdf,image/*" className="form-control " id="formFileMultiple" type="file" />
                                    </Form.Group>
                                </Col><br></br>
                                <Row >
                                    <div className='d-flex justify-content-center mt-2 '>
                                        <Button className="btn-lg" style={{ background: '#0F328E', border: '#0F328E', }} type="submit">
                                            Send
                                        </Button>
                                    </div>
                                </Row>



                            </Row>
                        </Form>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Career
