import React from "react";
import { Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { MdOutlineArrowForwardIos } from "react-icons/md";
function CategorySkeleton() {
    return (
        <>
            <div >
               
               
                <div className='row mt-5' style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                    <div class=" flex-wrap d-flex justify-content-start " >

                        <Col lg={2} md={2} sm={3} style={{ margin: '5px' }} className='d-flex flex-column'  >
                            <Skeleton style={{ background: '#F5F5F5', borderRadius: '50%', padding: '15px', height: '120px', maxWidth: '120px', boxShadow: "0px 97px 39px rgba(0, 0, 0, 0.01), 0px 54px 33px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.04), 0px 6px 13px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)" }}></Skeleton>
                        </Col>
                        <Col lg={2} md={2} sm={3} style={{ margin: '5px' }} className='d-flex flex-column'  >
                            <Skeleton style={{ background: '#F5F5F5', borderRadius: '50%', padding: '15px', height: '120px', maxWidth: '120px', boxShadow: "0px 97px 39px rgba(0, 0, 0, 0.01), 0px 54px 33px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.04), 0px 6px 13px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)" }}></Skeleton>
                        </Col>
                        <Col lg={2} md={2} sm={3} style={{ margin: '5px' }} className='d-flex flex-column'  >
                            <Skeleton style={{ background: '#F5F5F5', borderRadius: '50%', padding: '15px', height: '120px', maxWidth: '120px', boxShadow: "0px 97px 39px rgba(0, 0, 0, 0.01), 0px 54px 33px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.04), 0px 6px 13px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)" }}></Skeleton>
                        </Col>
                        <Col lg={2} md={2} sm={3} style={{ margin: '5px' }} className='d-flex flex-column'  >
                            <Skeleton style={{ background: '#F5F5F5', borderRadius: '50%', padding: '15px', height: '120px', maxWidth: '120px', boxShadow: "0px 97px 39px rgba(0, 0, 0, 0.01), 0px 54px 33px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.04), 0px 6px 13px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)" }}></Skeleton>
                        </Col>
                        <Col lg={2} md={2} sm={3} style={{ margin: '5px' }} className='d-flex flex-column'  >
                            <Skeleton style={{ background: '#F5F5F5', borderRadius: '50%', padding: '15px', height: '120px', maxWidth: '120px', boxShadow: "0px 97px 39px rgba(0, 0, 0, 0.01), 0px 54px 33px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.04), 0px 6px 13px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)" }}></Skeleton>
                        </Col>

                    </div>
                </div>
               
            </div>
        </>
    )
}
export default CategorySkeleton;