import React from "react";
import Lottie from "lottie-react"
import Error from '../../../Assets/images/404_lottie.json'
import { useNavigate } from "react-router-dom";
function NotFound(){
    let navigate = useNavigate()
    return(
        <>
            <Lottie animationData={Error} style={{height:'70vh'}} />
            <div className="d-flex flex-column justify-content-center">
                <h2 >Page Not Found</h2>
                <p>You can search for the page you want here
                or return to the homepage.</p>
                <div>
                    <button className="btn btn-lg  mb-2" style={{ backgroundColor:'#08338f',color:'white'}} onClick={()=>{navigate('/home')}}> GO HOME</button>
                </div>
               
            </div>
           
        </>
    )
}
export default NotFound;