import { createSlice } from "@reduxjs/toolkit";

const initialState = {    
    cartQauntity:0,
};
export const cartSlice = createSlice({
      name:"cartSlice",
      initialState,
    reducers:
    {
        updateCartQty:(state,action)=>{
            state.cartQauntity= action.payload
        }
        
    }
   
}
)
export const{updateCartQty}= cartSlice.actions;
export default cartSlice.reducer