import React, { useState } from 'react'
import { Row, Col, Button, Card } from "reactstrap";
import "./fundamental.css"
const Fundamentals = () => {
    const [showMore, setShowMore] = useState(false);
    const [showMore2, setShowMore2] = useState(false);
    const [mission, setMission] = useState("Adroit strives to be a leading global manufacturer of propeller shafts and components, providing high - quality products to the automotive industry and other sectors.We are committed to continuous  improvement, innovation,& delivering exceptional value to our customers.Through investment in modern infrastructure, adoption of advanced technologies, and a focus on new product development and aesthetics, we aim to exceed customer expectations and maintain our position as a trusted supplier.Our mission is to be a system - driven organization that sets industry standards, contributes to the growth of the Indian manufacturing sector, and proudly serves clients worldwide")
    const [vission, setVission] = useState('If you believe you can, you probably can. If you believe you want not  you most assuredly want not. Belief is the ignition switch that gets you off the launching pad. — Denis Waitley. Adroit, under the leadership of Chairman and Managing Director Mr. Mukesh Sangla, envisions power/torque transmission as a key growth area for the group. With dedicated efforts, hard work, investments in niche technology, product development, and global market expansion, the company aims to be among the most admired corporate entities globally and make India proud.')
    return (
        <div style={{ marginBottom: '50px', fontFamily: 'roboto' }}>
            <div className='background_contain2'>
                {/* <Row style={{ paddingTop: '100px' }}>               
                <Col lg={12} md={12} className='d-flex flex-column justify-content-center p-3' >
                    <h2 className="title text-center"> OUR MISSION</h2>
                    <div className=" d-flex flex-column justify-content-center  " >
                        <p className="about-inner-content" style={{ fontSize: '15px', }}>
                            Adroit strives to be a leading global manufacturer of propeller shafts and
                            components, providing high-quality products to the automotive industry and other
                            sectors. We are committed to continuous <br></br> improvement, innovation, and
                            delivering exceptional value to our customers. Through investment in modern

                            infrastructure, adoption of advanced technologies, <br></br>and a focus on new product
                            development and aesthetics, we aim to exceed customer expectations and
                            maintain our position as a trusted supplier. Our mission is to be a system-driven
                            organization that sets industry standards, contributes to the growth of the Indian
                            manufacturing sector, and proudly serves clients worldwide</p>
                    </div>
                </Col>
            </Row>
            <Row className="pb-1" style={{ backgroundColor: '#d1cdcd3d', paddingLeft: '100px' }}>
                <Col lg={12} md={12} className='d-flex flex-column justify-content-center p-3' >
                    <h2 className="title text-center"> OUR VISION</h2>
                    <div className=" d-flex flex-column justify-content-center" >
                        <p className="about-inner-content" style={{ fontSize: '15px', }}>
                            &quot;If you believe you can, you probably can. If you believe you won&#39;t, you most
                            assuredly won&#39;t. Belief is the ignition switch that gets you off the launching pad.&quot;
                            — Denis Waitley.<br></br>
                            Adroit, under the leadership of Chairman and Managing Director Mr. Mukesh
                            Sangla, envisions power/torque transmission as a key growth area for the group.
                            With dedicated efforts, hard work, investments in niche technology,<br></br> product
                            development, and global market expansion, the company aims to be among the
                            most admired corporate entities globally and make India proud.</p>

                    </div>
                </Col>
               

            </Row> */}
                {/* <Row style={{ paddingTop: '100px' }}>
                <Col lg={6} sm={10} md={10} className="d-flex justify-content-center ">
                    <div class="card-space col-10 col-sm-12 col-md-12 col-lg-10">
                        <div class="card1  col-12 ">
                            <div class="face front d-flex justify-content-center align-items-center">
                                <h1>OUR MISSION</h1>
                            </div>
                            <div class="face back ">
                                <p className="about-inner-content font text-justify" style={{  }}>
                                    Adroit strives to be a leading global manufacturer of propeller shafts and
                                    components, providing high-quality products to the automotive industry and other
                                    sectors. We are committed to continuous  improvement, innovation,&
                                    delivering exceptional value to our customers. Through investment in modern
                                    infrastructure, adoption of advanced technologies,and a focus on new product
                                    development and aesthetics, we aim to exceed customer expectations and
                                    maintain our position as a trusted supplier.Our mission is to be a system-driven
                                    organization that sets industry standards,contributes to the growth of the Indian
                                    manufacturing sector, and proudly serves clients worldwide</p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg={6} sm={10} md={10} className="d-flex justify-content-center " >
                    <div class="card-space col-10 col-sm-12 col-md-12 col-lg-10">
                        <div class="card1  col-12 ">
                            <div class="face front d-flex justify-content-center align-items-center">
                                <h1>OUR VISION</h1>
                            </div>
                            <div class="face back">
                                <p className="about-inner-content font text-justify" >
                                    &quot;If you believe you can, you probably can. If you believe you won&#39;t, you most
                                    assuredly won&#39;t. Belief is the ignition switch that gets you off the launching pad.&quot;
                                    — Denis Waitley.
                                    Adroit, under the leadership of Chairman and Managing Director Mr. Mukesh
                                    Sangla, envisions power/torque transmission as a key growth area for the group.
                                    With dedicated efforts, hard work, investments in niche technology, product
                                    development, and global market expansion, the company aims to be among the
                                    most admired corporate entities globally and make India proud.</p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row> */}

                <Row style={{ paddingTop: '120px' }} className=' d-flex justify-content-center'>
                    <Col className='d-flex justify-content-center m-2' lg={5}>
                        <Card style={{ background: '#dcf0fa', border: '0px', borderRadius: '0px' }}>

                            <img src={require('../../src/Assets/images/our_mission.png')} style={{ aspectRatio: '16/9' }}></img>


                            <p style={{ fontSize: '15px', textAlign: 'justify', padding: '20px', color: '#767676', marginBottom: '0px' }}>
                                {!showMore ? <>
                                    {mission.substring(0, 120)}<span style={{ marginLeft: '10px', fontWeight: 'bold', color: '#0f328e', fontSize: "15px", cursor: 'pointer' }} onClick={() => { setShowMore(!showMore) }}>Read More......</span>
                                </> : <>{mission}<span style={{ marginLeft: '10px', fontWeight: 'bold', color: '#0f328e', fontSize: "15px", cursor: 'pointer' }} onClick={() => { setShowMore(!showMore) }}>Read Less</span> </>}
                            </p>

                        </Card>
                    </Col>
                    <Col className='d-flex justify-content-center m-2' lg={5}>
                        <Card style={{ background: '#cfd7e9', border: '0px', borderRadius: '0px' }}>

                            <img src={require('../../src/Assets/images/our_vision.png')}></img>
                            <p style={{ fontSize: '15px', textAlign: 'justify', padding: '20px', color: '#767676' }} >
                                {!showMore2 ? <>
                                    {vission.substring(0, 120)}<span style={{ marginLeft: '10px', fontWeight: 'bold', color: '#0f328e', fontSize: "15px", cursor: 'pointer' }} onClick={() => { setShowMore2(!showMore2) }}>Read More......</span>
                                </> : <>{vission}<span style={{ marginLeft: '10px', fontWeight: 'bold', color: '#0f328e', fontSize: "15px", cursor: 'pointer' }} onClick={() => { setShowMore2(!showMore2) }}>Read Less</span> </>}</p>
                        </Card>
                    </Col>
                </Row>
                <div style={{ fontSize: '32px', fontWeight: '500', margin: '40px' }}>Adroit's work culture and philosophy:</div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Row style={{ padding: '30px', background: '#F2F7FA' }} className='col-10'>
                        <Col lg={5} md={10} className='d-flex justify-content-center '  >
                            <img src={require('../../src/Assets/images/newteam.png')} alt="philosopy" style={{ height: '80%', aspectRatio: '16/9' }} ></img>
                        </Col>
                        <Col lg={7} md={12} className='d-flex flex-column justify-content-center pt-3' >
                            <div className=" d-flex flex-column justify-content-center text-justify " >
                                <p className="about-inner-content" style={{ fontSize: '15px', }}>
                                    <ul>
                                        <li style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }}> <b style={{ color: '#4A4949', fontWeight: '500', fontSize: '18px' }}>Synergistic Talent:</b> <br></br>Adroit&#39;s work culture aims to synchronize and
                                            leverage the diverse talents within the organization toward a common goal.
                                            By bringing together individuals with various skills and expertise, the
                                            company strives to create a collaborative environment that maximizes the
                                            potential of its workforce.</li>
                                        <li style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }}>
                                            <b style={{ color: '#4A4949', fontWeight: '500', fontSize: '18px' }}> Professional Integrity: </b> <br></br>Adroit values professional integrity and
                                            emphasizes sound management practices. Upholding high ethical
                                            standards and maintaining transparency in operations form the foundation
                                            of the company&#39;s work culture. This commitment to integrity helps build
                                            trust among stakeholders and ensures long-term success.
                                        </li>
                                        <li style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }}>
                                            <b style={{ color: '#4A4949', fontWeight: '500', fontSize: '18px' }}>Freedom of Thought and Expression:</b><br></br> Adroit believes in fostering an
                                            environment that encourages freedom of thought and expression. By
                                            giving employees the freedom to voice their ideas, opinions, and
                                            perspectives, the company aims to unlock the full potential of each

                                            individual. This approach fosters innovation, creativity, and personal
                                            growth, ultimately leading to better results.
                                        </li>
                                        {/* <li>
                                    <b style={{ color: '#0F328E', fontWeight: '500', fontSize: '18px' }}>Risk Management:</b>  Adroit recognizes the importance of risk management
                                    in business. The company places due weightage on assessing and
                                    mitigating risks at every stage of its operations. By implementing effective
                                    risk control measures, Adroit ensures that potential risks are identified and
                                    managed proactively, minimizing any adverse impacts on the business.
                                </li> */}
                                    </ul>

                                </p>
                            </div>
                        </Col>

                        <Col >
                            <ul style={{ textAlign: 'justify' }}>
                                <li style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }}>
                                    <b style={{ color: '#4A4949', fontWeight: '500', fontSize: '18px' }}>Risk Management:</b>  <br></br>Adroit recognizes the importance of risk management
                                    in business. The company places due weightage on assessing and
                                    mitigating risks at every stage of its operations. By implementing effective
                                    risk control measures, Adroit ensures that potential risks are identified and
                                    managed proactively, minimizing any adverse impacts on the business.
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default Fundamentals
