import React from 'react'

function Footer() {
	let currentYear = new Date()
	let year = currentYear.getFullYear()
	return (
		<div>

			{/* Footer */}
			<footer className="site-footer ">
				<div className="footer-top" style={{ backgroundImage: 'url(images/pattern/pt15.png)' }}>
					<div className="container">
						<div className="row">
							<div className="col-md-6 col-xl-6 col-lg-6 col-sm-6 footer-col-4 col-12">
								<div className="widget widget_about border-0">
									<div className="footer-title">
										<h5 className="title text-white">ABOUT US</h5>
										<div className="dlab-separator-outer">
											<div className="dlab-separator bg-primary style-skew" />
										</div>
									</div>
									<p>The Signet Group, despite a humble beginning in 1985, is one of the fastest growing professionally owned enterprises in India. The flagship company, Signet Industries Ltd., started its trade with various chemicals and polymers under the leadership of Mr. Mukesh Sangla in 1980s. Today, the Signet Group portfolio is well diversified into fields of Petrochemicals, Polymers Trading and Distribution, Generation of Electricity, production of plastics house-hold, hardware, automotive, furniture, spray pumps, pipes and fittings and drip irrigation products and last but not the least, it has large interest in production of Drive-shaft components at Adroit .</p>
								</div>
							</div>
							{/* <div className="col-md-6 col-7 col-xl-3 col-lg-3 col-sm-6 footer-col-4 col-12"> */}
								{/* <div className="widget border-0 recent-posts-entry">
									<div className="footer-title">
										<h5 className="title text-white">LATEST POST</h5>
										<div className="dlab-separator-outer">
											<div className="dlab-separator bg-primary style-skew" />
										</div>
									</div>
									<div className="widget-post-bx">
										<div className="widget-post clearfix">
											<div className="dlab-post-media">
												<img src="https://tradezone.dexignzone.com/xhtml/images/blog/recent-blog/pic2.jpg" width={200} height={143} alt="" />
											</div>
											<div className="dlab-post-info">
												<div className="dlab-post-header">
													<h6 className="post-title"><a href="javascript:void(0);">Helping you and your house become better.</a></h6>
												</div>
												<div className="dlab-post-meta">
													<ul>
														<li className="post-author">By <a href="javascript:void(0);">Jone</a></li>
														<li className="post-comment"> 28</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="widget-post clearfix">
											<div className="dlab-post-media">
												<img src="https://tradezone.dexignzone.com/xhtml/images/blog/recent-blog/pic1.jpg" width={200} height={160} alt="" />
											</div>
											<div className="dlab-post-info">
												<div className="dlab-post-header">
													<h6 className="post-title"><a href="javascript:void(0);">Creating quality urban lifestyles.</a></h6>
												</div>
												<div className="dlab-post-meta">
													<ul>
														<li className="post-author">By <a href="javascript:void(0);">Jone</a></li>
														<li className="post-comment"> 28</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div> */}
							{/* </div> */}
							{/* <div className="col-md-6 col-xl-3 col-lg-3 col-sm-6 footer-col-4 col-12">
								<div className="widget widget_services border-0">
									<div className="footer-title">
										<h5 className="title text-white">COMPANY</h5>
										<div className="dlab-separator-outer">
											<div className="dlab-separator bg-primary style-skew" />
										</div>
									</div>
									<ul className="">
										<a href="javascript:void(0);">About Us</a><br></br><br></br>
										<a href="javascript:void(0);">Blog</a><br></br><br></br>
										<a href="javascript:void(0);">Services</a><br></br><br></br>
										<a href="javascript:void(0);">Privacy Policy</a><br></br><br></br>
									</ul>
								</div>
							</div> */}
							<div className="col-md-6 col-xl-6 col-lg-6 col-sm-6 footer-col-4 col-12">
								<div className="widget">
									<div className="footer-title">
										<h5 className="title text-white">GET IN TOUCH</h5>
										<div className="dlab-separator-outer">
											<div className="dlab-separator bg-primary style-skew" />
										</div>
									</div>
									<ul className="thsn-timelist-list d-lg-flex d-block ">
									<div>
											<p style={{ fontWeight: '700', color: '#4CB5E2' }}>Plant 1</p>
											<li> 44-59, Sector-D2, Sanwer Road,Indore- 452015 (M.P.),India Ph.: +91-90987-06668 emarketing@groupsignet.com</li>
									</div>&nbsp;&nbsp;
										<div>
											<p style={{ fontWeight: '700', color: '#4CB5E2' }}>Plant 2</p>
											<li>5 B & C, Industrial Area No.2, Dewas- 455002,(M.P.) India</li>
										</div>&nbsp;&nbsp;
										<div>
											<p style={{ fontWeight: '700', color: '#4CB5E2' }}>Plant 3</p>
											<li>99 Smart Industrial Park Near NATRIP , Dhar,454775, (M.P.) India</li>
										</div>
									
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* footer bottom part */}
				<div className="footer-bottom">
					<div className="container">
						<div className="row">
							<div className="col-12 text-left "> <span>© Copyright {year} Adroit Industries (India) Ltd.. All rights reserved.</span> </div>
							{/* <div className="col-md-6 col-sm-6 text-right ">
								<div className="widget-link ">
									<ul>
										<li><a href="javascript:void(0);"> About</a></li>
										<li><a href="javascript:void(0);"> Help Desk</a></li>
										<li><a href="javascript:void(0);"> Privacy Policy</a></li>
									</ul>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</footer>
			{/* Footer END*/}
		</div>
	)
}

export default Footer
