import React, { useEffect, useState } from "react";
import { Button, Col, Row, Card } from "react-bootstrap";
import { AiOutlineShoppingCart, AiOutlineHeart } from "react-icons/ai";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import StarRatings from "react-star-ratings";
import { Input } from "reactstrap";
import Productimg from '../../Assets/images/product_1.png';
import SleeveYolk1 from '../../Assets/images/sleevyoke1.PNG';
import SleeveYolk2 from '../../Assets/images/sleevyoke2.PNG';
import Sleevyoke3 from '../../Assets/images/sleevyoke3.PNG';
import Endyoke1 from '../../Assets/images/endyoke1.PNG';
import Endyoke2 from '../../Assets/images/endyoke2.PNG';
import Endyoke3 from '../../Assets/images/endyoke3.PNG';
import Endyoke4 from '../../Assets/images/endyoke4.PNG';
import Slipshaft from '../../Assets/images/slipshaft1.PNG';
import FlangeYoke1 from '../../Assets/images/flangeyoke1.PNG';
import FlangeYoke2 from '../../Assets/images/flangeyoke2.PNG';
import FlangeYoke3 from '../../Assets/images/flangeyoke3.PNG';
import CVFlangeyoke from '../../Assets/images/cvflangeyoke.PNG';
import YokeShaft1 from '../../Assets/images/yokeshaft1.PNG';
import YokeShaft2 from '../../Assets/images/yokeshaft2.PNG';
import YokeShaft3 from '../../Assets/images/yokeshaft3.PNG';
import MidShaft1 from '../../Assets/images/midshaft1.PNG';
import MidShaft2 from '../../Assets/images/midshaft2.PNG';
import Splinedsleeve from '../../Assets/images/splinedsleeve.PNG';
import TubeYoke1 from '../../Assets/images/tubeyoke1.PNG';
import TubeYoke2 from '../../Assets/images/tubeyoke2.PNG';
import TubeYoke3 from '../../Assets/images/tubeyoke3.PNG';
import CampanionFlange1 from '../../Assets/images/sfcampanion1.PNG';
import CampanionFlange2 from '../../Assets/images/slfcampanion2.PNG';
import ShortCouple from '../../Assets/images/SAEshortcouple.PNG';
import HoleCampanion5 from '../../Assets/images/4holecampanion5.PNG';
import CampanionFlange3 from '../../Assets/images/roundcampanion3.PNG';
import Groovetype from '../../Assets/images/groove_type.PNG';
import SlipJoint from '../../Assets/images/slip_joint.PNG';
import SlipStubShaft from '../../Assets/images/slip_stub_shaft.PNG';
import Cseries from '../../Assets/images/c_series_sy.PNG';
import CseriesTy from '../../Assets/images/c_series_ty.PNG';
import CseriesYs from '../../Assets/images/c_series_ys.PNG';
import CseriesSj from '../../Assets/images/slip_joint_c_series.PNG';
import CseriesShortcouple from '../../Assets/images/short_couple_c_series.PNG';
import CenterYoke from '../../Assets/images/center_yoke.PNG';
import CvTubeyoke from '../../Assets/images/cv_tube_yoke.PNG';
import CvFlangeyoke from '../../Assets/images/cv_flange_yoke.PNG';
import SarratedFlange from '../../Assets/images/serrated_flange_yoke.PNG';
import CVheadTypeA from '../../Assets/images/CVheadA.PNG';
import CVheadTypeB from '../../Assets/images/CVheadB.PNG';
import DashboardProduct from "./DashboardProduct";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { updateCartQty } from "../../Redux/feature/cartSlice";
import Dropdown from 'react-bootstrap/Dropdown';
import RelatedDummy from "../../Assets/images/relatedDummy.jpg"
// import ReactImageMagnify from "react-image-magnify";

function ProductDetail() {

    const dispatch = useDispatch()
    // const getCart = useSelector(state => state.allcart)

    let { id } = useParams()
    let [detail, setDetail] = useState({})
    const [cartList, setCartlist] = useState([])
    const [img1, setImg1] = useState({ images: '' })
    const [img2, setImg2] = useState({ images: '' })
    const [img3, setImg3] = useState({ images: '' })
    const [img4, setImg4] = useState({ images: '' })
    const [img5, setImg5] = useState({ images: '' })
    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(false)
    let navigate = useNavigate()
    useEffect(() => {
        productDetail();
    }, [reload])
    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const productDetail = () => {
        let url = process.env.REACT_APP_BASEURL_TWO + `products/${id}/`
        const config = {
            headers: {
                "Authorization": localStorage.getItem("token"),
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
            }
        };
        setLoading(true)
        axios.get(url, config)
            .then(res => {
                setDetail(res.data.data)
                setImg1(res.data.data.images[0])
                setImg2(res.data.data.images[1])
                setImg3(res.data.data.images[2])
                setImg4(res.data.data.images[3])
                setImg5(res.data.data.images[4])
                setLoading(false)
            }
            ).catch(err => {
                setLoading(false)
            })
    }
    const addtoCart = (public_id) => {


        setLoading(true)

        let url = process.env.REACT_APP_BASEURL_TWO + `cart/`
        const config = {
            headers: {
                "Authorization": localStorage.getItem("token"),
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
            }
        };
        const body = {
            cart_data: ([
                {
                    product_id: public_id,
                    quantity: 1,
                },
                // Add more objects if needed
            ]),
        };
        axios.post(url, body, config)
            .then(res => {
                setLoading(false)
                navigate("/showallcart");
            }

            ).catch(err => {
                setLoading(false)
            })
        getCartlist()

    }
    const getCartlist = () => {
        setLoading(true)
        if (localStorage.getItem('token')) {
            let url = process.env.REACT_APP_BASEURL_TWO + `cart/`
            const config = {
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json",
                }
            };
            axios.get(url, config)
                .then(res => {
                    setLoading(false)
                    setCartlist(res.data.data.item_list)
                    // localStorage.setItem('count', res.data.data.length+1)
                    dispatch(updateCartQty(res.data.data.item_list.length))
                }
                ).catch(err => {
                    // console.log(err)
                    setLoading(false)
                })
        }
        else {
            navigate('/home');
            toast.error(' your session has been expired login again.', {
                autoClose: 1000,
                theme: "colored",
            });
        }
    }

    return (
        <>
            <div style={{ marginTop: '100px', marginBottom: '180px', fontFamily: 'roboto' }}>
                {loading ? <div className="d-flex justify-content-center"><div>
                    <img src={require('../../Assets/images/loading-waiting.gif')} alt="loading"></img>
                </div></div> :
                    <Row className="m-0 col-11">
                        {/* <Col md={12} lg={5} style={{backgroundColor:"#ededed"}}>
                        <img src={Productimg} alt="product" className="mt-5"></img>
                        <Row className="ms-0 me-0 mt-5 d-flex justify-content-center">                           
                        </Row>
                    </Col> */}
                        {selectedImage === null ?
                            <Col md={12} lg={6} className="mt-5 justify-content-center"  >


                                {
                                    detail.fig === "1.0" && detail.category === 322 ?
                                        <img src={SleeveYolk1} alt="sleevyoke" style={{ height: '250px', width: '450px' }}></img>
                                        :
                                        detail.fig === "2.0" && detail.category === 322 ?
                                            <img src={SleeveYolk2} alt="sleevyoke" style={{ height: '270px', width: '450px' }}></img>
                                            :
                                            detail.fig === "3.0" && detail.category === 322 ?
                                                <img src={Sleevyoke3} alt="sleevyoke" style={{ height: '250px', width: '450px' }}></img>
                                                :
                                                detail.fig === "1.0" && detail.category === 323 ?
                                                    <img src={Endyoke1} alt="endyoke" style={{ height: '250px', width: '450px' }}></img>
                                                    :
                                                    detail.fig === "2.0" && detail.category === 323 ?
                                                        <img src={Endyoke2} alt="endyoke" style={{ height: '250px', width: '450px' }}></img>
                                                        :
                                                        detail.fig === "3.0" && detail.category === 323 ?
                                                            <img src={Endyoke3} alt="endyoke" style={{ height: '250px', width: '450px' }}></img>
                                                            :
                                                            detail.fig === "4.0" && detail.category === 323 ?
                                                                <img src={Endyoke4} alt="endyoke" style={{ height: '270px', width: '450px' }}></img>
                                                                :
                                                                detail.category === 325 ?
                                                                    <img src={Slipshaft} alt="sleevyoke" style={{ height: '250px', width: '450px' }}></img>
                                                                    :
                                                                    detail.fig === "1" && detail.category === 321 ?
                                                                        <img src={FlangeYoke1} alt="flangeyoke" style={{ height: '270px', width: '470px', }}></img>
                                                                        :
                                                                        detail.fig === "2" && detail.category === 321 ?
                                                                            <img src={FlangeYoke2} alt="flangeyoke" style={{ height: '270px', width: '470px' }}></img>
                                                                            :
                                                                            detail.fig === "3" && detail.category === 321 ?
                                                                                <img src={FlangeYoke3} alt="flangeyoke" style={{ height: '270px', width: '470px' }}></img>
                                                                                :
                                                                                detail.fig === "BLANK" && detail.category === 321 ?
                                                                                    <img src={FlangeYoke1} alt="flangeyoke" style={{ height: '270px', width: '470px' }}></img>
                                                                                    :
                                                                                    detail.fig === "CVTYP" && detail.category === 321 ?
                                                                                        <img src={CVFlangeyoke} alt="flangeyoke" style={{ height: '270px', width: '470px' }}></img>
                                                                                        :
                                                                                        detail.fig === "1" && detail.category === 328 ?
                                                                                            <img src={YokeShaft1} alt="yokeshaft" style={{ height: '250px', width: '450px' }}></img>
                                                                                            :
                                                                                            detail.fig === "2" && detail.category === 328 ?
                                                                                                <img src={YokeShaft2} alt="yokeshaft" style={{ height: '250px', width: '450px' }}></img>
                                                                                                :
                                                                                                detail.fig === "3" && detail.category === 328 ?
                                                                                                    <img src={YokeShaft3} alt="yokeshaft" style={{ height: '250px', width: '450px' }}></img>
                                                                                                    :
                                                                                                    detail.fig === "1.0" && detail.category === 326 ?
                                                                                                        <img src={MidShaft1} alt="midshaft" style={{ height: '250px', width: '450px' }}></img>
                                                                                                        :
                                                                                                        detail.fig === "2.0" && detail.category === 326 ?
                                                                                                            <img src={MidShaft2} alt="midshaft" style={{ height: '250px', width: '450px' }}></img>
                                                                                                            :
                                                                                                            detail.category === 327 ?
                                                                                                                <img src={Splinedsleeve} alt="splinedsleeve" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                :
                                                                                                                detail.fig === "1.0" && detail.category === 324 ?
                                                                                                                    <img src={TubeYoke1} alt="tubeyoke" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                    :
                                                                                                                    detail.fig === "2.0" && detail.category === 324 ?
                                                                                                                        <img src={TubeYoke2} alt="tubeyoke" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                        :
                                                                                                                        detail.fig === "3.0" && detail.category === 324 ?
                                                                                                                            <img src={TubeYoke3} alt="tubeyoke" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                            :
                                                                                                                            detail.fig === "1" && detail.category === 320 ?
                                                                                                                                <img src={CampanionFlange1} alt="campanion" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                :
                                                                                                                                detail.fig === "2" && detail.category === 320 ?
                                                                                                                                    <img src={CampanionFlange2} alt="campanion" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                    :
                                                                                                                                    detail.fig === "5" && detail.category === 320 ?
                                                                                                                                        <img src={HoleCampanion5} alt="campanion" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                        :
                                                                                                                                        detail.fig === "3" && detail.category === 320 ?
                                                                                                                                            <img src={CampanionFlange3} alt="campanion" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                            :
                                                                                                                                            detail.category === 331 ?
                                                                                                                                                <img src={ShortCouple} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img> :
                                                                                                                                                detail.internal_category === "TY-GWB" && detail.category === 329 ?
                                                                                                                                                    <img src={Groovetype} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img> :
                                                                                                                                                    detail.internal_category === "FY Serrated - GWB" && detail.category === 329 ?
                                                                                                                                                        <img src={SarratedFlange} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                        : detail.internal_category === "SJ-GWB" && detail.category === 329 ?
                                                                                                                                                            <img src={SlipJoint} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                            : detail.internal_category === "C-SSS" && detail.category === 330 ?
                                                                                                                                                                <img src={SlipStubShaft} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                                : detail.internal_category === "C-SY" && detail.category === 330 ?
                                                                                                                                                                    <img src={Cseries} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                                    : detail.internal_category === "C-TY" && detail.category === 330 ?
                                                                                                                                                                        <img src={CseriesTy} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                                        : detail.internal_category === "C-YS" && detail.category === 330 ?
                                                                                                                                                                            <img src={CseriesYs} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                                            : detail.internal_category === "C-SJ" && detail.category === 330 ?
                                                                                                                                                                                <img src={CseriesSj} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                                                : detail.internal_category === "C-SC" && detail.category === 330 ?
                                                                                                                                                                                    <img src={CseriesShortcouple} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                                                    : detail.internal_category === "H-Yoke-Center Yoke" && detail.category === 333 ?
                                                                                                                                                                                        <img src={CenterYoke} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                                                        : detail.internal_category === "Ball Stud Yoke1" && detail.category === 333 ?
                                                                                                                                                                                            <img src={CvTubeyoke} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                                                            : detail.internal_category === "Ball Stud Yoke" && detail.category === 333 ?
                                                                                                                                                                                                <img src={CvTubeyoke} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                                                                : detail.internal_category === "Center Yoke" && detail.category === 333 ?
                                                                                                                                                                                                    <img src={CvFlangeyoke} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                                                                    : detail.internal_category === "1310 CV Head" && detail.category === 333 ?
                                                                                                                                                                                                        <img src={CVheadTypeA} alt="cvhead" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                                                                        : detail.internal_category === "1350 CV Head" && detail.category === 333 ?
                                                                                                                                                                                                            <img src={CVheadTypeB} alt="cvhead" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                                                                            :
                                                                                                                                                                                                            img1 ?
                                                                                                                                                                                                                <img src={img1} alt="cvhead" style={{ height: '250px', width: '450px' }}></img> :
                                                                                                                                                                                                                <img src={Productimg} alt="cvhead" style={{ height: '250px', width: '450px' }}></img>


                                }

                                <Row className='d-flex justify-content-center mt-3'>
                                    {img1 ? <Col className="col-2 ms-2"> <img src={img1} style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: '80px' }} alt="product" onClick={() => handleImageClick(img1)}></img></Col> : null}
                                    {img2 ? <Col className="col-2 ms-2"> <img src={img2} style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: '80px' }} alt="product" onClick={() => handleImageClick(img2)}></img></Col> : null}
                                    {img3 ? <Col className="col-2 ms-2"> <img src={img3} style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: '80px' }} onClick={() => handleImageClick(img3)} alt="product"></img></Col> : null}
                                    {img4 ? <Col className="col-2 ms-2"> <img src={img4} style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(img4)} alt="product"></img></Col> : null}

                                </Row>
                            </Col> :

                            <Col md={12} lg={6}>

                                {selectedImage ? <img src={selectedImage} alt="Selected" style={{ height: '270px', width: '470px' }} /> : <img src={Productimg} alt="product" style={{ height: '270px', width: '470px' }} />}
                                <Row className='d-flex justify-content-center mt-3'>
                                    {img1 ? <Col className="col-2 ms-2"> <img src={img1} style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: '80px' }} alt="product" onClick={() => handleImageClick(img1)}></img></Col> : null}
                                    {img2 ? <Col className="col-2 ms-2"> <img src={img2} style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: '80px' }} alt="product" onClick={() => handleImageClick(img2)}></img></Col> : null}
                                    {img3 ? <Col className="col-2 ms-2"> <img src={img3} style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: '80px' }} onClick={() => handleImageClick(img3)} alt="product"></img></Col> : null}
                                    {img4 ? <Col className="col-2 ms-2"> <img src={img4} style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(img4)} alt="product"></img></Col> : null}
                                    <Col className="col-2 ms-2">
                                        {
                                            detail.fig === "1.0" && detail.category === 322 ?

                                                <img src={SleeveYolk1} alt="sleevyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(SleeveYolk1)}></img>
                                                :
                                                detail.fig === "2.0" && detail.category === 322 ?
                                                    <img src={SleeveYolk2} alt="sleevyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(SleeveYolk2)}></img>
                                                    :
                                                    detail.fig === "3.0" && detail.category === 322 ?
                                                        <img src={Sleevyoke3} alt="sleevyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Sleevyoke3)}></img>
                                                        :
                                                        detail.fig === "1.0" && detail.category === 323 ?
                                                            <img src={Endyoke1} alt="endyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Endyoke1)}></img>
                                                            :
                                                            detail.fig === "2.0" && detail.category === 323 ?
                                                                <img src={Endyoke2} alt="endyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Endyoke2)}></img>
                                                                :
                                                                detail.fig === "3.0" && detail.category === 323 ?
                                                                    <img src={Endyoke3} alt="endyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Endyoke3)}></img>
                                                                    :
                                                                    detail.fig === "4.0" && detail.category === 323 ?
                                                                        <img src={Endyoke4} alt="endyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Endyoke4)}></img>
                                                                        :
                                                                        detail.category === 8 ?
                                                                            <img src={Slipshaft} alt="sleevyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Slipshaft)}></img>
                                                                            :
                                                                            detail.fig === "1" && detail.category === 321 ?

                                                                                <img src={FlangeYoke1} alt="flangeyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(FlangeYoke1)}></img>

                                                                                :
                                                                                detail.fig === "2" && detail.category === 321 ?
                                                                                    <img src={FlangeYoke2} alt="flangeyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(FlangeYoke2)}></img>
                                                                                    :
                                                                                    detail.fig === "3" && detail.category === 321 ?
                                                                                        <img src={FlangeYoke3} alt="flangeyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(FlangeYoke3)}></img>
                                                                                        :
                                                                                        detail.fig === "BLANK" && detail.category === 321 ?
                                                                                            <img src={FlangeYoke1} alt="flangeyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(FlangeYoke1)}></img>
                                                                                            :
                                                                                            detail.fig === "CVTYP" && detail.category === 321 ?
                                                                                                <img src={CVFlangeyoke} alt="flangeyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CVFlangeyoke)}></img>
                                                                                                :
                                                                                                detail.fig === "1" && detail.category === 328 ?
                                                                                                    <img src={YokeShaft1} alt="yokeshaft" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(YokeShaft1)}></img>
                                                                                                    :
                                                                                                    detail.fig === "2" && detail.category === 328 ?
                                                                                                        <img src={YokeShaft2} alt="yokeshaft" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(YokeShaft2)}></img>
                                                                                                        :
                                                                                                        detail.fig === "3" && detail.category === 328 ?
                                                                                                            <img src={YokeShaft3} alt="yokeshaft" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(YokeShaft3)}></img>
                                                                                                            :
                                                                                                            detail.fig === "1.0" && detail.category === 326 ?
                                                                                                                <img src={MidShaft1} alt="midshaft" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(MidShaft1)}></img>
                                                                                                                :
                                                                                                                detail.fig === "2.0" && detail.category === 326 ?
                                                                                                                    <img src={MidShaft2} alt="midshaft" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(MidShaft2)}></img>
                                                                                                                    :
                                                                                                                    detail.category === 327 ?
                                                                                                                        <img src={Splinedsleeve} alt="splinedsleeve" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Splinedsleeve)}></img>
                                                                                                                        :
                                                                                                                        detail.fig === "1.0" && detail.category === 324 ?
                                                                                                                            <img src={TubeYoke1} alt="tubeyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(TubeYoke1)}></img>
                                                                                                                            :
                                                                                                                            detail.fig === "2.0" && detail.category === 324 ?
                                                                                                                                <img src={TubeYoke2} alt="tubeyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(TubeYoke2)}></img>
                                                                                                                                :
                                                                                                                                detail.fig === "3.0" && detail.category === 324 ?
                                                                                                                                    <img src={TubeYoke3} alt="tubeyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(TubeYoke3)}></img>
                                                                                                                                    :
                                                                                                                                    detail.fig === "1" && detail.category === 320 ?
                                                                                                                                        <img src={CampanionFlange1} alt="campanion" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CampanionFlange1)}></img>
                                                                                                                                        :
                                                                                                                                        detail.fig === "2" && detail.category === 320 ?
                                                                                                                                            <img src={CampanionFlange2} alt="campanion" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CampanionFlange2)}></img>
                                                                                                                                            :
                                                                                                                                            detail.fig === "5" && detail.category === 320 ?
                                                                                                                                                <img src={HoleCampanion5} alt="campanion" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(HoleCampanion5)}></img>
                                                                                                                                                :
                                                                                                                                                detail.fig === "3" && detail.category === 320 ?
                                                                                                                                                    <img src={CampanionFlange3} alt="campanion" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CampanionFlange3)}></img>
                                                                                                                                                    :
                                                                                                                                                    detail.category === 331 ?
                                                                                                                                                        <img src={ShortCouple} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(ShortCouple)}></img> :
                                                                                                                                                        detail.internal_category === "TY-GWB" && detail.category === 329 ?
                                                                                                                                                            <img src={Groovetype} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Groovetype)}></img> :
                                                                                                                                                            detail.internal_category === "FY Serrated - GWB" && detail.category === 329 ?
                                                                                                                                                                <img src={SarratedFlange} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(SarratedFlange)}></img>
                                                                                                                                                                : detail.internal_category === "SJ-GWB" && detail.category === 329 ?
                                                                                                                                                                    <img src={SlipJoint} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(SlipJoint)}></img>
                                                                                                                                                                    : detail.internal_category === "C-SSS" && detail.category === 330 ?
                                                                                                                                                                        <img src={SlipStubShaft} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(SlipStubShaft)}></img>
                                                                                                                                                                        : detail.internal_category === "C-SY" && detail.category === 330 ?
                                                                                                                                                                            <img src={Cseries} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Cseries)}></img>
                                                                                                                                                                            : detail.internal_category === "C-TY" && detail.category === 330 ?
                                                                                                                                                                                <img src={CseriesTy} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CseriesTy)}></img>
                                                                                                                                                                                : detail.internal_category === "C-YS" && detail.category === 330 ?
                                                                                                                                                                                    <img src={CseriesYs} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CseriesYs)}></img>
                                                                                                                                                                                    : detail.internal_category === "C-SJ" && detail.category === 330 ?
                                                                                                                                                                                        <img src={CseriesSj} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CseriesSj)}></img>
                                                                                                                                                                                        : detail.internal_category === "C-SC" && detail.category === 330 ?
                                                                                                                                                                                            <img src={CseriesShortcouple} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CseriesShortcouple)}></img>
                                                                                                                                                                                            : detail.internal_category === "H-Yoke-Center Yoke" && detail.category === 333 ?
                                                                                                                                                                                                <img src={CenterYoke} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CenterYoke)}></img>
                                                                                                                                                                                                : detail.internal_category === "Ball Stud Yoke1" && detail.category === 333 ?
                                                                                                                                                                                                    <img src={CvTubeyoke} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CvTubeyoke)}></img>
                                                                                                                                                                                                    : detail.internal_category === "Ball Stud Yoke" && detail.category === 333 ?
                                                                                                                                                                                                        <img src={CvTubeyoke} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CvTubeyoke)}></img>
                                                                                                                                                                                                        : detail.internal_category === "Center Yoke" && detail.category === 333 ?
                                                                                                                                                                                                            <img src={CvFlangeyoke} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CvFlangeyoke)}></img>
                                                                                                                                                                                                            : detail.internal_category === "1310 CV Head" && detail.category === 333 ?
                                                                                                                                                                                                                <img src={CVheadTypeA} alt="cvhead" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CVheadTypeA)}></img>
                                                                                                                                                                                                                : detail.internal_category === "1350 CV Head" && detail.category === 333 ?
                                                                                                                                                                                                                    <img src={CVheadTypeB} alt="cvhead" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CVheadTypeB)}></img>
                                                                                                                                                                                                                    :
                                                                                                                                                                                                                    null


                                        }
                                    </Col>
                                </Row>
                            </Col>


                        }

                        <Col></Col>
                        <Col md={12} lg={6}>
                            <div style={{ textAlign: "left" }} className='col-10'>
                                <span style={{ color: '#0F328E', fontSize: '24px', fontWeight: '600', lineHeight: '36.4px', display: 'flex' }}>{detail.series} / {detail.part_number} / {detail.category_name}
                                    {/* <Dropdown className="ms-3">
                                        <Dropdown.Toggle variant="success" id="dropdown-basic"  className=" border border-secondary" style={{ padding: '3px', background: '#fff', color: 'black', minWidth: '90px' }}>
                                       Unit type                           
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu >
                                        <Dropdown.Item href="#/action-1">MM</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Inch</Dropdown.Item>
                                      
                                    </Dropdown.Menu>
                                </Dropdown> */}

                                </span>

                                <div><StarRatings rating={4}
                                    starDimension="20px"
                                    starSpacing="5px"
                                    starRatedColor='#FDBC15'
                                /> <span style={{ color: '#A9A9A9' }}>(1 customer review)</span></div>

                            </div>
                            <div style={{ textAlign: "left", color: "#6C6C6C", fontSize: '14px', marginTop: '10px' }} className='col-10' >
                                <span>
                                    Description:-  {detail.description}
                                </span>
                            </div>
                            <Row style={{ textAlign: "left", marginTop: "20px", paddingLeft: '20px', marginRight: '0px ' }}>
                                <Col lg={6}>
                                    <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Category Name : <span style={{ color: 'black', fontSize: '13px' }}>{detail.category_name}</span></div>
                                    <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Category : <span style={{ color: 'black', fontSize: '13px' }}>{detail.category}</span></div>
                                    {detail.series !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Series : <span style={{ color: 'black', fontSize: '13px' }}>{detail.series}</span></div> : null}
                                    <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Part Number : <span style={{ color: 'black', fontSize: '13px' }}>{detail.part_number}</span></div>
                                    {detail.fig !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  Fig : <span style={{ color: 'black', fontSize: '13px' }}>{detail.fig} </span></div> : null}
                                    {detail.key_height_f !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  F Key Height : <span style={{ color: 'black', fontSize: '13px' }}>{detail.key_height_f} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.key_height_f_mm === null ? null : `(${detail.key_height_f_mm} mm)`}</i></span></div> : null}
                                    {detail.key_width_k !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>   K Key Width : <span style={{ color: 'black', fontSize: '13px' }}> {detail.key_width_k} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.key_width_k_mm === null ? null : `(${detail.key_width_k_mm} mm)`}</i></span></div> : null}
                                    {detail.bore_d !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>Bore : <span style={{ color: 'black', fontSize: '13px' }}> {detail.bore_d} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.bore_d_mm === null ? null : `(${detail.bore_d_mm} mm)`}</i></span></div> : null}
                                    {detail.flange_dia_a !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  Flange Diameter A: <span style={{ color: 'black', fontSize: '13px' }}>{detail.flange_dia_a} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.flange_dia_a_mm === null ? null : `(${detail.flange_dia_a_mm} mm)`}</i> </span></div> : null}
                                    {detail.pcd_b !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  PCD B: <span style={{ color: 'black', fontSize: '13px' }}>{detail.pcd_b} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.pcd_b_mm === null ? null : `(${detail.pcd_b_mm} mm )`}</i></span></div> : null}
                                    {detail.seat_dia_c !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Seat Diameter C: <span style={{ color: 'black', fontSize: '13px' }}>{detail.seat_dia_c} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.seat_dia_c_mm === null ? null : `(${detail.seat_dia_c_mm} mm)`}</i></span></div> : null}
                                    {detail.hub_dia_e !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Hub Diameter E: <span style={{ color: 'black', fontSize: '13px' }}>{detail.hub_dia_e} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.hub_dia_e_mm === null ? null : `(${detail.hub_dia_e_mm} )`}</i></span></div> : null}
                                    {detail.height_g !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Height G: <span style={{ color: 'black', fontSize: '13px' }}>{detail.height_g}inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.height_g_mm === null ? null : `(${detail.height_g_mm})`}</i></span></div> : null}
                                    {detail.reference_part_no !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  Reference Part Number : <span style={{ color: 'black', fontSize: '13px' }}>{detail.reference_part_no} </span></div> : null}
                                    {detail.spline_length_f !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  Spline Length F: <span style={{ color: 'black', fontSize: '13px' }}>{detail.spline_length_f} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.spline_length_f_mm === null ? null : `(${detail.spline_length_f_mm} mm)`}</i></span></div> : null}
                                    {detail.pilot_dia_c !== null ? <div className="mt-2">  Pilot Dia C : <span style={{ color: 'black', fontSize: '13px' }}>{detail.pilot_dia_c} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.pilot_dia_c_mm === null ? null : `(${detail.pilot_dia_c_mm} mm)`}</i></span></div> : null}
                                    {detail.universal_joint_size !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  Universal Joint Size : <span style={{ color: 'black', fontSize: '13px' }}>{detail.universal_joint_size} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.universal_joint_size_mm === null ? null : `(${detail.universal_joint_size_mm} mm )`}</i></span></div> : null}
                                    {detail.center_line_to_end_g !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Center Line To end G: <span style={{ color: 'black', fontSize: '13px' }}>{detail.center_line_to_end_g} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.center_line_to_end_g_mm === null ? null : `(${detail.center_line_to_end_g_mm} mm)`}</i></span></div> : null}
                                    {detail.pilot_dia_c_male_m_female_f !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  Pilot Dia C m&f: <span style={{ color: 'black', fontSize: '13px' }}>{detail.pilot_dia_c_male_m_female_f} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.pilot_dia_c_male_m_female_f_mm === null ? null : `(${detail.pilot_dia_c_male_m_female_f_mm} mm)`}</i> </span></div> : null}
                                    {detail.pcd !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  PCD : <span style={{ color: 'black', fontSize: '13px' }}>{detail.pcd} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.pcd_mm === null ? null : `(${detail.pcd_mm} mm)`}</i> </span></div> : null}
                                    {detail.hole_dia_and_numbers_of_holes !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Hole Diameter And No. Of Holes: <span style={{ color: 'black', fontSize: '13px' }}>{detail.hole_dia_and_numbers_of_holes} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.hole_dia_and_numbers_of_holes_mm === null ? null : `(${detail.hole_dia_and_numbers_of_holes_mm} mm)`}</i></span></div> : null}
                                    {detail.spline_size !== null ? <div className="mt-2">  Spline Size : <span style={{ color: 'black', fontSize: '13px' }}>{detail.spline_size} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.spline_size_mm === null ? null : `(${detail.spline_size_mm} mm)`}</i></span></div> : null}
                                    {detail.center_to_face_of_plug_f !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  Center To Face Of Plug F:  <span style={{ color: 'black', fontSize: '13px' }}> {detail.center_to_face_of_plug_f} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.center_to_face_of_plug_f_mm === null ? null : `(${detail.center_to_face_of_plug_f_mm} mm)`}</i></span></div> : null}
                                    {detail.center_to_end_of_spline_j !== null ? <div className="mt-2"> Center  To End Of Spline J:  <span style={{ color: 'black', fontSize: '13px' }}> {detail.center_to_end_of_spline_j} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.center_to_end_of_spline_j_mm === null ? null : `(${detail.center_to_end_of_spline_j_mm} mm)`}</i></span></div> : null}
                                    {detail.spline_size_bore_key_way !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Spline Size Bore Key Way:  <span style={{ color: 'black', fontSize: '13px' }}> {detail.spline_size_bore_key_way} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.spline_size_bore_key_way_mm === null ? null : `(${detail.spline_size_bore_key_way_mm} mm)`}</i></span></div> : null}
                                    {detail.ground_hub_dia_r !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Ground Hub Diameter R:  <span style={{ color: 'black', fontSize: '13px' }}> {detail.ground_hub_dia_r} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.ground_hub_dia_r_mm === null ? null : `(${detail.ground_hub_dia_r_mm} mm)`}</i></span></div> : null}
                                    {detail.length_of_hole_q !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  Lenght Of Hole Q : <span style={{ color: 'black', fontSize: '13px' }}>{detail.length_of_hole_q} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.length_of_hole_q_mm === null ? null : `(${detail.length_of_hole_q_mm} mm)`}</i></span></div> : null}
                                    {detail.center_line_to_end_s !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Center Line To end S : <span style={{ color: 'black', fontSize: '13px' }}>{detail.center_line_to_end_s} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.center_line_to_end_s_mm === null ? null : `(${detail.center_line_to_end_s_mm} mm )`}</i></span></div> : null}
                                    {detail.center_to_weld_length_h !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Center To Weld Length H : <span style={{ color: 'black', fontSize: '13px' }}>{detail.center_to_weld_length_h} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.center_to_weld_length_h_mm === null ? null : `(${detail.center_to_weld_length_h_mm} mm)`}</i></span></div> : null}
                                    {detail.tube_dia_and_wall_thickness !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  Tube Diameter Wall Thickness : <span style={{ color: 'black', fontSize: '13px' }}>{detail.tube_dia_and_wall_thickness} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.tube_dia_and_wall_thickness_mm === null ? null : `(${detail.tube_dia_and_wall_thickness_mm} mm)`}</i> </span></div> : null}
                                    {detail.tube_diameter !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Tube Diameter: <span style={{ color: 'black', fontSize: '13px' }}>{detail.tube_diameter} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.tube_diameter_mm === null ? null : `(${detail.tube_diameter_mm} mm)`}</i></span></div> : null}
                                    {detail.tube_thickness !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Tube Thickness: <span style={{ color: 'black', fontSize: '13px' }}>{detail.tube_thickness} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.tube_thickness_mm === null ? null : `(${detail.tube_thickness_mm}mm)`}</i></span></div> : null}
                                    {detail.total_length_a !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Total Length A : <span style={{ color: 'black', fontSize: '13px' }}>{detail.total_length_a} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.total_length_a_mm === null ? null : `(${detail.total_length_a_mm} mm)`}</i></span></div> : null}
                                    {detail.end_of_spline_to_weld_k !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> End Of Spline To Weld K: <span style={{ color: 'black', fontSize: '13px' }}>{detail.end_of_spline_to_weld_k} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.end_of_spline_to_weld_k_mm === null ? null : `(${detail.end_of_spline_to_weld_k_mm}mm)`}</i></span></div> : null}
                                    {detail.radius_length_r !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>Radius Lenght R: <span style={{ color: 'black', fontSize: '13px' }}>{detail.radius_length_r} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.radius_length_r_mm === null ? null : `(${detail.radius_length_r_mm} mm)`}</i></span></div> : null}
                                    {detail.bearing_diameter_c !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>Bearing Diameter C:<span style={{ color: 'black', fontSize: '13px' }}>{detail.bearing_diameter_c} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.bearing_diameter_c_mm === null ? null : `(${detail.bearing_diameter_c_mm} mm)`}</i></span></div> : null}
                                    {detail.spline_length_l !== null ? <div className="mt-2">Spline Length L:<span style={{ color: 'black', fontSize: '13px' }}>{detail.spline_length_l} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.spline_length_l_mm === null ? null : `(${detail.spline_length_l_mm}mm)`}</i> </span></div> : null}
                                    {detail.shoulder_diameter_b !== null ? <div className="mt-2">Shoulder Diameter B:<span style={{ color: 'black', fontSize: '13px' }}>{detail.shoulder_diameter_b} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.shoulder_diameter_b_mm === null ? null : `(${detail.shoulder_diameter_b_mm} mm)`}</i></span></div> : null}
                                    {detail.shoulder_length_g !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>Shoulder Diameter G:<span style={{ color: 'black', fontSize: '13px' }}>{detail.shoulder_length_g} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.shoulder_length_g_mm === null ? null : `(${detail.shoulder_length_g_mm} mm)`}</i></span></div> : null}
                                    {detail.spline_end_to_bearing_shoulder_y !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>Spline End Shoulder To Bearing Shoulder Yoke1:<span style={{ color: 'black', fontSize: '13px' }}>{detail.spline_end_to_bearing_shoulder_y} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.spline_end_to_bearing_shoulder_y_mm === null ? null : `(${detail.spline_end_to_bearing_shoulder_y_mm}mm)`}</i> </span></div> : null}
                                    {detail.thread_size !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  Thread Size : <span style={{ color: 'black', fontSize: '13px' }}>{detail.thread_size} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.thread_size_mm === null ? null : `(${detail.thread_size_mm} mm)`}</i></span></div> : null}
                                    {detail.tube_wall_thickeness !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>Tube Wall Thickeness<span style={{ color: 'black', fontSize: '13px' }}>{detail.tube_wall_thickeness} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.tube_wall_thickeness_mm === null ? null : `(${detail.tube_wall_thickeness_mm} mm)`}</i></span></div> : null}
                                    {detail.center_to_end_length_k !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>Center To End Length K: <span style={{ color: 'black', fontSize: '13px' }}>{detail.center_to_end_length_k} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.center_to_end_length_k_mm === null ? null : `(${detail.center_to_end_length_k_mm} mm)`}</i></span></div> : null}
                                    {detail.end_of_spline_to_radius_length_r !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>End Of Spline To Radius Lenght R: <span style={{ color: 'black', fontSize: '13px' }}>{detail.end_of_spline_to_radius_length_r} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.end_of_spline_to_radius_length_r_mm === null ? null : `(${detail.end_of_spline_to_radius_length_r_mm} mm)`}</i> </span></div> : null}
                                    {detail.seat_dia_b !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Seat Diameter B: <span style={{ color: 'black', fontSize: '13px' }}>{detail.seat_dia_b} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.seat_dia_b_mm === null ? null : `(${detail.seat_dia_b_mm} mm)`}</i></span></div> : null}
                                    {detail.spigot_d !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Spigot D: <span style={{ color: 'black', fontSize: '13px' }}>{detail.spigot_d}</span></div> : null}
                                    {detail.center_to_end_h !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Center To End H: <span style={{ color: 'black', fontSize: '13px' }}>{detail.center_to_end_h} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.center_to_end_h_mm === null ? null : `(${detail.center_to_end_h_mm} mm)`}</i></span></div> : null}
                                    {detail.grooving_length_m !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Grooving Length M: <span style={{ color: 'black', fontSize: '13px' }}>{detail.grooving_length_m} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.grooving_length_m_mm === null ? null : `(${detail.grooving_length_m_mm} mm)`}</i></span></div> : null}
                                    {detail.bearing_dia_n !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Bearing Dia N: <span style={{ color: 'black', fontSize: '13px' }}>{detail.bearing_dia_n} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.bearing_dia_n_mm === null ? null : `(${detail.bearing_dia_n_mm} mm)`}</i></span></div> : null}
                                    {detail.pcd_p !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  PCD P : <span style={{ color: 'black', fontSize: '13px' }}>{detail.pcd_p} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.pcd_p_mm === null ? null : `(${detail.pcd_p_mm} mm)`}</i></span></div> : null}
                                    {detail.hole_distance_e !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  Hole Distance E : <span style={{ color: 'black', fontSize: '13px' }}>{detail.hole_distance_e} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.hole_distance_e_mm === null ? null : `(${detail.hole_distance_e_mm} mm)`}</i></span></div> : null}
                                    {detail.hole_distance_f !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  Hole Distance F : <span style={{ color: 'black', fontSize: '13px' }}>{detail.hole_distance_f} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.hole_distance_f_mm === null ? null : `(${detail.hole_distance_f_mm} mm)`}</i> </span></div> : null}
                                    {detail.joint_alpha_beta_angle !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Joint Alpha Beta Angle : <span style={{ color: 'black', fontSize: '13px' }}>{detail.joint_alpha_beta_angle} </span></div> : null}
                                    {detail.center_to_end_length_g !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Center To End Length G : <span style={{ color: 'black', fontSize: '13px' }}>{detail.center_to_end_length_g} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.center_to_end_length_g_mm === null ? null : `(${detail.center_to_end_length_g_mm} mm)`}</i></span></div> : null}
                                    {detail.bearing_cap_dia_h !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Bearing Cap Dia H : <span style={{ color: 'black', fontSize: '13px' }}>{detail.bearing_cap_dia_h} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.bearing_cap_dia_h_mm === null ? null : `(${detail.bearing_cap_dia_h_mm} mm)`}</i></span></div> : null}
                                    {detail.groove_distance_m !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Grooving Distance M: <span style={{ color: 'black', fontSize: '13px' }}>{detail.groove_distance_m} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.groove_distance_m_mm === null ? null : `(${detail.groove_distance_m_mm} mm)`}</i></span></div> : null}
                                    {detail.butt_dia_d !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Butt Dia D: <span style={{ color: 'black', fontSize: '13px' }}>{detail.butt_dia_d} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.butt_dia_d_mm == null ? null : `(${detail.butt_dia_d_mm} mm)`}</i></span></div> : null}
                                    {detail.center_to_weld_length_l !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Center To Weld Length L : <span style={{ color: 'black', fontSize: '13px' }}>{detail.center_to_weld_length_l} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.center_to_weld_length_l_mm === null ? null : `(${detail.center_to_weld_length_l_mm} mm)`}</i></span></div> : null}
                                    {detail.center_to_end_length_l1 !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Center To End Length L1 : <span style={{ color: 'black', fontSize: '13px' }}>{detail.center_to_end_length_l1} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.center_to_end_length_l1_mm === null ? null : `(${detail.center_to_end_length_l1_mm} mm)`}</i> </span></div> : null}
                                    {detail.bearing_cap_dia_n !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Bearing Cap Dia N : <span style={{ color: 'black', fontSize: '13px' }}>{detail.bearing_cap_dia_n} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.bearing_cap_dia_n_mm === null ? null : `(${detail.bearing_cap_dia_n_mm} mm)`}</i></span></div> : null}
                                    {detail.tube_od_wall_thk !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Tube Od Wall Thk  : <span style={{ color: 'black', fontSize: '13px' }}>{detail.tube_od_wall_thk} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.tube_od_wall_thk_mm === null ? null : `(${detail.tube_od_wall_thk_mm} mm)`}</i></span></div> : null}
                                    {detail.spline_data !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  Spline Data : <span style={{ color: 'black', fontSize: '13px' }}>{detail.spline_data} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.spline_data_mm === null ? null : `(${detail.spline_data_mm} mm)`}</i></span></div> : null}
                                    {detail.slip_s !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>Slip S  : <span style={{ color: 'black', fontSize: '13px' }}>{detail.slip_s} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.slip_s_mm === null ? null : `(${detail.slip_s_mm} mm)`}</i></span></div> : null}
                                    {detail.joint_angle !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>Joint Angle  : <span style={{ color: 'black', fontSize: '13px' }}>{detail.joint_angle} </span></div> : null}
                                    {detail.center_to_weld_length_k !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>Center To Weld Length K : <span style={{ color: 'black', fontSize: '13px' }}>{detail.center_to_weld_length_k} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.center_to_weld_length_k_mm === null ? null : `(${detail.center_to_weld_length_k_mm} mm)`}</i></span></div> : null}
                                    {detail.bolt_hole_details_j !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>Bolt Hole Details J : <span style={{ color: 'black', fontSize: '13px' }}>{detail.bolt_hole_details_j} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.bolt_hole_details_j_mm === null ? null : `(${detail.bolt_hole_details_j_mm} mm)`}</i></span></div> : null}
                                    {detail.spline_length_h !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>Spline Lenght H : <span style={{ color: 'black', fontSize: '13px' }}>{detail.spline_length_h} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.spline_length_h_mm === null ? null : `(${detail.spline_length_h_mm} mm)`}</i></span></div> : null}
                                    {detail.total_length_g !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Total Length G : <span style={{ color: 'black', fontSize: '13px' }}>{detail.total_length_g} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.total_length_g_mm === null ? null : `(${detail.total_length_g_mm} mm)`}</i></span></div> : null}
                                    {detail.uj_size !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  Uj Size : <span style={{ color: 'black', fontSize: '13px' }}>{detail.uj_size} </span></div> : null}
                                    {detail.total_length_e !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Total Length E : <span style={{ color: 'black', fontSize: '13px' }}>{detail.total_length_e} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.total_length_e_mm === null ? null : `(${detail.total_length_e_mm} mm)`}</i></span></div> : null}
                                    {detail.center_to_weld_f !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>Center To Weld F : <span style={{ color: 'black', fontSize: '13px' }}>{detail.center_to_weld_f} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.center_to_weld_f_mm === null ? null : `(${detail.center_to_weld_f_mm} mm)`}</i> </span></div> : null}
                                    {detail.hole_details_g !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>Hole Details G : <span style={{ color: 'black', fontSize: '13px' }}>{detail.hole_details_g} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.hole_details_g_mm === null ? null : `(${detail.hole_details_g_mm} mm)`}</i></span></div> : null}

                                </Col>
                                <Col lg={6}>
                                    {detail.bolt_distance_a !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>Bolt Distance A : <span style={{ color: 'black', fontSize: '13px' }}>{detail.bolt_distance_a} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.bolt_distance_a_mm === null ? null : `(${detail.bolt_distance_a_mm} mm)`}</i></span></div> : null}
                                    {detail.bolt_distance_b !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>Bolt Distance B : <span style={{ color: 'black', fontSize: '13px' }}>{detail.bolt_distance_b} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.bolt_distance_b_mm === null ? null : `(${detail.bolt_distance_b_mm} mm)`}</i></span></div> : null}
                                    {detail.spline_details !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  Spline Details : <span style={{ color: 'black', fontSize: '13px' }}>{detail.spline_details} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.spline_details_mm === null ? null : `(${detail.spline_details_mm} mm)`}</i></span></div> : null}
                                    {detail.face_to_end_length_g !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  Face To End Length G : <span style={{ color: 'black', fontSize: '13px' }}>{detail.face_to_end_length_g} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.face_to_end_length_g_mm === null ? null : `(${detail.face_to_end_length_g_mm} mm)`}</i></span></div> : null}
                                    {detail.hub_dia_r !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Hub Dia R : <span style={{ color: 'black', fontSize: '13px' }}>{detail.face_to_end_length_g} </span></div> : null}
                                    {detail.total_length_h !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Total Length H : <span style={{ color: 'black', fontSize: '13px' }}>{detail.total_length_h} </span></div> : null}
                                    {detail.spline_length !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>Spline Lenght  : <span style={{ color: 'black', fontSize: '13px' }}>{detail.spline_length} </span></div> : null}
                                    {detail.face_to_weld_lc !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  Face To Weld Lc : <span style={{ color: 'black', fontSize: '13px' }}>{detail.face_to_weld_lc} </span></div> : null}
                                    {detail.slip !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  Slip : <span style={{ color: 'black', fontSize: '13px' }}>{detail.slip} </span></div> : null}
                                    {detail.bolt_holes !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  Bolt Holes : <span style={{ color: 'black', fontSize: '13px' }}>{detail.bolt_holes} </span></div> : null}
                                    {detail.tube_size !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  Tube Size : <span style={{ color: 'black', fontSize: '13px' }}>{detail.tube_size} </span></div> : null}
                                    {detail.f2f !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  F2F : <span style={{ color: '#6C6C6C', fontSize: '13px' }}>{detail.f2f} </span></div> : null}
                                    {detail.end_to_end_length_g1 !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>End To End Lenght G1  : <span style={{ color: 'black', fontSize: '13px' }}>{detail.end_to_end_length_g1} </span></div> : null}
                                    {detail.bearing_cap_dim_d1 !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>Bearing Cap Dim D1  : <span style={{ color: 'black', fontSize: '13px' }}>{detail.bearing_cap_dim_d1} </span></div> : null}
                                    {detail.end_to_end_length_g2 !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>End To End Lenght G2  : <span style={{ color: 'black', fontSize: '13px' }}>{detail.end_to_end_length_g2} </span></div> : null}
                                    {detail.bearing_cap_dim_d2 !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>Bearing Cap Dim D2  : <span style={{ color: 'black', fontSize: '13px' }}>{detail.bearing_cap_dim_d2} </span></div> : null}
                                    {detail.serial_no !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>Serial No  : <span style={{ color: 'black', fontSize: '13px' }}>{detail.serial_no} </span></div> : null}
                                    {detail.tube_diameter_d !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  Tube Diameter D : <span style={{ color: 'black', fontSize: '13px' }}>{detail.tube_diameter_d} </span></div> : null}
                                    {detail.tube_thickness_t !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  Tube Thickness T : <span style={{ color: 'black', fontSize: '13px' }}>{detail.tube_thickness_t} </span></div> : null}
                                    {detail.face_to_face_a_a !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Face To Face AA : <span style={{ color: 'black', fontSize: '13px' }}>{detail.face_to_face_a_a} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.face_to_face_a_a_mm === null ? null : `(${detail.face_to_face_a_a_mm} mm)`}</i></span></div> : null}
                                    {detail.center_to_center_c_c !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>Center To Center CC : <span style={{ color: 'black', fontSize: '13px' }}>{detail.center_to_center_c_c} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.center_to_center_c_c_mm === null ? null : `(${detail.center_to_center_c_c_mm} mm)`}</i></span></div> : null}
                                    {detail.joint_angles_d !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  Joint Angles D : <span style={{ color: 'black', fontSize: '13px' }}>{detail.joint_angles_d} </span></div> : null}
                                    {detail.adroit_drg_no !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>  Adroit Drg No : <span style={{ color: 'black', fontSize: '13px' }}>{detail.adroit_drg_no} </span></div> : null}
                                    {detail.flange_yoke !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Flange Yoke : <span style={{ color: 'black', fontSize: '13px' }}>{detail.flange_yoke} </span></div> : null}
                                    {detail.uj_cross !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Uj Cross : <span style={{ color: 'black', fontSize: '13px' }}>{detail.uj_cross} </span></div> : null}
                                    {detail.slip_yoke_assy !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Slip Yoke Assy : <span style={{ color: 'black', fontSize: '13px' }}>{detail.slip_yoke_assy} </span></div> : null}
                                    {detail.slip_stub_shaft !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Slip Stub Shaft : <span style={{ color: 'black', fontSize: '13px' }}>{detail.slip_stub_shaft} </span></div> : null}
                                    {detail.tube_yoke !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Tube Yoke : <span style={{ color: 'black', fontSize: '13px' }}>{detail.tube_yoke} </span></div> : null}
                                    {detail.slip_assy_barrel !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Slip Assy Barrel: <span style={{ color: 'black', fontSize: '13px' }}>{detail.slip_assy_barrel} </span></div> : null}
                                    {detail.stub_yoke_shaft !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>Stub Yoke Shaft : <span style={{ color: 'black', fontSize: '13px' }}>{detail.stub_yoke_shaft} </span></div> : null}
                                    {detail.diagram_id !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>Diagram Id : <span style={{ color: 'black', fontSize: '13px' }}>{detail.diagram_id} </span></div> : null}
                                    {detail.a_overall_length !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>A Overall Lenght : <span style={{ color: 'black', fontSize: '12px' }}>{detail.a_overall_length} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.a_overall_length_mm === null ? null : `(${detail.a_overall_length_mm} mm)`}</i></span></div> : null}
                                    {detail.b_cl_to_point_of_weld !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>B Cl To Point Of Weld : <span style={{ color: 'black', fontSize: '13px' }}>{detail.b_cl_to_point_of_weld} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.b_cl_to_point_of_weld_mm === null ? null : `(${detail.b_cl_to_point_of_weld_mm} mm)`}</i></span></div> : null}
                                    {detail.c_cl_to_cl_of_center_yoke !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>C Cl to Cl Of Center Yoke: <span style={{ color: 'black', fontSize: '13px' }}>{detail.c_cl_to_cl_of_center_yoke} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.c_cl_to_cl_of_center_yoke_mm === null ? null : `(${detail.c_cl_to_cl_of_center_yoke_mm} mm)`}</i></span></div> : null}
                                    {detail.e_pilot_dia !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>E Pilot Dia: <span style={{ color: 'black', fontSize: '13px' }}>{detail.e_pilot_dia} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.e_pilot_dia_mm === null ? null : `(${detail.e_pilot_dia_mm} mm)`}</i></span></div> : null}
                                    {detail.f_bolt_circle !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>F Bolt Circle: <span style={{ color: 'black', fontSize: '13px' }}>{detail.f_bolt_circle} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.f_bolt_circle_mm === null ? null : `(${detail.f_bolt_circle_mm} mm)`}</i></span></div> : null}
                                    {detail.g_hole_spacing_height !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>G Hole Spacing Height: <span style={{ color: 'black', fontSize: '13px' }}>{detail.g_hole_spacing_height} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.g_hole_spacing_height_mm === null ? null : `(${detail.g_hole_spacing_height_mm} mm)`}</i></span></div> : null}
                                    {detail.h_hole_spacing_width !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>H Hole Spacing Width: <span style={{ color: 'black', fontSize: '13px' }}>{detail.h_hole_spacing_width} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{`(${detail.g_hole_spacing_height_mm} mm)`}</i></span></div> : null}
                                    {detail.i_hole_thread_size !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>I Hole Thread Size: <span style={{ color: 'black', fontSize: '13px' }}>{detail.i_hole_thread_size} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.i_hole_thread_size_mm === null ? null : `(${detail.i_hole_thread_size_mm} mm )`}</i></span></div> : null}
                                    {detail.a !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Flange or Swing Diameter :  <span style={{ color: 'black', fontSize: '13px' }}>{detail.a} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.a_mm === null ? null : `(${detail.a_mm} mm)`}</i> </span></div> : null}
                                    {detail.b !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Flange or Swing Diameter B :  <span style={{ color: 'black', fontSize: '13px' }}>{detail.b} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.b_mm === null ? null : `(${detail.b_mm} mm)`}</i></span></div> : null}
                                    {detail.d_center_to_center !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> D Center tO Center:  <span style={{ color: 'black', fontSize: '13px' }}> {detail.d_center_to_center} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.d_center_to_center_mm === null ? null : `(${detail.d_center_to_center_mm} mm)`}</i></span></div> : null}
                                    {detail.e_swing_diameter !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>E Swing Diameter:  <span style={{ color: 'black', fontSize: '13px' }}> {detail.e_swing_diameter} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.e_swing_diameter_mm === null ? null : `(${detail.e_swing_diameter_mm} mm)`}</i></span></div> : null}
                                    {detail.max_joint_angle !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>Max Joint Angle:  <span style={{ color: 'black', fontSize: '13px' }}> {detail.max_joint_angle} </span></div> : null}
                                    {detail.tube_diameter_and_wall !== null ? <div className="mt-2">Tube Diameter And Wall:  <span style={{ color: 'black', fontSize: '13px' }}> {detail.tube_diameter_and_wall} </span></div> : null}
                                    {detail.e_centerline_to_point_of_weld !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> E Centerline To Point Of Weld:  <span style={{ color: 'black', fontSize: '13px' }}> {detail.e_centerline_to_point_of_weld} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.e_centerline_to_point_of_weld_mm === null ? null : `(${detail.e_centerline_to_point_of_weld_mm} mm)`}</i></span></div> : null}
                                    {detail.f_center_to_end_of_ball_stud !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> F Center To End Of Ball Stud:  <span style={{ color: 'black', fontSize: '13px' }}> {detail.f_center_to_end_of_ball_stud} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.f_center_to_end_of_ball_stud_mm === null ? null : `(${detail.f_center_to_end_of_ball_stud_mm} mm)`}</i></span></div> : null}
                                    {detail.g_ball_stud_length !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> G Ball Stud Length:  <span style={{ color: 'black', fontSize: '13px' }}> {detail.g_ball_stud_length} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.g_ball_stud_length_mm === null ? null : `(${detail.g_ball_stud_length_mm} mm)`}</i></span></div> : null}
                                    {detail.h_ball_stud_diameter !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> H Ball Stud Diameter:  <span style={{ color: 'black', fontSize: '13px' }}> {detail.h_ball_stud_diameter} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.h_ball_stud_diameter_mm === null ? null : `(${detail.h_ball_stud_diameter_mm} mm)`}</i></span></div> : null}
                                    {detail.secondary_part_number !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Secondary Part Number:  <span style={{ color: 'black', fontSize: '13px' }}> {detail.secondary_part_number} </span></div> : null}
                                    {detail.d_swing_diameter !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> D Swing Diameter:  <span style={{ color: 'black', fontSize: '13px' }}> {detail.d_swing_diameter} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.d_swing_diameter_mm === null ? null : `(${detail.d_swing_diameter_mm} mm)`}</i> </span></div> : null}
                                    {detail.e_bolt_circle !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> E Bolt Circle:  <span style={{ color: 'black', fontSize: '13px' }}> {detail.e_bolt_circle} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.e_bolt_circle_mm === null ? null : `(${detail.e_bolt_circle_mm} mm )`}</i></span></div> : null}
                                    {detail.f_hole_threa_size !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> F Hole Threa Size:  <span style={{ color: 'black', fontSize: '13px' }}> {detail.f_hole_threa_size} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.f_hole_threa_size_mm === null ? null : `(${detail.f_hole_threa_size_mm} mm)`}</i></span></div> : null}
                                    {detail.no_bolt_holes !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> No. Bolt Holes:  <span style={{ color: 'black', fontSize: '13px' }}> {detail.no_bolt_holes} </span></div> : null}
                                    {detail.g_pilot_diameter !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}>G Pilot Diameter:  <span style={{ color: 'black', fontSize: '13px' }}> {detail.g_pilot_diameter} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.g_pilot_diameter_mm === null ? null : `(${detail.g_pilot_diameter_mm} mm)`}</i></span></div> : null}
                                    {detail.h_flange_face_to_face_centerline !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> H Flange Face To Face Centerline:  <span style={{ color: 'black', fontSize: '13px' }}> {detail.h_flange_face_to_face_centerline} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.h_flange_face_to_face_centerline_mm === null ? null : `(${detail.h_flange_face_to_face_centerline_mm} mm)`}</i></span></div> : null}
                                    {detail.stud_socket_dimension !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Stud Socket Dimension:  <span style={{ color: 'black', fontSize: '13px' }}> {detail.stud_socket_dimension} inch <i style={{ color: '#6C6C6C', fontSize: '12px' }}>{detail.stud_socket_dimension_mm === null ? null : `(${detail.stud_socket_dimension_mm} mm)`}</i></span></div> : null}
                                    {detail.product_pricing !== null ? <div className="mt-2" style={{ color: '#3f3f3f', fontSize: '14px', fontWeight: 500 }}> Product Pricing:  <span style={{ color: 'black', fontSize: '13px' }}>$ {detail.product_pricing} </span></div> : null}



                                </Col>
                            </Row>
                            <Row className="col-11" style={{ textAlign: "left", marginTop: "20px", paddingLeft: '20px', marginRight: '0px ' }}>
                                <Col lg={8} >
                                    <h6>Note:- <span style={{ color: '#0F328E', fontSize: '15px', }}>Shipment Price is Added After Checkout</span></h6>
                                </Col>
                                {/* <Col lg={6} className='mt-1'>
                                    <span style={{ color: 'black', fontWeight: '400', fontSize: '16px' }}>Check Delivery</span>
                                    <div className="mt-2 d-flex">
                                        <Input style={{ border: '1px solid #D9D9D9', borderRadius: '4px', borderRight: "none", padding: "7px 15px 8px 15px" }} placeholder='Enter Pincode/Zipcode'></Input>
                                        <button style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', borderLeft: '0px', background: '#0F328E', borderRadius: '4px', color: 'white', border: 'none', padding: '0px 20px 0px 20px' }}>Check</button>
                                    </div>
                                </Col> */}
                            </Row>
                            <Row style={{ textAlign: "Right", marginTop: "20px", marginLeft: '0px', marginRight: '0px ', border: '1px solid #D9D9D9', borderRadius: '5px', padding: '10px 10px 15px 10px' }} className='col-11 '>
                                <Col className="mt-2 d-flex justify-content-lg-start">
                                    <span style={{ color: 'black', fontWeight: '400', fontSize: '20px', }}>$ {detail.product_pricing}</span>
                                    {/* <div><span style={{ color: 'black', textDecoration: 'line-through', fontSize: '14px' }}>₹14999</span> <span style={{ color: "#249B3E", fontSize: '14px' }}>Save - ₹4500</span></div> */}

                                </Col>
                                <Col className='mt-1'>
                                    <div> <Button style={{ background: "#4CB5E2", border: "none", padding: '12px 15px 12px 15px', }} onClick={() => addtoCart(detail.public_id)} ><AiOutlineShoppingCart /> Add to Cart</Button></div>
                                </Col>

                            </Row>

                            {/* <Row style={{ textAlign: "left" }}>
                            <Col style={{ marginLeft: "15px", color: 'black' }}>
                                <AiOutlineHeart style={{ color: '#4CB5E2', fontSize: '18px', fontWeight: 'bolder', }} /> Add to my wish list
                            </Col>
                        </Row> */}
                        </Col>

                    </Row>
                }
                <div className="" style={{ marginTop: '50px' }}>
                    <div className="d-flex justify-content-start " style={{ paddingLeft: '150px', paddingRight: '140px' }}>
                        <div style={{ fontWeight: 'bold', color: '#0F328E', borderBottom: "2px solid #0F328E", width: '200px', textAlign: 'left' }}>Related Products </div>

                    </div>
                    <div className='row  col-12 mt-3' >
                        <div class=" flex-wrap d-flex justify-content-center mt-4 mb-4" >
                            <Card style={{ width: '230px', height: 'auto', borderColor: '#A9ADB0', borderRadius: '16px', boxShadow: "0px 97px 39px rgba(0, 0, 0, 0.01), 0px 54px 33px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.04), 0px 6px 13px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)", margin: '10px', cursor: 'pointer' }} >
                                <div style={{ background: '#F5F5F5', borderRadius: '16px' }}>
                                    <Card.Img variant="top" src={RelatedDummy} style={{ background: '#F5F5F5', height: 'auto', width: 'auto', borderRadius: '16px', }} />
                                </div>
                                <Card.Body>
                                    <Card.Title style={{ color: '#0F328E', textAlign: 'left', marginTop: '10PX', fontSize: '16px' }}>AD1000-2-29</Card.Title>
                                    <Card.Text style={{ color: 'grey', textAlign: 'left', fontSize: '13px', lineHeight: '22px' }}>
                                        Product of Category Flange Yoke
                                        <div className='d-flex justify-content-between mt-1' >
                                        </div>
                                        <hr style={{ marginTop: "-3px" }}></hr>
                                    </Card.Text>

                                    <div className='d-flex justify-content-center mt-1 '>

                                        <Button onClick={() => { navigate(`/productdetail/5555324107377896`); setReload(!reload) }} style={{ background: '#0F328E', borderColor: '#0F328E' }} >View Detail</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                            <Card style={{ width: '230px', height: 'auto', borderColor: '#A9ADB0', borderRadius: '16px', boxShadow: "0px 97px 39px rgba(0, 0, 0, 0.01), 0px 54px 33px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.04), 0px 6px 13px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)", margin: '10px', cursor: 'pointer' }} >
                                <div style={{ background: '#F5F5F5', borderRadius: '16px' }}>
                                    <Card.Img variant="top" src={RelatedDummy} style={{ background: '#F5F5F5', height: 'auto', width: 'auto', borderRadius: '16px', }} />
                                </div>
                                <Card.Body>
                                    <Card.Title style={{ color: '#0F328E', textAlign: 'left', marginTop: '10PX', fontSize: '16px' }}>AD1000-2-29</Card.Title>
                                    <Card.Text style={{ color: 'grey', textAlign: 'left', fontSize: '13px', lineHeight: '22px' }}>
                                        Product of Category Flange Yoke
                                        <div className='d-flex justify-content-between mt-1' >
                                        </div>
                                        <hr style={{ marginTop: "-3px" }}></hr>
                                    </Card.Text>

                                    <div className='d-flex justify-content-center mt-1 '>
                                        <Button onClick={() => { navigate(`/productdetail/5555324107377896`); setReload(!reload) }} style={{ background: '#0F328E', borderColor: '#0F328E' }} >View Detail</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                            <Card style={{ width: '230px', height: 'auto', borderColor: '#A9ADB0', borderRadius: '16px', boxShadow: "0px 97px 39px rgba(0, 0, 0, 0.01), 0px 54px 33px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.04), 0px 6px 13px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)", margin: '10px', cursor: 'pointer' }} >
                                <div style={{ background: '#F5F5F5', borderRadius: '16px' }}>
                                    <Card.Img variant="top" src={RelatedDummy} style={{ background: '#F5F5F5', height: 'auto', width: 'auto', borderRadius: '16px', }} />
                                </div>
                                <Card.Body>
                                    <Card.Title style={{ color: '#0F328E', textAlign: 'left', marginTop: '10PX', fontSize: '16px' }}>AD1000-2-29</Card.Title>
                                    <Card.Text style={{ color: 'grey', textAlign: 'left', fontSize: '13px', lineHeight: '22px' }}>
                                        Product of Category Flange Yoke
                                        <div className='d-flex justify-content-between mt-1' >
                                        </div>
                                        <hr style={{ marginTop: "-3px" }}></hr>
                                    </Card.Text>

                                    <div className='d-flex justify-content-center mt-1 '>

                                        <Button onClick={() => { navigate(`/productdetail/5555324107377896`); setReload(!reload) }} style={{ background: '#0F328E', borderColor: '#0F328E' }} >View Detail</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                            <Card style={{ width: '230px', height: 'auto', borderColor: '#A9ADB0', borderRadius: '16px', boxShadow: "0px 97px 39px rgba(0, 0, 0, 0.01), 0px 54px 33px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.04), 0px 6px 13px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)", margin: '10px', cursor: 'pointer' }} >
                                <div style={{ background: '#F5F5F5', borderRadius: '16px' }}>
                                    <Card.Img variant="top" src={RelatedDummy} style={{ background: '#F5F5F5', height: 'auto', width: 'auto', borderRadius: '16px', }} />
                                </div>
                                <Card.Body>
                                    <Card.Title style={{ color: '#0F328E', textAlign: 'left', marginTop: '10PX', fontSize: '16px' }}>AD1000-2-29</Card.Title>
                                    <Card.Text style={{ color: 'grey', textAlign: 'left', fontSize: '13px', lineHeight: '22px' }}>
                                        Product of Category Flange Yoke
                                        <div className='d-flex justify-content-between mt-1' >
                                        </div>
                                        <hr style={{ marginTop: "-3px" }}></hr>
                                    </Card.Text>

                                    <div className='d-flex justify-content-center mt-1 '>

                                        <Button onClick={() => { navigate(`/productdetail/5555324107377896`); setReload(!reload) }} style={{ background: '#0F328E', borderColor: '#0F328E' }} >View Detail</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                            <Card style={{ width: '230px', height: 'auto', borderColor: '#A9ADB0', borderRadius: '16px', boxShadow: "0px 97px 39px rgba(0, 0, 0, 0.01), 0px 54px 33px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.04), 0px 6px 13px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)", margin: '10px', cursor: 'pointer' }} >
                                <div style={{ background: '#F5F5F5', borderRadius: '16px' }}>
                                    <Card.Img variant="top" src={RelatedDummy} style={{ background: '#F5F5F5', height: 'auto', width: 'auto', borderRadius: '16px', }} />
                                </div>
                                <Card.Body>
                                    <Card.Title style={{ color: '#0F328E', textAlign: 'left', marginTop: '10PX', fontSize: '16px' }}>AD1000-2-29</Card.Title>
                                    <Card.Text style={{ color: 'grey', textAlign: 'left', fontSize: '13px', lineHeight: '22px' }}>
                                        Product of Category Flange Yoke
                                        <div className='d-flex justify-content-between mt-1' >
                                        </div>
                                        <hr style={{ marginTop: "-3px" }}></hr>
                                    </Card.Text>

                                    <div className='d-flex justify-content-center mt-1 '>

                                        <Button onClick={() => { navigate(`/productdetail/5555324107377896`); setReload(!reload) }} style={{ background: '#0F328E', borderColor: '#0F328E' }} >View Detail</Button>
                                    </div>
                                </Card.Body>
                            </Card>


                        </div>
                    </div>
                </div>
                {/* <Col lg={10} style={{ paddingLeft: '50px', marginTop: '40px' }}><TabsNav /></Col> */}
                {/* <div className="d-flex justify-content-between col-10" style={{ marginTop: '50px', paddingLeft: "50px" }}>
                    <div style={{ fontWeight: 'bold', color: '#0F328E', borderBottom: "2px solid #0F328E", width: '200px', textAlign: 'left' }}>Related Products </div>
                    <div style={{ fontWeight: 'bold', display: 'flex' }}>View All <MdOutlineArrowForwardIos style={{ fontSize: '14px', marginTop: '5px', color: '#4CB5E2' }} /></div>
                </div>
                <DashboardProduct /> */}
                {/* <div className="d-flex justify-content-between mt-5 col-10" style={{ paddingLeft: "50px" }}>
                    <div style={{ fontWeight: 'bold', color: '#0F328E', borderBottom: "2px solid #0F328E", width: '200px', textAlign: 'left' }}>Top Brand</div>
                    <div style={{ fontWeight: 'bold', display: 'flex' }}>View All<MdOutlineArrowForwardIos style={{ fontSize: '14px', marginTop: '5px', color: '#4CB5E2' }} /></div>
                </div>
                <Brands /> */}
            </div >
        </>
    )
}
export default ProductDetail;