import React from "react";
function Certifications(){
    return(
        <>
        <div style={{marginTop:'100px',marginBottom:"50px"}}>            <h5>
                Certifications
            </h5>
            </div>

        </>
    )

}
export default Certifications;