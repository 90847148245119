import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
const Technical = () => {
    return (
        <div>
            <div style={{ marginTop: '100px',fontFamily:'sans-serif' }}>
                <div className=''>
                    <div className="container">
                        <div className="col-lg-12 section-head text-center mt-5">
                            <h5 className="title">Technical Information</h5>
                            <div className="dlab-separator-outer">
                                <div className="dlab-separator bg-primary style-skew" />
                            </div>
                            {/* <h2 className="font-weight-700 m-b0">What Defines Adroit</h2> */}
                        </div>
                        <Accordion className='pb-5'>
                            <Accordion.Item eventKey="0">
                                {/* drive shaft section */}
                                <Accordion.Header >Drive Shaft Joint Selection</Accordion.Header>
                                <Accordion.Body >
                                    <span className='d-flex justify-content-start text-justify'><p>When the Power and RPM of a driveshaft are known, the torque transmitted will be: T=HPX7119/RPM</p></span>
                                    <span className='d-flex justify-content-start'>The Effective torque transmitted is based on following factors:</span>
                                    <span className='d-flex justify-content-start text-justify'>
                                        <ul>
                                            <li className='mt-2'>Life Requirement Factor Kl</li>
                                            <li className='mt-2'>Angularity Factor Ka &</li>
                                            <li className='mt-2'>Power Factor Kp</li>
                                        </ul>
                                    </span>
                                    <span className='d-flex justify-content-start text-justify'>The graphs 1 gives appropriate life requirement factor<br></br>
                                        The graphs 2 gives appropriate Angularity factor &<br></br>
                                        The table 3 gives appropriate Power factor
                                    </span>
                                    <span className='d-flex justify-content-center mt-2' style={{ color: '#0F328E', fontSize: '20px' }}>GRAPH-1</span>
                                    <span className='d-flex justify-content-center mt-2'><img src={require('../../Assets/images/graph1.jpg')} ></img></span>
                                    <span className='d-flex justify-content-center mt-2' style={{ color: '#0F328E', fontSize: '20px' }}>GRAPH-2</span>
                                    <span className='d-flex justify-content-center mt-2'><img src={require('../../Assets/images/graph2.jpg')} ></img></span>
                                    <span className='d-flex justify-content-center mt-2' style={{ color: '#0F328E', fontSize: '20px' }}>GRAPH-6</span>
                                    <span className='d-flex justify-content-center mt-2'><img src={require('../../Assets/images/graph6.jpg')} ></img></span>
                                    <span className='d-flex justify-content-start text-justify'>All manufacturer's names, numbers, series, symbols and descriptions are
                                        used for reference purposes only, and it is not implied that any part/series listed is the product of these manufacturers.</span>

                                    {/* </p> */}
                                </Accordion.Body>
                            </Accordion.Item>
                            {/* common driveline section */}
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Common Driveline Issues</Accordion.Header>
                                <Accordion.Body className='d-flex justify-content-start'>

                                    <p className='text-justify'>DRIVE SHAFT COMPONENTS
                                        <span className='d-flex justify-content-start' ><i style={{ color: '#0F328E', fontWeight: 'bold', fontSize: '17px' }}>TYPICAL FAILURE RELATED TO A BROKEN PART</i></span>
                                        <span className='d-flex justify-content-start mt-2'>Most of the failures are the result of shock loads. It causes parts of the driveline to break. Shock loads may occur during following cases:</span>
                                        <span className='d-flex justify-content-start text-justify'>
                                            <ul>
                                                <li className='mt-2'>Frequently engaging and disengaging of the clutch.</li>
                                                <li className='mt-2'>Sudden engagement of spinning tires with the ground.</li>
                                                <li className='mt-2'>While reversing into a heavy trailer or pulling away from it with breaks of trailer engaged.</li>
                                                <li className='mt-2'>Shock loads are caused due to contact of mating yokes. Mating yokes may come in contact during suspension due to improper operating angles. Yoke with suitable angle should be used to avoid this.</li>
                                                <li className='mt-2'>Excessive torque through the yoke. It can be avoided by using yokes having higher torque rating.</li>
                                            </ul>
                                        </span>
                                        <span className='d-flex justify-content-start' style={{ color: '#0F328E', fontSize: '20px' }}>Fractures</span>
                                        <hr></hr>
                                        <span className='d-flex justify-content-start' ><i style={{ color: '#0F328E', fontSize: '17px' }}>Tube yoke fracture</i></span>
                                        <span className='d-flex justify-content-start text-justify'>
                                            <ul>
                                                <li> Improper welding can cause tube yoke failures. The tube yoke circle welds should be started in line with the tube yoke ear and 180 degrees from the tube weld seam.</li>
                                            </ul>
                                        </span>
                                        <span className='d-flex justify-content-start' ><i style={{ color: '#0F328E', fontSize: '17px' }}>Slip yoke fracture</i></span>
                                        <span className='d-flex justify-content-start '>Slip yokes usually crack near the end of the spline, when the drive shaft slip is not installed properly.</span>
                                        <span className='d-flex justify-content-start text-justify'>
                                            <ul>
                                                <li>  The drive shafts must operate at mid-slip. If failure occurs however the length of the pipe must be changed.</li>
                                            </ul>
                                        </span>
                                        <span className='d-flex justify-content-start' ><i style={{ color: '#0F328E', fontSize: '17px' }}>End yoke fracture</i></span>
                                        <span className='d-flex justify-content-start text-justify'>
                                            <ul>
                                                <li>End yoke sometimes fracture near the tangs, it may be a result of improper bearing strap bolt torque or improper handling.</li>
                                            </ul>
                                        </span>
                                        <span className='d-flex justify-content-start'>To avoid this failure the U-joint bearing must not rotate in the bearing pocket, the rotation of bearings may cause excessive wear. Wear causes misalignment and vibrations which may lead to fracture of the tangs.</span>
                                        <span className='d-flex justify-content-start'>A properly installed beating strap holds the bearing tight in its place and avoids rotation. When the bearing straps are installed they stretch and thus a proper torque is established. The stretching causes the bearing strap to be non reusable.</span>
                                        {/* ..........2............. */}
                                        <span className='d-flex justify-content-start mt-3' style={{ color: '#0F328E', fontSize: '20px' }}>Premature center bearing or pillow block failures</span>
                                        <hr></hr>
                                        <span className='d-flex justify-content-start' ><i style={{ color: '#0F328E', fontSize: '17px' }}>Center bearing and/or center bearing rubber failure</i></span>
                                        <span className='d-flex justify-content-start text-justify'>
                                            Secondary couple loads are the main cause of Center Bearing or Pillow Block failure. Secondary couple loads generate when the operating angle at the front end
                                            of a coupling shaft are large and occur twice per revolution. The force generated travels down the centerline of the driveshaft and creates a bending on the drive
                                            shaft. Since the drive shaft is bolted fast on the driver and driven end, it tries to bend the driveshaft at the connecting points. Since the components cannot
                                            be bent the bearing and the surfaces attached to it are loaded. This causes the bearing rubber to fail.
                                        </span>
                                        <span className='d-flex justify-content-start text-justify mt-2'>
                                            To avoid this type of failure the operating angle at drive end of the drive shaft must always be less than 1 and half degrees.
                                            There should be regular inspection of the center bearing to see if the bearing is wearing out, which is typically depicted by black rubber dust near the bearing surface. If the bearing is showing signs of wear the operating angle needs to be checked and rectified.
                                        </span>
                                        <span className='d-flex justify-content-start mt-3' ><i style={{ color: '#0F328E', fontSize: '17px' }}>Center bearing failures related to weight</i></span>
                                        <span className='d-flex justify-content-start '>Center bearings may also fail under the heavy weight of a drive shaft. Heavy duty drive shafts with thick walled tubes can
                                            cause the slotted rubber cushions of center bearing to collapse under load. Thus heavy duty drive shafts must have solid rubber cushion design with sufficient stiffness to withhold the weight of the drive shaft.</span>
                                        {/* ..............3................. */}
                                        <span className='d-flex justify-content-start mt-3' style={{ color: '#0F328E', fontSize: '20px' }}>Drive Shaft Tubing</span>
                                        <hr></hr>
                                        <span className='d-flex justify-content-start' ><i style={{ color: '#0F328E', fontSize: '17px' }}>Twisted Tubing</i></span>
                                        <span className='d-flex justify-content-start text-justify'>
                                            <ul>
                                                <li> This failure is usually the result of shock load. The shock load might occur due to the popping of the clutch or sudden engagement of spinning tires with the ground</li>
                                            </ul>
                                        </span>
                                        <span className='d-flex justify-content-start' ><i style={{ color: '#0F328E', fontSize: '17px' }}>Fractured Tubing</i></span>
                                        <span className='d-flex justify-content-start text-justify'>
                                            This type of failure is generally a fatigue type failure. a crack occurs in the tube weld circle and the progress around the tube.
                                            To avoid this kind of failure the tube weld seam must be kept in line of the yoke ear and the welding of the seam must be started 180 degrees from the tube weld seam.
                                            A high operating angle which generates large torsional vibrations are also the cause of fractured tubing's. U-joint operating angle should always be less than 3 degrees.
                                            Welding of the balance weight can also cause failure of drive shaft tubing. Welding of balanced weights near the tube weld seam or near the circle weld can cause the
                                            metallurgical structure to change and thus lead to premature fatigue failure. thus balance weights should never be welded over the tube weld seam or near the circle weld of the tube yoke
                                        </span>
                                        <span className='d-flex justify-content-start text-justify mt-2'>
                                            Tubing also fails at its critical speed. A drive shaft which is too long with respect to its operating speed may show this kind of failure. the critical speed
                                            must be calculated and should always be more than its maximum operating speed.
                                        </span>
                                        {/* ............4............... */}
                                        <span className='d-flex justify-content-start mt-3' style={{ color: '#0F328E', fontSize: '20px' }}>U Joint (TYPICAL UNIVERSAL JOINT KIT FAILURES)</span>
                                        <hr></hr>
                                        <span className='d-flex justify-content-start' ><i style={{ color: '#0F328E', fontSize: '17px' }}>Brinelling</i></span>
                                        <span className='d-flex justify-content-start text-justify'>
                                            When the needle marks get embossed on the bearing surface of the U-joint it is known as Brinelling. The main causes are excessive
                                            continuous torque loads, excessive driveline operating angle, seized slip yoke splines, bent yoke and over tight U-bolts. Torque
                                            to be transmitted by the drive shaft should be calculated and a U-joint should be used according to it.
                                        </span>
                                        <span className='d-flex justify-content-start mt-2' ><i style={{ color: '#0F328E', fontSize: '17px' }}>Spalling</i></span>
                                        <span className='d-flex justify-content-start '>When the surface of the bearing has been worn out and it looks like a layer of
                                            material have been removed from the surface it is known as Spalling. The main causes of Spalling are contamination of the bearing
                                            with water, unsuitable lube type or lubrication failure.</span>
                                        <span className='d-flex justify-content-start mt-2' ><i style={{ color: '#0F328E', fontSize: '17px' }}>Burned U-joint cross</i></span>
                                        <span className='d-flex justify-content-start text-justify'>
                                            When a bearing operates under insufficient lubricant it causes the trunnions to be burnt. This may occur when sufficient purging
                                            is not achieved in the bearing. Improper application or a use of wrong lube type can also cause burnt U-Joint cross. To avoid such
                                            cases recommended purging should be achieved at all the 4 u-joint seal. Recommended lube type should be used.
                                        </span>
                                        <span className='d-flex justify-content-start mt-2' ><i style={{ color: '#0F328E', fontSize: '17px' }}>End Galling/Galling</i></span>
                                        <span className='d-flex justify-content-start '>When the end of the trunnion looks as if material has been carved out of it,
                                            it is known as end galling. This may occur due to high u-joint operating angle, bent yoke or improper lubrication. To avoid this
                                            the operating angles must be kept below 3 degrees.</span>
                                        <span className='d-flex justify-content-start mt-2' ><i style={{ color: '#0F328E', fontSize: '17px' }}>U-joint Fractures</i></span>
                                        <span className='d-flex justify-content-start '>U-joint fractures may be a result of shock loads, excessive torque loads or a result of
                                            improper application. It can be avoided by using U-Joint suitable to the series of the driveline.</span>
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            {/* driveline lubrication section */}
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Driveline Lubrication</Accordion.Header>
                                <Accordion.Body>
                                    <span className='d-flex justify-content-start mt-3' style={{ color: '#0F328E', fontSize: '20px' }}>U-JOINT CROSS, BROKEN THROUGH LUBE FITTING HOLE</span>
                                    <hr></hr>
                                    <span className='d-flex justify-content-start text-justify'>
                                        This failure occurs when the u-joint is not installed in correct orientation in the drive shaft. When the driving torque stretches the lube fitting hole the U-joint fails through lube fitting hole. The orientation of lube fitting hole should be such that the driving torque must always compress the U-joint lube fitting hole.
                                        When seen from the driving end, the drive shaft rotates in clockwise direction. The lube fitting must be at 45 degrees to the right in north-east direction.
                                    </span>
                                    <span className='d-flex justify-content-start mt-2' ><i style={{ color: '#0F328E', fontSize: '17px' }}>Lube related failures.</i></span>
                                    <span className='d-flex justify-content-start text-justify mt-1'>
                                        Vast majority of drive shaft failures are related to Lube Failures. Lube related failures occur in U-joint and Slip area of the drive shaft.
                                        Improper lubricating procedure is the main cause of drive shaft failure.
                                    </span>
                                    <span className='d-flex justify-content-start mt-2' ><i style={{ color: '#0F328E', fontSize: '17px' }}>lube related failure are as follows:</i></span>
                                    <span className='d-flex justify-content-start text-justify'>
                                        <ul>
                                            <li className='mt-2'>Spalling</li>
                                            <li className='mt-2'>Brinelling</li>
                                            <li className='mt-2'>Burned off trunnions</li>
                                        </ul>
                                    </span>
                                    <span className='d-flex justify-content-start mt-3' style={{ color: '#0F328E', fontSize: '20px' }}>The causes of failures are as follows:</span>
                                    <hr></hr>
                                    <span className='d-flex justify-content-start mt-2' ><i style={{ color: '#0F328E', fontSize: '17px' }}>Incorrect lube procedure of U-Joint or Slip assembly</i></span>
                                    <span className='d-flex justify-content-start text-justify mt-1'>When the procedure of lubrication is not followed as recommended it causes failure over time.</span>
                                    <span className='d-flex justify-content-start text-justify'> When proper purging is not achieved at all 4 seals in a U-Joint or till the grease is not completely filled in the slip assembly of a yoke, it causes
                                        contamination or lack of lubricant in the desired areas and thus failure over time.
                                    </span>
                                    <span className='d-flex justify-content-start text-justify mt-2' >Proper purging ensures that the old lubricant is completely replaced by new lubricant. When proper purging is not achieved in a u-joint it should be replaced.
                                        To ensure that the slip assembly is filled with grease , grease must be forced into the assembly till it comes out of the vent hole in the Slip Yoke and then through the seal after covering the vent hole.
                                    </span>
                                    <span className='d-flex justify-content-start mt-3' style={{ color: '#0F328E', fontSize: '20px' }}>Using incorrect lube</span>
                                    <hr></hr>
                                    <span className='d-flex justify-content-start mt-2' ><i style={{ color: '#0F328E', fontSize: '17px' }}>Using incorrect lube or mixed lube can cause the performance of the lube to be below par.</i></span>
                                    <span className='d-flex justify-content-start text-justify'>
                                        <ul>
                                            <li className='mt-2'>The lube should be in accordance with NLGI Grade 2 spec, with an EP additive.</li>
                                            <li className='mt-2'>It should have a temperature range of +325 degrees F to –10 degrees F.</li>
                                        </ul>
                                    </span>
                                    <span className='d-flex justify-content-start mt-2' ><i style={{ color: '#0F328E', fontSize: '17px' }}>Lubrication at incorrect intervals.</i></span>
                                    <span className='d-flex justify-content-start text-justify mt-1'>U-joints should always be serviced at the manufacturers recommended intervals. The service intervals depend upon the application and
                                        the conditions of operation. The service intervals may vary between 3 months for regular over the road type vehicle to 500 hours for normal industrial application to 200 hours for heavy industrial
                                        applications in severe conditions.</span>
                                </Accordion.Body>
                            </Accordion.Item>
                            {/* vibrational section */}
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Vibrational Issues </Accordion.Header>
                                <Accordion.Body>
                                    <span className='d-flex justify-content-start' ><i style={{ color: '#0F328E', fontWeight: 'bold', fontSize: '17px' }}>Vibrations due to Imbalance</i></span>
                                    <span className='d-flex justify-content-start text-justify mt-1'>This type of vibration occurs due Imbalance in the shaft. The drive shaft rotates at much higher rpm than the tires. As the tires are
                                        balanced during the regular service, the driveshaft too needs to be balanced at regular service intervals. When a drive shaft is manufactured or repaired it must be dynamically balanced at,
                                        3000 RPM for light duty vehicles or at 2500 RPM for heavy duty application. During its service a drive shaft must be checked for missing balancing weights.</span>
                                    <span className='d-flex justify-content-start' ><i style={{ color: '#0F328E', fontWeight: 'bold', fontSize: '17px' }}>Critical Speed Vibration</i></span>
                                    <span className='d-flex justify-content-start text-justify mt-1'>Critical speed is the speed at which the drive shaft begins to bow away from its normal rotating center
                                        line and eventually fails, commonly at the center of the tubing. As the driveshaft approaches the Critical Speed it begins to vibrate, when the driveshaft operates near its
                                        critical speed for extended period of time it fails. The failure is generally catastrophic and can cause damage to the vehicle or injury to anyone in the vicinity.
                                        Thus manufactures should always calculate a driveshaft's critical speed and it should be sufficiently higher than the maximum recommended operating speed.</span>
                                    <span className='d-flex justify-content-start mt-3' style={{ color: '#0F328E', fontSize: '20px' }}>Critical speed can be calculated by the following formula:</span>
                                    <hr></hr>
                                    <span className='d-flex justify-content-start mt-1' >Ƞ max=(1.21x108√D2+d2)/L2</span>
                                    <span className='d-flex justify-content-start mt-1' >Where Ƞ= CRITICAL RPM</span>
                                    <span className='d-flex justify-content-start mt-1' >D= TUBE OUTSIDE DIA (mm)</span>
                                    <span className='d-flex justify-content-start mt-1' >d= TUBE INSIDE DIA (mm)</span>
                                    <span className='d-flex justify-content-start mt-1' >L= JOINT CENTERS LENGTH (mm)</span>
                                    <span className='d-flex justify-content-start mt-3' style={{ color: '#0F328E', fontSize: '20px' }}>The maximum operating speed should include a factor of safety as below:</span>
                                    <hr></hr>
                                    <span className='d-flex justify-content-start' ><i style={{ color: '#0F328E', fontWeight: '500', fontSize: '17px' }}>For Automotive application</i></span>
                                    <span className='d-flex justify-content-start mt-1' >Max safe RPM= Ƞmax X 0.70,</span>
                                    <span className='d-flex justify-content-start' ><i style={{ color: '#0F328E', fontWeight: '500', fontSize: '17px' }}>For Industrial application</i></span>
                                    <span className='d-flex justify-content-start mt-1' >Max safe RPM= Ƞmax X 0.65</span>
                                    <span className='d-flex justify-content-start mt-3' style={{ color: '#0F328E', fontSize: '20px' }}>Vibration due to U-Joint operating angle</span>
                                    <hr></hr>
                                    <span className='d-flex justify-content-start text-justify mt-1'>A higher U-Joint operating angle is the primary cause of various vibrations in a drive shaft.
                                        Every U-Joint operating at an angle produces vibration. The higher the angle more is the vibration. U-Joint operating at angles cause: Vibration, Reduced U-Joint
                                        life and problems with other drive train components.It causes twice per revolution change in the speed of the driveshaft.</span>
                                    <span className='d-flex justify-content-start text-justify mt-1'> Inertial vibrations are also caused due to higher operating angle at the drive end of the driveshaft.
                                        When the speed of a heavy drive shaft is changed it creates a bending moment due to higher operating angles which causes bending of the connecting components.</span>
                                    <span className='d-flex justify-content-start text-justify mt-1'> To avoid vibrations due to operating angle the operating angles at both the ends of drive.</span>
                                    <span className='d-flex justify-content-start mt-3' style={{ color: '#0F328E', fontSize: '20px' }}>Phasing to damp vibrations</span>
                                    <hr></hr>
                                    <span className='d-flex justify-content-start text-justify mt-1'>A properly phased driveshaft has the ears of yokes in line with each other. Drive shafts that are NOT in
                                        phase will vibrate at twice per revolution vibration, same as a drive shaft with incorrect operating angles.</span>
                                    <span className='d-flex justify-content-start text-justify mt-1'> Thus before taking the parts of driveshaft apart they must be properly marked, so that they are in phase when
                                        reassembled as they were when manufactured.</span>
                                </Accordion.Body>
                            </Accordion.Item>
                            {/* maintainance section */}
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Maintenance of Propeller Shafts</Accordion.Header>
                                <Accordion.Body>
                                    <span className='d-flex justify-content-start text-justify mt-1'>The propeller shafts are either Maintenance free or come with the option of lubrication In the lubrication
                                        design the four bearings of a joint are lubricated via lubricating nipple.</span>
                                    <span className='d-flex justify-content-start text-justify mt-1'> The lubricating nipples must be cleaned before lubrication.</span>
                                    <span className='d-flex justify-content-start text-justify mt-1'> The grease is forced into the lubricating nipple and transferred to the universal joint bushes,
                                        the grease must be forced into the UJ until proper purging is achieved at all four seals.</span>
                                    <span className='d-flex justify-content-start text-justify mt-1'> !! If it is not possible to lubricate all four bearings fully, the shaft must be dismantled.</span>
                                    <span className='d-flex justify-content-start mt-3' ><i style={{ color: '#0F328E', fontWeight: '500', fontSize: '17px' }}>Lubrication Intervals</i></span>
                                    <span className='d-flex justify-content-start text-justify mt-1'>U-joints should always be serviced at the manufacturers recommended intervals. The service intervals
                                        depend upon the application and the conditions of operation. The service intervals may vary between 3 months for regular over the road type vehicle to 500 hours for
                                        normal industrial application to 200 hours for heavy industrial applications in severe conditions.</span>
                                    <span className='d-flex justify-content-start mt-3' style={{ color: '#0F328E', fontSize: '20px' }}>Repair and Replacement of Parts on Propeller Shafts</span>
                                    <span className='d-flex justify-content-start text-justify mt-1'> Our propeller shafts are designed to be dismantled easily without the use of any special tools.</span>
                                    <span className='d-flex justify-content-start text-justify mt-1'> The dismantling should be carried out by experienced personnel.</span>
                                    <span className='d-flex justify-content-start text-justify mt-1'> Before dismantling a shaft the yoke ear position should be marked clearly. The yoke ears must be in line
                                        to prevent any undue vibrations due to secondary couple torque. The splined mating parts in a propeller shaft should be replaced together. The universal joint must always
                                        be replaced completely, usually pins of all the bearings are damaged simultaneously. So, replacing individual bearing should not be practiced.</span>
                                    <span className='d-flex justify-content-start mt-3' ><i style={{ color: '#0F328E', fontWeight: '500', fontSize: '17px' }}>3.1 Dismantling a Joint</i></span>
                                    <span className='d-flex justify-content-start text-justify'>
                                        <ul>
                                            <li className='mt-2'> Remove all four circlips with circlip pliers.</li>
                                            <li className='mt-2'> Push the universal joint and bushes to one side using a press.</li>
                                            <li className='mt-2'> Clamp the projecting bush in a screw vice and withdraw the bush with light hammer blows on the yoke. Do not reuse bearing bushes with thin walls.</li>
                                        </ul>
                                    </span>
                                    <span className='d-flex justify-content-start mt-3' ><i style={{ color: '#0F328E', fontWeight: '500', fontSize: '17px' }}>3.2 Assembling a Joint</i></span>
                                    <span className='d-flex justify-content-start text-justify'>
                                        <ul>
                                            <li className='mt-2'>Bearing bush must be pressed in the ear keeping the centerline exactly vertical.</li>
                                            <li className='mt-2'>  Suitable thickness of circlips must be used to achieve perfect fit. The UJ should be easily removable and at the same time no undue stress should be generated.</li>
                                            <li className='mt-2'> To check that the joint has been perfectly assembled, mount the shaft on flange end and make sure it runs true. Maximum permissible run out is 0.1mm. This check
                                                helps in most cases to avoid the need for balancing for shafts running below 1500rpm and considerably shortens the balancing process for shafts running above 1500rpm. For safety the
                                                balancing must be done at speed 10-15% above the maximum running speed in service.</li>
                                        </ul>
                                    </span>
                                    <span className='d-flex justify-content-start mt-1' >Repair and Replacement of Parts on Double Jointed Shafts</span>
                                    <span className='d-flex justify-content-start '>Repairs to double cardan shafts should be considered only in emergency cases when it is not possible to obtain replacement shafts.</span>
                                    <span className='d-flex justify-content-start mt-1' >!! For safety reasons, repairs should be carried out only by Authorized workshops.</span>

                                </Accordion.Body>
                            </Accordion.Item>
                            {/* Spline technical section */}
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>Spline Technical Information</Accordion.Header>
                                <Accordion.Body>
                                    <span className='d-flex justify-content-start text-justify mt-1'>"A splined shaft is a shaft having multiple groves, or key-seats, cut around its circumference for a portion of its length, in order that a sliding
                                        engagement may be made with corresponding internal groves of a mating part.</span>
                                    <span className='d-flex justify-content-start text-justify mt-1'>Splines are capable of carrying heavier loads than keys, permit lateral movement of a part, parallel to the axis of the shaft, while maintaining
                                        positive oration, and allow the attached part to be indexed or changed to another angular position.</span>
                                    <span className='d-flex justify-content-start text-justify mt-1'>Splines are of two types the one having straight sided teeth & other having curved sided teeth known as Involute splines.</span>
                                    <span className='d-flex justify-content-start mt-3' ><i style={{ color: '#0F328E', fontWeight: '500', fontSize: '17px' }}>Involute Splines:</i></span>
                                    <span className='d-flex justify-content-start text-justify mt-1'>These splines are similar in shape to involute gear teeth but they have pressure angles of 30, 37.5, or 45. There are two types of fits, the side fit and the major-diameter fit.
                                        Major diameter fit spline is suitable for applications where axes alignment between two parts (internal and external spline) is a critical design parameter.</span>
                                    <span className='d-flex justify-content-start text-justify mt-1'>There are certain manufacturing challenges when dealing with major dia. fit splined couplings, mainly, the restrictions associated with the precision grinding of
                                        the internal spline's major diameter. In some cases this may not even be possible taking into the account the grinding wheel diameter and the amount of space required for the grinding head.</span>
                                    <span className='d-flex justify-content-center mt-4'><img src={require('../../Assets/images/major-spline-pic.jpg')} ></img></span>
                                    <span className='d-flex justify-content-center mt-2' style={{ fontSize: '18px', fontWeight: 'bold' }}>MAJOR DIA FIT SPLINE</span>
                                    <span className='d-flex justify-content-start text-justify mt-1'>Side fit splined couplings are widely used in all industries including automotive and aerospace. The main feature of this type of coupling is its self-centering ability
                                        under load. Although not as precise as the major diameter fit spline, this type of coupling serves successfully in a wide variety of applications. In some cases, to improve the axes alignment and reduce vibrations the spline pilot
                                        diameters are introduced.</span>
                                    <span className='d-flex justify-content-center mt-4'><img src={require('../../Assets/images/SIDE-FIT-SPLINE-PIC.jpg')} ></img></span>
                                    <span className='d-flex justify-content-center mt-2' style={{ fontSize: '18px', fontWeight: 'bold' }}>SIDE FIT SPLINE</span>

                                    <span className='d-flex justify-content-start mt-3' ><i style={{ color: '#0F328E', fontWeight: '500', fontSize: '17px' }}>Straight-Sided Splines</i></span>
                                    <span className='d-flex justify-content-start text-justify mt-1'>The most popular are the SAE straight-side splines shown below. They have been used in many applications in the automotive and machine industries. Involute Splines
                                        are Favored over straight Sided Splines because of their greater strength and the fact that for any given pitch the tooling can cut any number of teeth resulting in a more cost effective production method.</span>
                                    <span className='d-flex justify-content-center mt-4'><img src={require('../../Assets/images/straight-sided-spline.jpg')} ></img></span>
                                    <span className='d-flex justify-content-center mt-2' style={{ fontSize: '18px', fontWeight: 'bold' }}>STRAIGHT SIDED SPLINE</span>

                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Technical

