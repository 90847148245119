import React, { useState, useEffect } from "react";
import { Row, Col } from 'react-bootstrap'
import { Input } from "reactstrap";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import axios from 'axios';
import { toast } from 'react-toastify';
import { handleError } from "../../../util/HandleErrors";
import { Navigate, useNavigate } from "react-router-dom";
import { BsWindowSidebar } from "react-icons/bs";
const ExcelDownload = ({ excelData, fileName, closeModal, sendClose, reloading }) => {

    const [uploadedFileName, setUploadedFileName] = useState("");
    const [bulklist, setBulklist] = useState([])
    const [changepage, setChangepage] = useState(0)
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate()
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setUploadedFileName(file.name);
        }
    }

    const uploadFileToAPI = async (event) => {
        event.preventDefault(); // Prevent the form from refreshing the page
        setLoading(true)
        const fileInput = event.target.elements.fileInput;
        const file = fileInput.files[0];

        try {
            const url = process.env.REACT_APP_BASEURL_TWO + 'bulk-import/';
            const formData = new FormData();
            formData.append('file', file);

            const response = await axios.post(url, formData, {
                headers: {
                    Authorization: localStorage.getItem('token'),
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200) {
                setLoading(false)
                sendClose(false)
                reloading(true)
                toast.success("Excle File Submiited Successful!", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                    theme: "colored",
                })
                // window.location.reload()
                // File uploaded successfully, you can handle the response here if needed
            } else {
                // Handle error when the API returns a non-successful response
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            if (error != {}) {
                const getErrRes = handleError(error);
                toast.error(getErrRes ? getErrRes : "Something went wrong.", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                    theme: "colored",
                });
            }
          
        }
    };
    //................ get bulkcart api...........
    // const getbulkcart = () => {
    //     if (localStorage.getItem('token')) {
    //         let url = process.env.REACT_APP_BASEURL_TWO + 'bulk-import/';
    //         const config = {
    //             headers: {
    //                 Authorization: localStorage.getItem('token'),
    //                 'Access-Control-Allow-Origin': '*',
    //                 'Content-type': 'application/json',
    //             },
    //         };
    //         axios
    //             .get(url, config)
    //             .then((res) => {
    //                 // setLoading(false);
    //                 // setBulklist(res.data.data);
    //                 console.log("fdsfsdfsd", res.data.data)
    //                 reloading(true)
    //                 // dispatch(updateCartQty(res.data.data.item_list.length))
    //                 console.log(res.data.data.item_list)
    //             })
    //             .catch((err) => {
    //                 // setLoading(false);
    //                 console.log(err);
    //             });
    //     } else {

    //         toast.error('Your session has expired. Please login again.', {
    //             autoClose: 1000,
    //             theme: 'colored',
    //         });
    //     }
    // };

    return (
        <>
            <Row className="d-flex col-12 justify-content-between " style={{ fontFamily: 'ROBOTO' }}>
                <Col lg={5} style={{ background: '#E9E9E9', padding: '15px' }} className='d-flex align-items-center flex-column'>
                    <div>
                        <p style={{ fontWeight: '500', textAlign: 'center' }}>Download Excel File</p>
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-primary" onClick={exportToExcel} style={{ backgroundColor: '#0f328e', borderColor: "#0f328e" }}>Download Excel</button>
                        </div>
                    </div>
                </Col>
                <Col lg={5} style={{ background: '#E9E9E9', padding: '15px' }}>
                    <p style={{ fontWeight: '500', textAlign: 'center' }}>Import Excel File</p>
                    <div className="input-group d-flex justify-content-center">
                        <form onSubmit={uploadFileToAPI}>
                            <input
                                type="file"
                                className="form-control d-none"
                                id="inputGroupFile08"
                                accept=".xlsx"
                                name="fileInput" // Give the input a name to reference it in the onSubmit function
                                onChange={handleFileInputChange}
                            />
                            <label className="input-group-text" htmlFor="inputGroupFile08" style={{ color: '#fff', cursor: "pointer", border: "1px solid #4CB5E2", background: "#4CB5E2", }}>
                                {uploadedFileName ? uploadedFileName : "Upload Excel File"}
                            </label>
                            <div className="d-flex justify-content-center">
                                <div >
                                    {loading ? <button className="btn btn-primary btn-sm mt-3" type="submit" style={{ backgroundColor: '#0f328e', borderColor: "#0f328e" }} disabled> <span
                                        className="spinner-border spinner-border-sm mx-1"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>Submitting....</button>
                                     : uploadedFileName !== '' ? <button className="btn btn-primary btn-sm mt-3" type="submit" style={{ backgroundColor: '#0f328e', borderColor: "#0f328e" }}>Submit</button> :
                                        <button className="btn btn-primary btn-sm mt-3" type="submit" style={{ backgroundColor: '#0f328e', borderColor: "#0f328e" }} disabled>Submit</button>}
                                </div>
                            </div>
                        </form>
                    </div>
                </Col>
            </Row>


        </>
    )
}

export default ExcelDownload;
