import React from 'react'

const Manufacture = () => {
  return (
    <div className='mt-5'>
      COMING SOON....
    </div>
  )
}

export default Manufacture
