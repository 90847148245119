import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Modal } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { Input } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { updateCartQty } from '../../../Redux/feature/cartSlice';
import Accordion from 'react-bootstrap/Accordion';
const CheckoutPage = () => {
    let navigate = useNavigate()
    const [sameAsBilling, setSameAsBilling] = useState(false); // State to track checkbox status
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [orderSummary, setOrderSummary] = useState({
        items: 0,
        subtotal: 0,
        discount: 0,
        total_amount: 0,
    });
    const [cartList, setCartList] = useState([]);
    const [loading, setLoading] = useState(false)
    const [countryList, setcountryList] = useState()
    const [publicId, setPublicId] = useState()
    const [objId, setObjId] = useState("")

    const [addData, setAddData] = useState({
        email: '', emailerr: '',
        full_name: '', full_nameerr: '',
        last_name: '', last_nameerr: '',
        address: '', addresserr: '',
        address_2: '', address_2err: '',
        state: '', stateerr: '',
        country: '', countryerr: '',
        city: '', cityerr: '',
        number: null,
        phone_number: '', phone_numbererr: '',
        zipcode: '', zipcodeerr: ''
    })
    const [shippingAddress, setShippingAddress] = useState({
        shipFull_name: '', shipFull_nameerr: '',
        shipLast_name: '', shipLast_nameerr: '',
        shipAddress: '', shipAddresserr: '',
        shipAddress_2: '', shipAddress_2err: '',
        shipState: '', shipStateerr: '',
        shipCountry: '', shipCountryerr: '',
        shipCity: '', shipCityerr: '',
        shipNumber: null,
        shipPhone_number: '', shipPhone_numbererr: '',
        shipZipcode: '', shipZipcodeerr: ''
    }); // State for shipping address data

    useEffect(() => {
        OrderSummmery()
        getUserAddress();
        // getCartlist()
    }, [])

    // const handleSubmit = async (e) => {
    //     e.preventDefault()
    //     let error = { status: 0, full_name: '', last_name: '', address: '', city: '', email: '', phone_number: '', zipcode: '', state: '', country: '', }
    //     if (addData.full_name === '') { error.full_name = 'Please enter full name'; error.status = 1 } else { error.full_name = '' }
    //     if (addData.last_name === '') { error.last_name = 'Please enter last name'; error.status = 1 } else { error.last_name = '' }
    //     if (addData.address === '') { error.address = 'Please enter address'; error.status = 1 } else { error.address = '' }
    //     if (addData.city === '') { error.city = 'Please enter city'; error.status = 1 } else { error.city = '' }
    //     if (addData.state === '') { error.state = 'Please enter state'; error.status = 1 } else { error.state = '' }
    //     if (addData.country === '') { error.country = 'Please enter country'; error.status = 1 } else { error.country = '' }
    //     if (addData.phone_number === '') { error.phone_number = 'Please enter Phone no'; error.status = 1 } else { error.phone_number = '' }
    //     if (addData.zipcode === '') { error.zipcode = 'Please enter zipcode'; error.status = 1 } else { error.zipcode = '' }

    //     if (addData.email <= 0 || addData.email === '') { error.email = 'Please enter email'; error.status = 1 } else { error.email = '' }
    //     setAddData({ ...addData, full_nameerr: error.full_name, last_nameerr: error.last_name, emailerr: error.email, phone_numbererr: error.phone_number, addresserr: error.address, cityerr: error.city, zipcodeerr: error.zipcode, stateerr: error.state, countryerr: error.country })
    //     if (error.status === 0) {


    //     setLoading(true)
    //     let url = process.env.REACT_APP_BASEURL_TWO + "order/"
    //     const config = {
    //         headers: {
    //             Authorization: localStorage.getItem('token'),
    //             "Access-Control-Allow-Origin": "*",
    //             "Content-type": "application/json"
    //         }
    //     };
    //     let data = {
    //         "first_name": addData.full_name,
    //         "last_name": addData.last_name,
    //         "address_1": addData.address,
    //         "address_2": addData.address_2,
    //         "email_for_order_confirmation": addData.email,
    //         "phone_number": addData.phone_number,
    //         "zip_code": addData.zipcode,
    //         "city": addData.city,
    //         "state": addData.state,
    //         "country": addData.country,
    //         "address_type": "billing_address"
    //     };
    //    
    //     console.log(data, "datttaaa")
    //     await axios.post(url, data, config)
    //         .then(res => {
    //             setLoading(false)
    //             console.log(res.data)
    //             toast.success("Thank you for placing the order with us", {
    //                 position: toast.POSITION.TOP_RIGHT,
    //                 autoClose: 2000,
    //                 theme: "colored",
    //             })
    //             navigate("/app/dashboard")
    //         })
    //         .catch(err => {
    //             setLoading(false)
    //             toast.error(`${err.response.data.error}`, {
    //                 autoClose: 1000,
    //                 theme: "colored",
    //             });
    //         })
    //     // }
    // }

    {/* Post api for Shipping address*/ }
    const handleSubmit = async (e) => {
        e.preventDefault()
        let body = {}
        setLoading(true)
        let url = process.env.REACT_APP_BASEURL_TWO + `shipping-address/${publicId}/`
        const config = {
            headers: {
                Authorization: localStorage.getItem('token'),
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json"
            }
        };
        if (sameAsBilling == true) {
            body.is_same = true;
            body.first_name_shipping = addData.full_name;
            body.last_name_shipping = addData.last_name;
            body.address_1_shipping = addData.address;
            body.city_shipping = addData.city;
            body.state_shipping = addData.state;
            body.zip_code_shipping = addData.zipcode;
            body.country_shipping = addData.country;
            body.mobile_no_shipping = addData.phone_number;
            body.address_type = "shipping_address"
        } else {
            body.is_same = false;
            body.first_name_shipping = shippingAddress.shipFull_name;
            body.last_name_shipping = shippingAddress.shipLast_name;
            body.address_1_shipping = shippingAddress.shipAddress;
            body.city_shipping = shippingAddress.shipCity;
            body.state_shipping = shippingAddress.shipState;
            body.zip_code_shipping = shippingAddress.shipZipcode;
            body.country_shipping = shippingAddress.shipCountry;
            body.mobile_no_shipping = shippingAddress.shipPhone_number;
            body.address_type = "shipping_address"
        }
        await axios.post(url, body, config)
            .then(res => {
                setLoading(false)
                setObjId(res.data.data.shipping_address_obj_id)

                // navigate("/app/dashboard")
            })
            .catch(err => {
                setLoading(false)
                toast.error(`${err.response.data.error}`, {
                    autoClose: 1000,
                    theme: "colored",
                });
            })
    }

    {/* Post api for Order create*/ }
    const orderCreate = async () => {
        setLoading(true)
        let url = process.env.REACT_APP_BASEURL_TWO + "order/"
        const config = {
            headers: {
                Authorization: localStorage.getItem('token'),
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json"
            }
        };
        const body = {
            shipping_address_obj_id: objId
        }

        await axios.post(url, body, config)
            .then(res => {
                setLoading(false)
                console.log(res.data)
                toast.success("Thank you for placing the order with us", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                    theme: "colored",
                })
                navigate("/app/dashboard")
            })
            .catch(err => {
                setLoading(false)
                toast.error(`${err.response.data.error}`, {
                    autoClose: 1000,
                    theme: "colored",
                });
            })
    }
    let dispatch = useDispatch()

    {/* Get api for Billing detail*/ }
    const getUserAddress = () => {
        setLoading(true);
        if (localStorage.getItem('token')) {
            let url = process.env.REACT_APP_BASEURL_TWO + 'order-address/';
            const config = {
                headers: {
                    Authorization: localStorage.getItem('token'),
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                },
            };
            axios
                .get(url, config)
                .then((res) => {
                    setLoading(false);
                    if (res.data.success) {
                        const userAddress = res.data.data[0];
                        // Populate user address data into the 'addData' state
                        setAddData({
                            ...addData,
                            email: userAddress.email,
                            full_name: userAddress.first_name,
                            last_name: userAddress.last_name,
                            address: userAddress.address_1,
                            address_2: userAddress.address_2,
                            city: userAddress.city,
                            state: userAddress.state,
                            zipcode: userAddress.zip_code,
                            phone_number: userAddress.mobile_no,
                            country: userAddress.country,
                        });
                        console.log("ffffffff", res.data.data)
                        setPublicId(res.data.data[0].public_id)
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    toast.error('Failed to fetch user address data.', {
                        autoClose: 1000,
                        theme: 'colored',
                    });
                });
        } else {
            navigate('/home');
            toast.error('Your session has expired. Please login again.', {
                autoClose: 1000,
                theme: 'colored',
            });
        }
    };

    {/* CHeckbox functionality*/ }
    const handleCheckboxChange = () => {
        setSameAsBilling(!sameAsBilling); // Toggle checkbox status
        if (!sameAsBilling) {
            // If the checkbox is checked, copy the billing address to shipping address
            setShippingAddress({
                full_name: addData.full_name,
                last_name: addData.last_name,
                address: addData.address,
                address_2: addData.address_2,
                city: addData.city,
                state: addData.state,
                zipcode: addData.zipcode,
                country: addData.country,
            });
        } else {
            // If the checkbox is unchecked, clear the shipping address
            setShippingAddress({});
        }
    };

    {/* Get api for cart*/ }
    const OrderSummmery = () => {
        setLoading(true);
        if (localStorage.getItem('token')) {
            let url = process.env.REACT_APP_BASEURL_TWO + 'cart/';
            const config = {
                headers: {
                    Authorization: localStorage.getItem('token'),
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                },
            };
            axios
                .get(url, config)
                .then((res) => {
                    setLoading(false);
                    // setCartList(res.data.data.item_list);
                    // dispatch(updateCartQty(res.data.data.length))
                    const { items, subtotal, discount, total_amount } = res.data.data;
                    setOrderSummary({ items, subtotal, discount, total_amount });

                })

                .catch((err) => {
                    setLoading(false);
                });
        } else {
            navigate('/home');
            toast.error('Your session has expired. Please login again.', {
                autoClose: 1000,
                theme: 'colored',
            });
        }
    };
    return (<>
        <ToastContainer></ToastContainer>
        <div style={{ marginTop: '50px', fontFamily: 'roboto' }}>
            <div className="row justify-content-evenly">
                <div className="col-lg-7 col-md-12 col-sm-12 col-12">
                    <div className=' mb-5'>
                        <div className='ms-md-4 ms-sm-0 p-3 text-left' style={{ background: '#f7f7f7', borderTopRightRadius: '10px', borderTopLeftRadius: '10PX', fontSize: '19px' }}>Customer Detail</div>
                        <Card className='ms-md-4 ms-sm-0' style={{ boxShadow: '1px 1px 2px 0px #b0b0b0', background: '#ffffff' }}>
                            <Card.Body className='text-left'>
                                <Form onSubmit={handleSubmit}>
                                    <Row className='col-12 ' >
                                        <p>Billing address</p>

                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control type="email" placeholder="Email for order confirmation" readOnly
                                                defaultValue={addData.email} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, email: e.target.value, emailerr: '' }) : setAddData({ ...addData, email: '', emailerr: 'email  is required.' }) }} />
                                            <small className="text-danger">{addData.emailerr}</small>
                                        </Form.Group>
                                    </Row>
                                    <Row className='col-12'>
                                        <Col sm={12} lg={6}>
                                            <Form.Group className="m-1  text-justify" >
                                                <Form.Control type="text" placeholder="Full Name"
                                                    defaultValue={addData.full_name} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, full_name: e.target.value, full_nameerr: '' }) : setAddData({ ...addData, full_name: '', full_nameerr: 'fullname  is required.' }) }} />
                                                <small className="text-danger ">{addData.full_nameerr}</small>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} lg={6}>
                                            <Form.Group className="m-1 text-justify" controlId="formBasicEmail">
                                                <Form.Control type="text" placeholder="Last Name"
                                                    defaultValue={addData.last_name} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, last_name: e.target.value, last_nameerr: '' }) : setAddData({ ...addData, last_name: '', last_nameerr: 'lastname  is required.' }) }} />
                                                <small className="text-danger ">{addData.last_nameerr}</small>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='col-12 ' >
                                        <Form.Group className="mb-3 mt-2" controlId="formBasicEmail">
                                            <Form.Control type="text" placeholder="address-1"
                                                defaultValue={addData.address} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, address: e.target.value, addresserr: '' }) : setAddData({ ...addData, address: '', addresserr: 'address  is required.' }) }} />
                                            <small className="text-danger">{addData.addresserr}</small>
                                        </Form.Group>
                                    </Row>
                                    <Row className='col-12 ' >
                                        <Form.Group className="mb-3" >
                                            <Form.Control type="text" placeholder="address-2 (optional)"
                                                defaultValue={addData.address_2} onChange={(e) => { setAddData({ ...addData, address_2: e.target.value }) }} />
                                            <small className="text-danger"></small>
                                        </Form.Group>
                                    </Row>
                                    <Row className='col-12 ' >
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control type="text" placeholder="City"
                                                defaultValue={addData.city} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, city: e.target.value, cityerr: '' }) : setAddData({ ...addData, city: '', cityerr: 'city  is required.' }) }} />
                                            <small className="text-danger">{addData.cityerr}</small>
                                        </Form.Group>
                                    </Row>
                                    <Row className='col-12'>
                                        <Col sm={12} lg={6}>
                                            <Form.Group className="m-1">
                                                <Form.Control type="text" placeholder="Country"
                                                    defaultValue={addData.country} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, country: e.target.value, countryerr: '' }) : setAddData({ ...addData, country: '', countryerr: 'country  is required.' }) }} />
                                                <small className="text-danger ">{addData.countryerr}</small>


                                                {/* {countryList.length === 0 ? <Form.Select><option>Not Found</option></Form.Select> : <Form.Select style={{ padding: '15px' }} >
                                                    <option disabled>Vehicle List</option>

                                                    {countryList.map((item) => {

                                                        return (<option onChange={() => { setPublicId(item) }}>{item.name}</option>)
                                                    })
                                                    }
                                                </Form.Select>} */}
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} lg={6}>
                                            <Form.Group className="m-1 text-justify" controlId="formBasicEmail">
                                                <Form.Control type="text" placeholder="State"
                                                    defaultValue={addData.state} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, state: e.target.value, stateerr: '' }) : setAddData({ ...addData, state: '', nameerr: 'state  is required.' }) }} />
                                                <small className="text-danger ">{addData.stateerr}</small>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className='col-12'>
                                        <Col sm={12} lg={6}>
                                            <Form.Group className="m-1  text-justify" >
                                                <Form.Control type="tel" placeholder="Zip Code"
                                                    defaultValue={addData.zipcode} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, zipcode: e.target.value, zipcodeerr: '' }) : setAddData({ ...addData, zipcode: '', zipcodeerr: 'zipcode  is required.' }) }} />
                                                <small className="text-danger ">{addData.zipcodeerr}</small>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} lg={6}>
                                            <Form.Group className="m-1  text-justify" controlId="formBasicPassword">
                                                <Form.Control type="number" placeholder="Enter phone number"
                                                    maxLength={10} value={addData.phone_number} onChange={(e) => { e.target.value !== '' || e.target.value.length > e.target.maxLength ? setAddData({ ...addData, phone_number: e.target.value.slice(0, e.target.maxLength), phone_numbererr: '' }) : setAddData({ ...addData, phone_number: '', phone_numbererr: "Phone no. is required" }) }} />
                                                <small className="text-danger">{addData.phone_numbererr}</small>

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {/* ............Shipping Detail..................... */}
                                    {/* <Row className='col-12'>
                                        <p>Shipping Details:</p>
                                        <div className="d-flex">
                                            <Input
                                                type="checkbox"
                                                // checked={selectAll}
                                                // onChange={toggleSelectAll}
                                                id="selectAllCheckbox"
                                            />
                                            <p className="ms-4">same as billing address</p>
                                        </div>
                                        <Col sm={12} lg={6}>
                                            <Form.Group className="m-1  text-justify" >
                                                <Form.Control type="text" placeholder="Full Name"
                                                    defaultValue={addData.full_name} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, name: e.target.value, nameerr: '' }) : setAddData({ ...addData, name: '', nameerr: 'name  is required.' }) }} />
                                                <small className="text-danger ">{addData.full_nameerr}</small>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} lg={6}>
                                            <Form.Group className="m-1 text-justify" controlId="formBasicEmail">
                                                <Form.Control type="text" placeholder="Last Name"
                                                    defaultValue={addData.last_name} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, name: e.target.value, nameerr: '' }) : setAddData({ ...addData, name: '', nameerr: 'name  is required.' }) }} />
                                                <small className="text-danger ">{addData.last_nameerr}</small>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='col-12 ' >
                                        <Form.Group className="mb-3 mt-2" controlId="formBasicEmail">
                                            <Form.Control type="text" placeholder="address-1"
                                                defaultValue={addData.email} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, email: e.target.value, emailerr: '' }) : setAddData({ ...addData, email: '', emailerr: 'email  is required.' }) }} />
                                            <small className="text-danger">{addData.emailerr}</small>
                                        </Form.Group>
                                    </Row>
                                    <Row className='col-12 ' >
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control type="email" placeholder="address-2 (optional)"
                                                defaultValue={addData.email} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, email: e.target.value, emailerr: '' }) : setAddData({ ...addData, email: '', emailerr: 'email  is required.' }) }} />
                                            <small className="text-danger">{addData.emailerr}</small>
                                        </Form.Group>
                                    </Row>
                                    <Row className='col-12 ' >
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control type="text" placeholder="City"
                                                defaultValue={addData.email} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, email: e.target.value, emailerr: '' }) : setAddData({ ...addData, email: '', emailerr: 'email  is required.' }) }} />
                                            <small className="text-danger">{addData.emailerr}</small>
                                        </Form.Group>
                                    </Row>
                                    <Row className='col-12'>
                                        <Col sm={12} lg={6}>
                                            <Form.Group className="m-1  text-justify" >
                                                <Form.Control type="text" placeholder="Country"
                                                    defaultValue={addData.name} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, name: e.target.value, nameerr: '' }) : setAddData({ ...addData, name: '', nameerr: 'name  is required.' }) }} />
                                                <small className="text-danger ">{addData.nameerr}</small>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} lg={6}>
                                            <Form.Group className="m-1 text-justify" controlId="formBasicEmail">
                                                <Form.Control type="text" placeholder="State"
                                                    defaultValue={addData.name} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, name: e.target.value, nameerr: '' }) : setAddData({ ...addData, name: '', nameerr: 'name  is required.' }) }} />
                                                <small className="text-danger ">{addData.nameerr}</small>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className='col-12'>
                                        <Col sm={12} lg={6}>
                                            <Form.Group className="m-1  text-justify" >
                                                <Form.Control type="text" placeholder="Zip Code"
                                                    defaultValue={addData.name} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, name: e.target.value, nameerr: '' }) : setAddData({ ...addData, name: '', nameerr: 'name  is required.' }) }} />
                                                <small className="text-danger ">{addData.nameerr}</small>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} lg={6}>
                                            <Form.Group className="m-1 text-justify" controlId="formBasicEmail">
                                                <Form.Control type="text" placeholder="Phone Number"
                                                    defaultValue={addData.name} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, name: e.target.value, nameerr: '' }) : setAddData({ ...addData, name: '', nameerr: 'name  is required.' }) }} />
                                                <small className="text-danger ">{addData.nameerr}</small>
                                            </Form.Group>
                                        </Col>
                                    </Row> */}
                                    {
                                        sameAsBilling == true ?
                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header> <div className="d-flex">
                                                        <Input
                                                            type="checkbox"
                                                            checked={sameAsBilling}
                                                            onChange={handleCheckboxChange} // Call the handler on checkbox change
                                                            id="selectAllCheckbox"
                                                        />
                                                        <p className="ms-4">Same as billing address</p>
                                                    </div></Accordion.Header>

                                                </Accordion.Item>
                                            </Accordion>
                                            :

                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header> <div className="d-flex">
                                                        <Input
                                                            type="checkbox"
                                                            checked={sameAsBilling}
                                                            onChange={handleCheckboxChange} // Call the handler on checkbox change
                                                            id="selectAllCheckbox"
                                                        />
                                                        <p className="ms-4">Same as billing address</p>
                                                    </div></Accordion.Header>
                                                    <Accordion.Body>
                                                        <Row className='col-12'>
                                                            <p>Shipping Details:</p>
                                                            <Col sm={12} lg={6}>
                                                                <Form.Group className="m-1  text-justify" >
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Full Name"
                                                                        value={shippingAddress.shipFull_name || ''}
                                                                        onChange={(e) =>
                                                                            setShippingAddress({ ...shippingAddress, shipFull_name: e.target.value })
                                                                        }
                                                                    />
                                                                    {/* <small className="text-danger ">{addData.full_nameerr}</small> */}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} lg={6}>
                                                                <Form.Group className="m-1 text-justify" controlId="formBasicEmail">
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Last Name"
                                                                        value={shippingAddress.shipLast_name || ''}
                                                                        onChange={(e) =>
                                                                            setShippingAddress({ ...shippingAddress, shipLast_name: e.target.value })
                                                                        }
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row className='col-12 ' >
                                                            <Form.Group className="mb-3 mt-2" controlId="formBasicEmail">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="address"
                                                                    value={shippingAddress.shipAddress || ''}
                                                                    onChange={(e) =>
                                                                        setShippingAddress({ ...shippingAddress, shipAddress: e.target.value })
                                                                    }
                                                                />

                                                            </Form.Group>
                                                        </Row>
                                                        {/* <Row className='col-12 ' >
                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Address2 "
                                                                    value={shippingAddress.address_2 || ''}
                                                                    onChange={(e) =>
                                                                        setShippingAddress({ ...shippingAddress, address_2: e.target.value })
                                                                    }
                                                                />

                                                            </Form.Group>
                                                        </Row> */}
                                                        <Row className='col-12 ' >
                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="City"
                                                                    value={shippingAddress.shipCity || ''}
                                                                    onChange={(e) =>
                                                                        setShippingAddress({ ...shippingAddress, shipCity: e.target.value })
                                                                    }
                                                                />


                                                            </Form.Group>
                                                        </Row>
                                                        <Row className='col-12'>
                                                            <Col sm={12} lg={6}>
                                                                <Form.Group className="m-1  text-justify" >
                                                                    <Form.Control type="text" placeholder="Country"
                                                                        value={shippingAddress.shipCountry || ''}
                                                                        onChange={(e) =>
                                                                            setShippingAddress({ ...shippingAddress, shipCountry: e.target.value })
                                                                        } />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} lg={6}>
                                                                <Form.Group className="m-1 text-justify" controlId="formBasicEmail">
                                                                    <Form.Control type="text" placeholder="State"
                                                                        value={shippingAddress.shipState || ''}
                                                                        onChange={(e) =>
                                                                            setShippingAddress({ ...shippingAddress, shipState: e.target.value })
                                                                        } />

                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row className='col-12'>
                                                            <Col sm={12} lg={6}>
                                                                <Form.Group className="m-1  text-justify" >
                                                                    <Form.Control type="text" placeholder="Zip Code"
                                                                        value={shippingAddress.shipZipcode || ''}
                                                                        onChange={(e) =>
                                                                            setShippingAddress({ ...shippingAddress, shipZipcode: e.target.value })
                                                                        } />

                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} lg={6}>
                                                                <Form.Group className="m-1  text-justify" controlId="formBasicPhone">
                                                                    <Form.Control type="number" placeholder="Enter phone number"
                                                                        maxLength={10} value={shippingAddress.shipPhone_number || ''}
                                                                        // onChange={(e) =>
                                                                        //     setShippingAddress({ ...shippingAddress, shipPhone_number: e.target.value })
                                                                        // }
                                                                        onChange={(e) => {
                                                                            const inputValue = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
                                                                            if (inputValue.length <= 10) {
                                                                                setShippingAddress({ ...shippingAddress, shipPhone_number: inputValue });
                                                                            }
                                                                        }}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                    }

                                    <div className="d-flex justify-content-end p-3" style={{}} >
                                        <p style={{ fontSize: '20px' }} className='mr-4 pe-3'>Total Price<br></br><span style={{ fontSize: '20px', color: '#4CB5E2' }}>${orderSummary.total_amount.toFixed(2)}</span></p>

                                        <div>

                                            <Button onClick={handleShow} className="ms-1" type="submit" style={{ background: '#4CB5E2', borderColor: '#4CB5E2' }}>
                                                Place Order
                                            </Button>

                                        </div>
                                    </div>
                                    <Modal show={showModal} onHide={handleClose} centered style={{ fontFamily: 'roboto' }}>
                                        <Modal.Header closeButton style={{ background: "#4CB5E2" }}>
                                            <Modal.Title>Order Confirmation</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {/* Add any content you want to display in the modal body */}
                                           Would you like to confirm your Order ?
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose}>
                                                Close
                                            </Button>
                                            <Button variant="primary" style={{ background: "#4CB5E2", borderColor:'#4cb5e2'}} onClick={() => orderCreate()}>
                                                Submit Order
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>

                {/* <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                        <div className="card w-lg-50 w-md-100 w-sm-100 w-50" style={{ backgroundColor: '#0F328E' }} >
                            <div className="card-body">
                                <h5 className="card-title text-white">Order Summery</h5>
                                <Row className='d-flex justify-content-between mt-4 text-white'>
                                    <Col>Items </Col>
                                    <Col>01</Col>
                                </Row>
                                <Row className='d-flex justify-content-between mt-4 text-white'>
                                    <Col>Subtotal </Col>
                                    <Col>3335</Col>
                                </Row>
                                <Row className='d-flex justify-content-between mt-4 text-white'>
                                    <Col>Discount </Col>
                                    <Col>0.00</Col>
                                </Row>
                                <Row className='d-flex justify-content-between mt-4 text-info'>
                                    <Col>Total </Col>
                                    <Col>1234</Col>
                                </Row>
                                <div className='d-flex justify-content-center mt-5 '>
                                    <Button style={{ background: '#4CB5E2', borderColor: '#4CB5E2' }} >Place Order</Button>
                                </div>
                            </div>
                        </div>
                    </div> */}


                <div className="col-lg-4 col-md-12 col-sm-12 col-12 " >
                    <div className="card w-lg-50 w-md-100 w-sm-100 w-50" style={{ backgroundColor: '#0F328E' }} >
                        <div className="card-body ">
                            <h5 className="card-title text-white">Order Summery</h5>
                            <Row className='d-flex justify-content-between mt-4 text-white'>
                                <Col>Items </Col>
                                <Col>{cartList.length}</Col>
                            </Row>
                            <Row className='d-flex justify-content-between mt-4 text-white'>
                                <Col>Subtotal </Col>
                                <Col>${orderSummary.subtotal.toFixed(2)}</Col>
                            </Row>
                            <Row className='d-flex justify-content-between mt-4 text-white'>
                                <Col>Discount </Col>
                                <Col>${orderSummary.discount.toFixed(2)}</Col>
                            </Row>
                            <Row className='d-flex justify-content-between mt-4 text-info'>
                                <Col>Total </Col>
                                <Col>${orderSummary.total_amount.toFixed(2)}</Col>
                            </Row>
                            <Row>
                                <Col className='mt-3'>
                                    {/* <span style={{ color: 'black', fontWeight: '500', fontSize: '16px' }}>Check Delivery</span> */}
                                    <div className="mt-2 d-flex">
                                        <Input style={{ border: '1px solid #D9D9D9', borderRadius: '4px', borderLeft: "0px", padding: "7px 15px 8px 15px" }} placeholder='Enter Pincode/Zipcode'></Input>
                                        <Button style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', borderRight: '0px', background: '#4CB5E2', borderRadius: '4px', color: 'white', border: 'none', padding: '0px 20px 0px 20px' }}>Check</Button>
                                    </div>
                                </Col>
                            </Row>
                            <div className='d-flex justify-content-center mt-5 '>
                                <Button style={{ background: '#4CB5E2', borderColor: '#4CB5E2' }} onClick={() => { navigate('/checkout') }}>Check Out</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
    )
}

export default CheckoutPage
