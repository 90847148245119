import React from 'react'
import { Row, Col } from 'react-bootstrap'


const Engineering = () => {
    return (
        <div>
            <div style={{ marginTop: '40px', fontFamily: 'roboto' }} className='container-fluid'>
                <div className='background_contain'>
                    <div className="content-block">
                        <div>
                            <div className="section-full call-action " >



                                <div style={{ padding: '20px', }}>
                                    <div className="col-lg-12 section-head text-center mt-2 mb-4">
                                        <h4 className="title">Engineering</h4>
                                        {/* <div className="dlab-separator-outer">
                                            <div className="dlab-separator bg-primary style-skew" />
                                        </div> */}
                                    </div>
                                    <div className='container'>
                                        <p style={{ color: '#0F328E', fontSize: '21px', fontFamily: 'roboto' }}>Adroit's strong engineering focus and customer-centric approach are indeed integral to
                                            the company's operations. Here are some key points regarding its engineering-driven
                                            and customer-centric approach:</p>
                                    </div>
                                    {/* <div className="col-lg-12 section-head text-center mt-3">
                                        <h4 className="title">Forging Division</h4>
                                        <div className="dlab-separator-outer">
                                            <div className="dlab-separator bg-primary style-skew" />
                                        </div>
                                    </div> */}
                                    <Row className='d-flex justify-content-center col-12 p-5'>
                                        <Col lg={4} md={10} className='d-flex justify-content-center '  >
                                            <img src={require("../../Assets/images/engineering.JPG")} style={{  aspectRatio: '16/9' }}></img>

                                        </Col>
                                        <Col lg={6} md={12} className='d-flex flex-column justify-content-center  p-3' >
                                            <div className=" d-flex flex-column justify-content-center text-justify " >
                                                <p className="about-inner-content" style={{ color: '#767676', fontSize: '14px' }}>
                                                    <ul>
                                                        <li style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }} className='mt-2'> <span style={{ color: '#4A4949', fontWeight: '500', fontSize: '18px' }}> Engineering as the Soul:</span> <br></br>
                                                            Adroit recognizes engineering as a core aspect of its
                                                            company. It underscores the importance we place on technical expertise and
                                                            innovation in driving its business forward. Engineering forms the foundation of
                                                            its product development and solution-driven approach.</li>
                                                        <li className='mt-2' style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }} ><span style={{ color: '#4A4949', fontWeight: '500', fontSize: '18px' }}>Adaptation to Changing Markets:</span> <br></br>
                                                            Adroit&#39;s ability to adapt to changing markets
                                                            and driveline technologies demonstrates its agility and responsiveness. We
                                                            proactively seek to understand the evolving needs of our customers and develop
                                                            innovative solutions that meet those requirements. This adaptability allows them
                                                            to stay ahead in a fast-paced industry.</li>
                                                    

                                                    </ul>
                                                </p>
                                            </div>
                                        </Col>
                                        <Col className='col-10 '>
                                          
                                            <ul style={{ textAlign: 'justify' }}>
                                                <li className='mt-2' style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }} > <span style={{ color: '#4A4949', fontWeight: '500', fontSize: '18px' }}>Collaboration and Expertise: </span> <br></br>
                                                    Adroit&#39;s cross-functional and multi-disciplinary
                                                    teams highlight their commitment to collaboration and expertise. By bringing
                                                    together professionals from various disciplines, such as design, manufacturing,
                                                    quality, purchasing, and sales, we foster a holistic approach to product
                                                    development. This collaboration ensures that the products we deliver are reliable,
                                                    durable, and cost-effective.</li>
                                                <li className='mt-2' style={{ fontSize: '14px', fontFamily: 'roboto', color: '#767676' }}><span style={{ color: '#4A4949', fontWeight: '500', fontSize: '18px' }}>Emphasis on Efficiency:  </span><br></br>
                                                    Adroit&#39;s focus on efficiency is evident in our product
                                                    development process. We leverage advanced technologies and analysis methods
                                                    to streamline design and testing, enabling faster iterations and reducing time to
                                                    market. This emphasis on efficiency benefits our customers by providing timely
                                                    solutions and optimizing costs</li>

                                            </ul></Col>
                                    </Row>
                                </div>
                              

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Engineering
