import React, { useState, useEffect } from "react";
import "../Layout/Fulllayout.css";
import { MdOutlinePolicy } from "react-icons/md";
import { TbReceiptTax } from "react-icons/tb";
import { HiBars3 } from "react-icons/hi2";
import { IoSettingsOutline } from "react-icons/io5";
import { Outlet, NavLink, useNavigate, useLocation } from "react-router-dom";
import logoImg from "../../../Assets/images/adroitlogo.png";
import smallLogo from "../../../Assets/images/logo-1.png";
import { ImUserPlus } from "react-icons/im";
import {FaHome,FaRegFile,FaExchangeAlt,FaAngleDoubleRight,FaAngleDoubleLeft,FaQrcode,} from "react-icons/fa";
import Offcanvas from "react-bootstrap/Offcanvas";
import Webnavbar from "../Webnavbar/Webnavbar";
const Fulllayout = () => {
    let navigate = useNavigate()
    let location = useLocation()
    const [isClick, setIsCLick] = useState(false)
    const [isOpen, setIsOpen] = useState(true);
    const toggle = () => setIsOpen(!isOpen);
    const [show, setShow] = useState(false);
    const handleClosed = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <main>
            {/********header**********/}
            <div className="pageWrapper d-lg-flex overflow-hidden" >
                {/********Sidebar**********/}
                {isClick ? null : <aside className="sidebarArea shadow bg-white" id="sidebarArea" >
                    {/* -----------------------sidebar design----------------------------- */}
                    <div className="dashItem">
                        <div className="sidebar" style={{ width: location.pathname === '/bulkimport' ? !isOpen ? "250px" : "90px": isOpen ? "250px" : "90px" }}>
                            <div className="top_section">
                                <h1 className="logo">
                                    {location.pathname === '/bulkimport' ?!isOpen == " " && " " ? (
                                        <img
                                            src={smallLogo}
                                            className="img-fluid mx-1 mt-1 mb-0"
                                            style={{ width: 40, height: 30, transition: "all 0.5s" }}
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            src={logoImg}
                                            className="img-fluid px-3"
                                            style={{ width: 190, height: 60, transition: "all 0.5s" }}
                                            alt=""
                                        />
                                    ):isOpen == " " && " " ? (
                                        <img
                                            src={smallLogo}
                                            className="img-fluid mx-1 mt-1 mb-0"
                                            style={{ width: 40, height: 30, transition: "all 0.5s" }}
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            src={logoImg}
                                            className="img-fluid px-3"
                                            style={{ width: 190, height: 60, transition: "all 0.5s" }}
                                            alt=""
                                        />
                                    )}
                                </h1>
                            </div>
                            <div>
                                <div
                                    id="openSidebarMenu"
                                    className="bars col"
                                    style={{
                                        marginLeft: location.pathname === '/bulkimport' ? !isOpen ? "224px" : "65px": isOpen ? "224px" : "65px",
                                        transition: "all 0.5s",
                                    }}
                                >
                                    {location.pathname === '/bulkimport' ?!isOpen == " " && " " ? (
                                        <FaAngleDoubleRight
                                            className="barIcon fw-bold text-black"
                                            onClick={toggle}
                                        />
                                    ) : (
                                        <FaAngleDoubleLeft
                                            className="barIcon fw-bold text-black"
                                            onClick={toggle}
                                        />
                                    ):isOpen == " " && " " ? (
                                        <FaAngleDoubleRight
                                            className="barIcon fw-bold text-black"
                                            onClick={toggle}
                                        />
                                    ) : (
                                        <FaAngleDoubleLeft
                                            className="barIcon fw-bold text-black"
                                            onClick={toggle}
                                        />
                                    )}
                                </div>

                                <NavLink
                                    className="link mt-4"
                                    // activeclassName="active"
                                    style={{ textDecoration: "none" }}
                                    to="/app/home"
                                >
                                    <div className="link_icon">
                                        <FaHome />
                                    </div>
                                    <div
                                        className="link_text"
                                        style={{ display: location.pathname === '/bulkimport' ? !isOpen ? "block" : "none" :isOpen ? "block" : "none" }}
                                    >
                                        <div className=" ms-2">Home</div>
                                    </div>
                                </NavLink>
                                <NavLink
                                    className="link mt-4"
                                    style={{ textDecoration: "none" }}
                                    to="/app/dashboard"
                                >
                                    <div className="link_icon">
                                        <FaQrcode />
                                    </div>
                                    <div
                                        className="link_text"
                                        style={{ display: location.pathname === '/bulkimport' ? !isOpen ? "block" : "none" : isOpen ? "block" : "none" }}
                                    >
                                        <div className=" ms-2">Dashboard</div>
                                    </div>
                                </NavLink>
                                <NavLink
                                    className="link mt-4"
                                    // activeclassName="active"
                                    style={{ textDecoration: "none" }}
                                    to="/app/application"
                                >
                                    <div className="link_icon">
                                        <TbReceiptTax />
                                    </div>
                                    <div
                                        className="link_text"
                                        style={{ display: location.pathname === '/bulkimport' ? !isOpen ? "block" : "none" : isOpen ? "block" : "none" }}
                                    >
                                        <div className=" ms-2">Application</div>
                                    </div>
                                </NavLink>
                                <NavLink
                                    className="link mt-4"
                                    // activeclassName="active"
                                    style={{ textDecoration: "none" }}
                                    to="/productlist"
                                >
                                    <div className="link_icon">
                                        <MdOutlinePolicy />
                                    </div>
                                    <div
                                        className="link_text"
                                        style={{ display: location.pathname === '/bulkimport' ? !isOpen ? "block" : "none" : isOpen ? "block" : "none" }}
                                    >
                                        <div className=" ms-2">Products</div>
                                    </div>
                                </NavLink>
                                <NavLink
                                    className="link mt-4"
                                    // activeclassName="active"
                                    style={{ textDecoration: "none" }}
                                    to="/app/manufacture"
                                >
                                    <div className="link_icon">
                                        <FaExchangeAlt />
                                    </div>
                                    <div
                                        className="link_text"
                                        style={{ display: location.pathname === '/bulkimport' ? !isOpen ? "block" : "none" : isOpen ? "block" : "none" }}
                                    >
                                        <div className=" ms-2">Manufacturing</div>
                                    </div>
                                </NavLink>
                                <NavLink
                                    className="link mt-4"
                                    // activeclassName="active"
                                    style={{ textDecoration: "none" }}
                                    to="/app/technical"
                                >
                                    <div className="link_icon">
                                        <FaRegFile />
                                    </div>
                                    <div
                                        className="link_text"
                                        style={{ display: location.pathname === '/bulkimport' ? !isOpen ? "block" : "none" : isOpen ? "block" : "none" }}
                                    >
                                        <div className=" ms-2">Technical</div>
                                    </div>
                                </NavLink>


                                <NavLink
                                    className="link mt-4"
                                    // activeclassName="active"
                                    style={{ textDecoration: "none" }}
                                    to="/app/contact"
                                >
                                    <div className="link_icon">
                                       
                                        <ImUserPlus />
                                 
                                    </div>
                                    <div
                                        className="link_text"
                                        style={{ display: location.pathname === '/bulkimport' ? !isOpen ? "block" : "none" : isOpen ? "block" : "none" }}
                                    >
                                        <div className=" ms-2">Contact us</div>
                                    </div>
                                </NavLink>
                                <NavLink
                                    className="link mt-4"
                                    // activeclassName="active"
                                    style={{ textDecoration: "none" }}
                                    to="/app/investor"
                                >
                                    <div className="link_icon">
                                        <IoSettingsOutline />
                                    </div>
                                    <div
                                        className="link_text"
                                        style={{ display: location.pathname === '/bulkimport' ? !isOpen ? "block" : "none" : isOpen ? "block" : "none" }}
                                    >
                                        <div className=" ms-2">Investor Relations</div>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                        {/* --------------------sidebar--for small screen size----------     */}
                        <div className="smallSidebar">
                            <div className="row">
                                <div className="col">
                                  <HiBars3 onClick={handleShow} className="smallSidebarIcon" />
                                </div>
                            </div>

                            <Offcanvas className="canvascard" show={show} onHide={handleClosed}>
                                <Offcanvas.Header className="top_section" closeButton>
                                    <img
                                        src={logoImg}
                                        alt=""
                                        className="img-fluid px-5"
                                        style={{ width: 250, height: 60, transition: "all 0.5s" }}
                                    />
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <div>
                                        <NavLink
                                            className="link mt-4"
                                            // activeclassName="active"
                                            style={{ textDecoration: "none" }}
                                            to="/app/home"
                                        >
                                            <div className="link_icon">
                                                <FaHome />
                                            </div>
                                            <div
                                                className="link_text"
                                                style={{ display: isOpen ? "block" : "none" }}
                                            >
                                                <div className=" ms-2">Home</div>
                                            </div>
                                        </NavLink>
                                        <NavLink
                                            className="link mt-4 "
                                            style={{ textDecoration: "none" }}
                                            to="/app/dashboard"
                                        >
                                            <div className="link_icon">
                                                <FaQrcode />
                                            </div>
                                            <div
                                                className="link_text"
                                                style={{ display: isOpen ? "block" : "none" }}
                                            >
                                                <div className=" ms-2">Dashboard</div>
                                            </div>
                                        </NavLink>
                                        <NavLink
                                            className="link mt-4"
                                            // activeclassName="active"
                                            style={{ textDecoration: "none" }}
                                            to="/app/application"
                                        >
                                            <div className="link_icon">
                                                <TbReceiptTax />
                                            </div>
                                            <div
                                                className="link_text"
                                                style={{ display: isOpen ? "block" : "none" }}
                                            >
                                                <div className=" ms-2">Application</div>
                                            </div>
                                        </NavLink>
                                        <NavLink
                                            className="link mt-4"
                                            // activeclassName="active"
                                            style={{ textDecoration: "none" }}
                                            to="/productlist"
                                        >
                                            <div className="link_icon">
                                                <MdOutlinePolicy />
                                            </div>
                                            <div
                                                className="link_text"
                                                style={{ display: isOpen ? "block" : "none" }}
                                            >
                                                <div className=" ms-2">Products</div>
                                            </div>
                                        </NavLink>
                                        <NavLink
                                            className="link mt-4"
                                            // activeclassName="active"
                                            style={{ textDecoration: "none" }}
                                            to="/app/manufacture"
                                        >
                                            <div className="link_icon">
                                                <FaExchangeAlt />
                                            </div>
                                            <div
                                                className="link_text"
                                                style={{ display: isOpen ? "block" : "none" }}
                                            >
                                                <div className=" ms-2">Manufacturing</div>
                                            </div>
                                        </NavLink>
                                        <NavLink
                                            className="link mt-4"
                                            // activeclassName="active"
                                            style={{ textDecoration: "none" }}
                                            to="/app/technical"
                                        >
                                            <div className="link_icon">
                                                <FaRegFile />
                                            </div>
                                            <div
                                                className="link_text"
                                                style={{ display: isOpen ? "block" : "none" }}
                                            >
                                                <div className=" ms-2">Technical</div>
                                            </div>
                                        </NavLink>
                                        <NavLink
                                            className="link mt-4"
                                            // activeclassName="active"
                                            style={{ textDecoration: "none" }}
                                            to="/app/contact"
                                        >
                                            <div className="link_icon">
                                                <ImUserPlus />
                                            </div>
                                            <div
                                                className="link_text"
                                                style={{ display: isOpen ? "block" : "none" }}
                                            >
                                                <div className=" ms-2">Contact Us</div>
                                            </div>
                                        </NavLink>
                                        <NavLink
                                            className="link mt-4"
                                            // activeclassName="active"
                                            style={{ textDecoration: "none" }}
                                            to="/app/investor"
                                        >
                                            <div className="link_icon">
                                                <IoSettingsOutline />
                                            </div>
                                            <div
                                                className="link_text"
                                                style={{ display: isOpen ? "block" : "none" }}
                                            >
                                                <div className=" ms-2">Investor Relations</div>
                                            </div>
                                        </NavLink>
                                    </div>
                                </Offcanvas.Body>
                            </Offcanvas>
                        </div>
                    </div>
                </aside>}

                {/********Content Area**********/}
                <div className="contentArea nav-res" >
                    {/********Middle Content**********/}

                    <div className={location.pathname === '/bulkimport' ? !isOpen ? "dashboard_container" : "dashboard_container2" :isOpen ? "dashboard_container" : "dashboard_container2"}>
                        <Webnavbar />
                        <Outlet />
                    </div>
                    {/* <Footer></Footer> */}
                </div>
            </div>
        </main>
    );
};

export default Fulllayout;
