import React, { useState, useEffect } from "react";
import { BsFillEyeFill } from "react-icons/bs";
import { Card, Col, Row, Table, Modal, Button } from 'react-bootstrap';
import { Input } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import PlaceholderImage from "../../Assets/images/image_placeholder.jpg"
import axios from 'axios';
import moment from 'moment';
function Dashboard() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    let [loading, setLoading] = useState(false)
    let [orderList, setOrderlist] = useState([])
    const [orderDetail, setOrderDetail] = useState([]);
    const [totalAmountRecived, setTotalAmountRecived] = useState("")

    let navigate = useNavigate()
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    useEffect(() => {
        orderListData();
        // fetchOrderItems()
    }, [])
    
    const orderListData = () => {
        setLoading(true);
        let url = process.env.REACT_APP_BASEURL_TWO + `order/`
        const config = {
            headers: {
                "Authorization": localStorage.getItem("token"),
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
            }
        };
        axios.get(url, config)
            .then(res => {
                const formattedData = res.data.data.map(item => ({
                    ...item,
                    placed_datetime: moment(item.placed_datetime).format('MMM D, YYYY '),
                }));
                console.log("formattedData",formattedData)
                setOrderlist(formattedData)
                setLoading(false)
                // console.log(res.data.data)
            }
            ).catch(err => {
                // console.log(err)
                setLoading(false);
            })
    }
    const fetchOrderItems = (orderId) => {
        setLoading(true);
        const url = `${process.env.REACT_APP_BASEURL_TWO}order-items/${orderId}/`;

        const config = {
            headers: {
                "Authorization": localStorage.getItem("token"),
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
            }
        };

        let totalAmountReci = "" 
        let totalValue = ""
        axios.get(url, config)
            .then(res => {
                // console.log("Hello", res.data.data);
                setOrderDetail(res.data.data);
                totalAmountReci = res.data.data
                totalAmountReci.map((res)=>{
                totalValue += res.prices[0].total_cart_price
                })
                console.log("TOTAL VALUEEEEE",totalValue)
                setTotalAmountRecived(totalValue)
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    };

    return (
        <>
            <div style={{ fontFamily: 'roboto' }}>
                <div className="d-flex justify-content-between col-11 mt-3" style={{ paddingLeft: "30px" }}>
                    <div style={{ fontWeight: 'bold', color: '#0F328E', width: '200px', textAlign: 'left' }}>Dashboard</div>
                    {/* <div style={{ fontWeight: 'bold', display: 'flex', cursor: 'pointer' }} ><Button style={{ background: "#0F328E", boder: '#0F328E' }} onClick={()=>{navigate('/adroitmain')}}>Continue <RxDoubleArrowRight /></Button></div> */}
                </div>
                <Row className='col-11  mt-5 ' style={{ padding: '0px 0px 0px 22px' }} >
                    <Col className="mt-1">
                        <Card className='d-flex flex-row p-2 pt-0' style={{ width: '248px', height: '90px', boxShadow: 'rgb(0 0 0 / 20%) 2px 3px 7px 1px', borderRadius: '10px' }}>
                            <div style={{ textAlign: "left" }} className='d-flex flex-column col-8'>
                                <span style={{ fontWeight: 'bold', fontSize: '14px', marginTop: '15px' }}>Amount Oustanding </span>
                                <span style={{
                                    fontWeight: 'bold', color: "#FF7555", fontSize: '22px'
                                }}>$0.00</span>

                            </div>
                            <div className='col-4 mt-1'>
                                <img src={require('../../Assets/images/Icon_1.png')} alt="icon"></img>
                            </div>
                        </Card>
                    </Col>
                    <Col className="mt-1">
                        <Card className='d-flex flex-row p-2 pt-0' style={{ width: '248px', height: '90px', boxShadow: 'rgb(0 0 0 / 20%) 2px 3px 7px 1px', borderRadius: '10px' }}>
                            <div style={{ textAlign: "left" }} className='d-flex flex-column col-8'>
                                <span style={{ fontWeight: 'bold', fontSize: '14px', marginTop: '15px' }}> Amount Received</span>
                                <span style={{
                                    fontWeight: 'bold', color: "#FF7555", fontSize: '22px'
                                }}>$20.55
                                    {/* ${Math.floor(Math.random() * 100)}.00 */}
                                </span>
                            </div>
                            <div className='col-4 mt-1'>
                                <img src={require('../../Assets/images/icon_2.png')} alt="icon"></img>
                            </div>


                        </Card>
                    </Col>
                    <Col className="mt-1">
                        <Card className='d-flex flex-row p-2 pt-0' style={{ width: '248px', height: '90px', boxShadow: 'rgb(0 0 0 / 20%) 2px 3px 7px 1px', borderRadius: '10px' }}>
                            <div style={{ textAlign: "left" }} className='d-flex flex-column col-8'>
                                <span style={{ fontWeight: 'bold', fontSize: '14px', marginTop: '15px' }}>Orders Shipped </span>
                                <span style={{
                                    fontWeight: 'bold', color: "#FF7555", fontSize: '22px'
                                }}>$0.00</span>
                            </div>
                            <div className='col-4 mt-1'>
                                <img src={require('../../Assets/images/icon_3.png')} alt="icon"></img>
                            </div>


                        </Card>
                    </Col>
                    <Col className="mt-1">
                        <Card className='d-flex flex-row p-2 pt-0' style={{ width: '248px', height: '90px', boxShadow: 'rgb(0 0 0 / 20%) 2px 3px 7px 1px', borderRadius: '10px' }}>
                            <div style={{ textAlign: "left" }} className='d-flex flex-column col-8'>
                                <span style={{ fontWeight: 'bold', fontSize: '14px', marginTop: '15px' }}>Orders Delivered </span>
                                <span style={{
                                    fontWeight: 'bold', color: "#FF7555", fontSize: '22px'
                                }}>$0.00</span>
                            </div>
                            <div className='col-4 mt-1'>
                                <img src={require('../../Assets/images/icon_4.png')} alt="icon"></img>
                            </div>


                        </Card>
                    </Col>
                </Row>
                <div className='mt-5 col-11' style={{ padding: '0px 20px 0px 18px' }} >
                    <div className='text-left fw-bold'>Order Details</div>
                    <Table
                        className="no-wrap mt-3 align-middle border-top"
                        responsive
                        borderless
                        striped
                        >
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>OrderNumber</th>
                                <th>Quantity</th>
                                <th>Order Date</th>
                                {/* <th>Total Price</th> */}
                                <th>Order Status </th>
                                <th>View More</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderList.map((item, index) => (
                                <tr className="border-top" key={item.id} onClick={() => fetchOrderItems(item.id)}>
                                    <td>{index + 1}</td>
                                    <td>{item.order_id}</td>
                                    <td>{item.total_quantity}</td>
                                    <td>{item.placed_datetime}</td>
                                    <td style={{ color: "green" }}>{item.order_status}</td>
                                    <td onClick={toggleModal} ><BsFillEyeFill /></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Modal show={isModalOpen} onHide={toggleModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{ fontFamily: 'roboto' }}>
                        <Modal.Header closeButton className="bg-white text-center" style={{ padding: '9px' }}>
                            <Modal.Title style={{ color: 'Black', fontFamily: 'roboto', fontSize: '18px' }}> View Order Detail</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="table-responsive">
                                <Table className="table" >
                                    <thead style={{ backgroundColor: '#f4f4f4', position: 'sticky', top: '0', fontSize: '14px' }}>
                                        <tr>
                                            <th>image</th>
                                            <th>Part No</th>
                                            <th>Part Type</th>
                                            <th>Description</th>
                                            <th>quantity</th>
                                            <th>vendor name</th>
                                            <th>Price</th>
                                            <th>Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderDetail.map((item, index) => (
                                            <tr className="border-top" key={item.id} style={{ fontSize: '14px' }} >
                                                <td style={{ cursor: 'pointer' }} ><img style={{ height: '40px' }} src={item.image === "" ? PlaceholderImage : item.image} ></img></td>
                                                <td>{item.part_number}</td>
                                                <td>{item.prices[0].part_type}</td>
                                                <td>{item.prices[0].basic_description}</td>
                                                <td>{item.quantity}</td>
                                                <td>{item.prices[0].vendor_name}</td>
                                                <td>{item.prices[0].vendor_price}</td>
                                                <td>{item.prices[0].total_cart_price.toFixed(2)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                             {/* <Modal.Footer style={{ borderTop: '#fff' }}>
                            <Button variant="secondary" onClick={toggleModal} style={{ background: '#4CB5E2', borderColor: '#4CB5E2' }}>
                                Close
                            </Button>
                        </Modal.Footer> */}
                        </Modal.Body>
                       
                    </Modal>
                </div>
            </div>
        </>)
}
export default Dashboard;