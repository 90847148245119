import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Input } from "reactstrap";
import { RiDeleteBin5Line } from 'react-icons/ri';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
import PlaceholderImage from "../../../Assets/images/image_placeholder.jpg";
import { RiDeleteBinLine } from "react-icons/ri";
import { useDispatch } from 'react-redux';
import { updateCartQty } from '../../../Redux/feature/cartSlice';
import Card from 'react-bootstrap/Card';
const ShowAllcart = () => {
    let navigate = useNavigate();
    const [cartList, setCartList] = useState([]);
    const [orderSummary, setOrderSummary] = useState({
        items: 0,
        subtotal: 0,
        discount: 0,
        total_amount: 0,
    });
    const [loading, setLoading] = useState(false);
    const [needsReload, setNeedsReload] = useState(false)
    const [cartLoading, setCartLoading] = useState({});
    const [deleteCartLoading, setDeleteCartLoading] = useState({})
    const [compareProd, setCompareProd] = useState([])
    let dispatch = useDispatch()
    useEffect(() => {
        getCartlist();
        OrderSummmery()
    }, [needsReload]);

    // .......api for get cartlist
    const getCartlist = () => {
        setLoading(true);
        if (localStorage.getItem('token')) {
            let url = process.env.REACT_APP_BASEURL_TWO + 'cart/';
            const config = {
                headers: {
                    Authorization: localStorage.getItem('token'),
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                },
            };
            axios
                .get(url, config)
                .then((res) => {
                    setLoading(false);
                    setCartList(res.data.data.item_list);
                    dispatch(updateCartQty(res.data.data.item_list.length))
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else {
            navigate('/home');
            toast.error('Your session has expired. Please login again.', {
                autoClose: 1000,
                theme: 'colored',
            });
        }
    };
    const OrderSummmery = () => {
        setLoading(true);
        if (localStorage.getItem('token')) {
            let url = process.env.REACT_APP_BASEURL_TWO + 'cart/';
            const config = {
                headers: {
                    Authorization: localStorage.getItem('token'),
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                },
            };
            axios
                .get(url, config)
                .then((res) => {
                    setLoading(false);
                    // setCartList(res.data.data.item_list);
                    // dispatch(updateCartQty(res.data.data.length))
                    const { items, subtotal, discount, total_amount } = res.data.data;
                    setOrderSummary({ items, subtotal, discount, total_amount });

                })

                .catch((err) => {
                    setLoading(false);
                });
        } else {
            navigate('/home');
            toast.error('Your session has expired. Please login again.', {
                autoClose: 1000,
                theme: 'colored',
            });
        }
    };

    const deleteCart = (public_id) => {
        setDeleteCartLoading((prevCartLoading) => ({
            ...prevCartLoading,
            [public_id]: true,
        }));

        if (localStorage.getItem('token')) {
            let url = process.env.REACT_APP_BASEURL_TWO + `cart-remove/${public_id}/`;
            const config = {
                headers: {
                    Authorization: localStorage.getItem('token'),
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                },
            };
            axios
                .delete(url, config)
                .then((res) => {
                    setLoading(false);
                    setNeedsReload(true)
                    getCartlist()

                    // setCartList(res.data.data);
                })
                .catch((err) => {
                    setLoading(false);
                }).finally(() => {
                    // Reset the loading state for the specific item after deletion
                    setDeleteCartLoading((prevCartLoading) => ({
                        ...prevCartLoading,
                        [public_id]: false,
                    }));
                });

        } else {
            navigate('/home');
            toast.error('Your session has expired. Please login again.', {
                autoClose: 1000,
                theme: 'colored',
            });
        }
    };


    const updateQuantity = (public_id, quantity) => {
        setLoading(true);
        setCartLoading((prevCartLoading) => ({
            ...prevCartLoading,
            [public_id]: true, // Set the loading state for the specific item
        }));
        let url = process.env.REACT_APP_BASEURL_TWO + 'cart/';
        const config = {
            headers: {
                Authorization: localStorage.getItem('token'),
                'Access-Control-Allow-Origin': '*',
                'Content-type': 'application/json',
            },
        };
        const body = {
            cart_data: ([
                {
                    product_id: public_id,
                    quantity: quantity,
                },
                // Add more objects if needed
            ]),
        };
        axios
            .post(url, body, config)
            .then((res) => {
                setLoading(false);
                getCartlist();
                OrderSummmery()
            
            })
           
            .catch((err) => {
                setLoading(false);
            })
            .finally(() => {
                setCartLoading((prevCartLoading) => ({
                    ...prevCartLoading,
                    [public_id]: false, // Set the loading state for the specific item
                }));
            });
    };

    const handleDecrease = (public_id, quantity) => {
        const updatedQuantity = Math.max(quantity - 1, 1);
        updateQuantity(public_id, updatedQuantity);
    };

    const handleIncrease = (public_id, quantity) => {
        const updatedQuantity = parseInt(quantity, 10) + 1;
        updateQuantity(public_id, updatedQuantity);

    };
    const checkboxClick = (item, e) => {
        if (e.target.checked === true) {

            setCompareProd((compareProd) => [...compareProd, item])

        }


    }

    const checkUserCompare = () => {

        const compareData = compareProd.map((obj) => ({
            'product_id': obj.products.public_id.toString(),
            'quantity': parseInt(obj.quantity, 10),
        }));

        // setSendPublicId(compareData)
        let url = process.env.REACT_APP_BASEURL_TWO + 'cart/';
        const config = {
            headers: {
                Authorization: localStorage.getItem('token'),
                'Access-Control-Allow-Origin': '*',
                'Content-type': 'application/json',
            },
        };
        const body = {
            cart_data: JSON.stringify(compareData)
        };
        axios
            .post(url, body, config)
            .then((res) => {
                setLoading(false);
                getCartlist();
            })
            .catch((err) => {
                setLoading(false);
            })

    }
    return (
        <div style={{ marginTop: '50px', fontFamily: 'roboto' }}>
            <div className="row justify-content-evenly">
                <div className="col-lg-7 col-md-12 col-sm-12 col-12">
                    <div className=' mb-5'>
                        <div className="card ">
                            <div className="table-responsive">
                                <div style={{ maxHeight: '75vh' }}>
                                    <table className="table">
                                        <thead className='mt-5' style={{ backgroundColor: '#f4f4f4', position: 'sticky', top: '0', }}>
                                            <tr>
                                                <th>Images</th>
                                                <th>Part No</th>
                                                <th>Category Name</th>
                                                <th>Price</th>
                                                <th>Quantity</th>
                                                <th>Subtotal</th>
                                                <th>Remove</th>

                                            </tr>
                                        </thead>
                                        {
                                            loading ?
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="7">
                                                            <div className="d-flex justify-content-center"><div>
                                                                <img src={require('../../../Assets/images/loading-waiting.gif')} alt="loading"></img>
                                                            </div></div>
                                                        </td>
                                                    </tr>
                                                </tbody> :
                                                cartList.length === 0 ? <>
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan="7">
                                                                <div className="d-flex justify-content-center mx-auto mt-5">
                                                                    <div className="text-center fs-4 fw-bold">
                                                                        Cart List Not Found
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </> : <>

                                                    <tbody className='text-center align-items-center justify-content-center mt-3'>
                                                    {/* {JSON.stringify(cartList)} */}
                                                        {cartList.map((item, i) => (
                                                 
                                                            <tr key={i} >

                                                                <td className='py-2 ps-2' style={{ cursor: 'pointer' }} onClick={() => navigate(`/productdetail/${item.products.public_id}`)}><img style={{ height: '60px' }} src={item.products.images === "" ? PlaceholderImage : item.products.images} ></img></td>
                                                                <td className='align-items-center'>{item.products.part_number}</td>
                                                                <td>{item.products.category_name}</td>

                                                                <td>$ {item.products.product_price === "Price not available" ? "0" : item.products.product_price }</td>
                                                                <td>
                                                                    <span
                                                                        onClick={() =>
                                                                            handleDecrease(
                                                                                item.products.public_id,
                                                                                item.quantity
                                                                            )
                                                                        }
                                                                        style={{
                                                                            padding: '7px 15px 8px 15px',
                                                                            border: ' 1px solid #BDBDBD',
                                                                            background: '#EEEEEE',
                                                                            borderTopLeftRadius: '5px',
                                                                            borderBottomLeftRadius: '5px',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        -
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            padding: '7px 30px 8px 30px',
                                                                            border: ' 1px solid #BDBDBD',
                                                                            background: '#EEEEEE',
                                                                        }}
                                                                    >

                                                                        {cartLoading[item.products.public_id] ? (
                                                                            <Spinner animation="border" variant="secondary" size="sm" />
                                                                        ) : (
                                                                            item.quantity
                                                                        )}
                                                                    </span>
                                                                    <span
                                                                        onClick={() =>
                                                                            handleIncrease(
                                                                                item.products.public_id,
                                                                                item.quantity
                                                                            )
                                                                        }

                                                                        style={{
                                                                            padding: '7px 15px 8px 15px',
                                                                            border: ' 1px solid #BDBDBD',
                                                                            background: '#EEEEEE',
                                                                            borderTopRightRadius: '5px',
                                                                            borderBottomRightRadius: '5px',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        +
                                                                    </span>
                                                                </td>
                                                                <td>$ {(item.products.product_price * item.quantity).toFixed(2)}</td>


                                                                <td>
                                                                    {deleteCartLoading[item.public_id] ? (
                                                                        <Spinner animation="border" variant="secondary" size="sm" />
                                                                    ) : (
                                                                        <RiDeleteBinLine
                                                                            style={{ color: '#0f328e', fontSize: '25px', cursor: 'pointer' }}
                                                                            onClick={() => deleteCart(item.public_id)}
                                                                        />
                                                                    )}
                                                                </td>

                                                            </tr>
                                                        ))}

                                                    </tbody>

                                                </>}
                                    </table>
                                </div>


                            </div>
                            <div className="d-flex justify-content-end p-2"style={{ backgroundColor: '#f4f4f4' }}>                                                            
                                <Button style={{ background: '#4CB5E2', borderColor: '#4CB5E2' }}
                                    onClick={() => { navigate('/checkout') }}>
                                    Check Out
                                </Button>
                                <Button
                                    className="ms-1"
                                    style={{ background: '#4CB5E2', borderColor: '#4CB5E2' }}
                                    onClick={() => {
                                        navigate('/shipperlist');
                                    }}
                                >
                                    Shipper List
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-12 col-sm-12 col-12 " >
                    <div className="card w-lg-50 w-md-100 w-sm-100 w-50" style={{ backgroundColor: '#0F328E' }} >
                        <div className="card-body ">
                            <h5 className="card-title text-white">Order Summery</h5>
                            <Row className='d-flex justify-content-between mt-4 text-white'>
                                <Col>Items </Col>
                                <Col>{cartList.length}</Col>
                            </Row>
                            <Row className='d-flex justify-content-between mt-4 text-white'>
                                <Col>Subtotal </Col>
                                <Col>${orderSummary.subtotal.toFixed(2)}</Col>
                            </Row>
                            <Row className='d-flex justify-content-between mt-4 text-white'>
                                <Col>Discount </Col>
                                <Col>${orderSummary.discount.toFixed(2)}</Col>
                            </Row>
                            <Row className='d-flex justify-content-between mt-4 text-info'>
                                <Col>Total </Col>
                                <Col>${orderSummary.total_amount.toFixed(2)}</Col>
                            </Row>
                            <Row>
                                <Col className='mt-3'>
                                    {/* <span style={{ color: 'black', fontWeight: '500', fontSize: '16px' }}>Check Delivery</span> */}
                                    <div className="mt-2 d-flex">
                                        <Input style={{ border: '1px solid #D9D9D9', borderRadius: '4px', borderLeft: "0px", padding: "7px 15px 8px 15px" }} placeholder='Enter Pincode/Zipcode'></Input>
                                        <Button style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', borderRight: '0px', background: '#4CB5E2', borderRadius: '4px', color: 'white', border: 'none', padding: '0px 20px 0px 20px' }}>Check</Button>
                                    </div>
                                </Col>
                            </Row>
                            <div className='d-flex justify-content-center mt-5 '>
                                <Button style={{ background: '#4CB5E2', borderColor: '#4CB5E2' }} onClick={() => { navigate('/checkout') }}>Check Out</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShowAllcart
