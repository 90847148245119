import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import OTPInput from 'otp-input-react';

const OtpSend = () => {
    const [OTP, setOTP] = useState('');
    const [loading1, setLoading1] = useState(false);
    const [verificationResult, setVerificationResult] = useState(null);

    const handleOtpVerification = async (e) => {
        e.preventDefault();

        setLoading1(true);

        try {
            // Replace with your OTP verification API endpoint
            const response = await fetch(`${process.env.REACT_APP_BASEURL}verify-otp/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ otp: OTP }),
            });

            const data = await response.json();
            // Assuming your API responds with some verification result
            setVerificationResult(data);
        } catch (error) {
            console.error('Error verifying OTP:', error);
        }

        setLoading1(false);
    };

    return (
        <div style={{ marginBottom: '120px', marginTop: '100px', fontFamily: 'roboto', alignItems: 'center' }}>
            <div className="d-flex justify-content-center ">
                <Col lg={3}>
                    <div className="card card-body p-5" style={{ boxShadow: '-1px 1px 15px 4px #9289897a' }}>
                        <h5 className="text-center mt- mb-3">Enter OTP</h5>
                        <form className="p-3" onSubmit={handleOtpVerification}>
                            <div className="d-flex justify-content-center">
                                <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} />
                            </div>
                            {loading1 ? (
                                <button className="btn col-6 mt-4" disabled style={{ background: '#4CB5E2', color: 'white' }} type="submit">
                                    Verifying...<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                </button>
                            ) : (
                                <button className="btn col-6 mt-4" style={{ background: '#4CB5E2', color: 'white' }} type="submit">
                                    Verify OTP
                                </button>
                            )}
                        </form>
                        {verificationResult && (
                            <div className="text-center mt-3">
                                {verificationResult.success ? (
                                    <p className="text-success">OTP Verified Successfully!</p>
                                ) : (
                                    <p className="text-danger">Failed to Verify OTP. Please try again.</p>
                                )}
                            </div>
                        )}
                    </div>
                </Col>
            </div>
        </div>
    );
};

export default OtpSend;
