import React, { useState, useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { RiDeleteBin5Line } from 'react-icons/ri';
import axios from "axios";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
const ShipperList = () => {

  let navigate = useNavigate()
  const [cartList, setCartlist] = useState([])
  const [accountInfo, setAccountInfo] = useState({})
  const [consignees, setConsignees] = useState([])
  const [packages, setPackages] = useState([])
  const [products, setProducts] = useState([])
  const [frieghtCharges, setFrieghtCharges] = useState([])
  const [shipmentTypes, setShipmentTypes] = useState([])
  const [services, setServices] = useState([])
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    getCartlist();
  }, [])
  const getCartlist = () => {
    // setLoading(true)
    if (localStorage.getItem('token')) {
      let url = "http://192.168.1.92:8002/shippersList/"
      const config = {
        headers: {
          "Authorization": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json",
        }
      };
      axios.get(url, config)
        .then(res => {
          // setLoading(false)
          setCartlist(res.data.data)
          setAccountInfo(res.data.data.AccountInfo)
          setConsignees(res.data.data.Consignees)
          setPackages(res.data.data.Packages)
          setProducts(res.data.data.Products)
          setFrieghtCharges(res.data.data.FrieghtCharges)
          setShipmentTypes(res.data.data.ShipmentTypes)
          setServices(res.data.data.Services)

        }
        ).catch(err => {
          // setLoading(false)
        })
    }
    else {
      navigate('/home');
      toast.error(' your session has been expired login again.', {
        autoClose: 1000,
        theme: "colored",
      });
    }
  }

  return (
    <div style={{ marginTop: '50px' }}>
      <div className="row   justify-content-center">


        <div className="col-8">
          <div className="card " >
            <div className="table-responsive">
              <table className="table">
                <thead style={{ backgroundColor: '#f4f4f4' }}>
                  <td>ShipperID</td>
                  <td>ShipperName</td>
                  <td>Address1</td>
                  <td>CityName</td>
                  <td>StateAbb</td>
                  <td>ZipCode</td>
                  <td>CountryName </td>
                  <td>PrimaryPhone</td>
                  <td>ClosingTime</td>
                  <td>EmailID</td>
                  <td>OpeningTime</td>
                </thead>
                <tbody>
                  {/* {JSON.stringify(item.products.part_number,"gjgjhg")} */}
                  <tr>
                    <td>{accountInfo.ShipperID}</td>
                    <td>{accountInfo.ShipperName}</td>
                    <td>{accountInfo.Address1}</td>
                    <td>{accountInfo.CityName}</td>
                    <td>{accountInfo.StateAbb}</td>
                    <td>{accountInfo.ZipCode}</td>
                    <td>{accountInfo.CountryName}</td>
                    <td>{accountInfo.PrimaryPhone}</td>
                    <td>{accountInfo.ClosingTime}</td>
                    <td>{accountInfo.EmailID}</td>
                    <td>{accountInfo.OpeningTime}</td>

                    {/* <td>
                      <span style={{ padding: "7px 15px 8px 15px", border: " 1px solid #BDBDBD", background: '#EEEEEE', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }} >-</span>
                      <span style={{ padding: "7px 30px 8px 30px", border: " 1px solid #BDBDBD", background: '#EEEEEE' }}>{item.products.cart_quantity}</span>
                      <span style={{ padding: "7px 15px 8px 15px", border: " 1px solid #BDBDBD", background: '#EEEEEE', borderTopRightRadius: '5px', borderBottomRightRadius: '5px' }}>+</span>
                    </td> */}
                  </tr>
                </tbody>


              </table>
              {/* <div className='d-flex justify-content-end  p-2' style={{ backgroundColor: '#f4f4f4' }}>
                <Button style={{ background: '#4CB5E2', borderColor: '#4CB5E2', }}>Check Out</Button>
                <Button className='ms-1' style={{ background: '#4CB5E2', borderColor: '#4CB5E2' }} onClick={() => { navigate('/shipperlist') }}>Shipper List</Button>
              </div> */}

            </div>
          </div>
        </div>


      </div>
      <div className="row justify-content-center mt-3">


        <div className="col-lg-8">
          <div className="card " >
            <div className="table-responsive">
              <table className="table">
                <thead style={{ backgroundColor: '#f4f4f4' }}>
                  <td>Name</td>
                  <td>State</td>
                  <td>Country</td>
                  <td>Email</td>
                  <td>Telephone</td>
                  <td>PostalCode</td>
                  <td>City</td>
                </thead>
                {consignees.length === 0 ? <div className="d-flex justify-content-center mx-auto mt-5"><div className="text-center fs-4 fw-bold">Cart List Not Found</div></div> :
                  consignees.map((item) => {

                    return (<>
                      <tbody>
                        {/* {JSON.stringify(item.products.part_number,"gjgjhg")} */}
                        <tr>
                          <td>{item.Name}</td>
                          <td>{item.State}</td>
                          <td>{item.Country}</td>
                          <td>{item.Email}</td>
                          <td>{item.Telephone}</td>
                          <td>{item.PostalCode}</td>
                          <td>{item.City}</td>

                        </tr>
                      </tbody>
                    </>)
                  }
                  )}

              </table>
              {/* <div className='d-flex justify-content-end  p-2' style={{ backgroundColor: '#f4f4f4' }}>
                <Button style={{ background: '#4CB5E2', borderColor: '#4CB5E2', }}>Check Out</Button>
                <Button className='ms-1' style={{ background: '#4CB5E2', borderColor: '#4CB5E2' }} onClick={() => { navigate('/shipperlist') }}>Shipper List</Button>
              </div> */}

            </div>
          </div>
        </div>


      </div>
      <div className="row justify-content-center mt-3">


        <div className="col-lg-8">
          <div className="card " >
            <div className="table-responsive">
              <table className="table">
                <thead style={{ backgroundColor: '#f4f4f4' }}>
                  <td>PackageId</td>
                  <td>PackageName</td>

                </thead>
                {packages.length === 0 ? <div className="d-flex justify-content-center mx-auto mt-5"><div className="text-center fs-4 fw-bold">Cart List Not Found</div></div> :
                  packages.map((item) => {

                    return (<>
                      <tbody>
                        {/* {JSON.stringify(item.products.part_number,"gjgjhg")} */}
                        <tr>
                          <td>{item.PackageId}</td>
                          <td>{item.PackageName}</td>
                        </tr>
                      </tbody>
                    </>)
                  }
                  )}

              </table>
              {/* <div className='d-flex justify-content-end  p-2' style={{ backgroundColor: '#f4f4f4' }}>
                <Button style={{ background: '#4CB5E2', borderColor: '#4CB5E2', }}>Check Out</Button>
                <Button className='ms-1' style={{ background: '#4CB5E2', borderColor: '#4CB5E2' }} onClick={() => { navigate('/shipperlist') }}>Shipper List</Button>
              </div> */}

            </div>
          </div>
        </div>


      </div>
      <div className="row justify-content-center mt-3">

        <div className="row justify-content-center mt-3">
        <div className="col-lg-8">
          <div className="card " >
            <div className="table-responsive">
              <table className="table">
                <thead style={{ backgroundColor: '#f4f4f4' }}>
                  <td>ProductId</td>
                  <td>ProductName</td>

                </thead>
                {products.length === 0 ? <div className="d-flex justify-content-center mx-auto mt-5"><div className="text-center fs-4 fw-bold">Cart List Not Found</div></div> :
                  products.map((item) => {

                    return (<>
                      <tbody>
                        {/* {JSON.stringify(item.products.part_number,"gjgjhg")} */}
                        <tr>
                          <td>{item.ProductId}</td>
                          <td>{item.ProductName}</td>
                        </tr>
                      </tbody>
                    </>)
                  }
                  )}

              </table>
              {/* <div className='d-flex justify-content-end  p-2' style={{ backgroundColor: '#f4f4f4' }}>
                <Button style={{ background: '#4CB5E2', borderColor: '#4CB5E2', }}>Check Out</Button>
                <Button className='ms-1' style={{ background: '#4CB5E2', borderColor: '#4CB5E2' }} onClick={() => { navigate('/shipperlist') }}>Shipper List</Button>
              </div> */}

            </div>
          </div>
        </div>
        </div>
        <div className="row justify-content-center mt-3">
        <div className="col-lg-8">
          <div className="card " >
            <div className="table-responsive">
              <table className="table">
                <thead style={{ backgroundColor: '#f4f4f4' }}>
                  <td>TermId</td>
                  <td>TermName</td>

                </thead>
                {frieghtCharges.length === 0 ? <div className="d-flex justify-content-center mx-auto mt-5"><div className="text-center fs-4 fw-bold">Cart List Not Found</div></div> :
                  frieghtCharges.map((item) => {

                    return (<>
                      <tbody>
                        {/* {JSON.stringify(item.products.part_number,"gjgjhg")} */}
                        <tr>
                          <td>{item.TermId}</td>
                          <td>{item.TermName}</td>
                        </tr>
                      </tbody>
                    </>)
                  }
                  )}

              </table>
              {/* <div className='d-flex justify-content-end  p-2' style={{ backgroundColor: '#f4f4f4' }}>
                <Button style={{ background: '#4CB5E2', borderColor: '#4CB5E2', }}>Check Out</Button>
                <Button className='ms-1' style={{ background: '#4CB5E2', borderColor: '#4CB5E2' }} onClick={() => { navigate('/shipperlist') }}>Shipper List</Button>
              </div> */}

            </div>
          </div>
        </div>
</div>
        <div className="row justify-content-center mt-3">
        <div className="col-lg-8">
          <div className="card " >
            <div className="table-responsive">
              <table className="table">
                <thead style={{ backgroundColor: '#f4f4f4' }}>
                  <td>Id</td>
                  <td>Name</td>

                </thead>
                {shipmentTypes.length === 0 ? <div className="d-flex justify-content-center mx-auto mt-5"><div className="text-center fs-4 fw-bold">Cart List Not Found</div></div> :
                  shipmentTypes.map((item) => {

                    return (<>
                      <tbody>
                        {/* {JSON.stringify(item.products.part_number,"gjgjhg")} */}
                        <tr>
                          <td>{item.Id}</td>
                          <td>{item.Name}</td>
                        </tr>
                      </tbody>
                    </>)
                  }
                  )}

              </table>
              {/* <div className='d-flex justify-content-end  p-2' style={{ backgroundColor: '#f4f4f4' }}>
                <Button style={{ background: '#4CB5E2', borderColor: '#4CB5E2', }}>Check Out</Button>
                <Button className='ms-1' style={{ background: '#4CB5E2', borderColor: '#4CB5E2' }} onClick={() => { navigate('/shipperlist') }}>Shipper List</Button>
              </div> */}

            </div>
          </div>
        </div>
        </div>
        <div className="row justify-content-center mt-3">
        <div className="col-lg-8">
          <div className="card " >
            <div className="table-responsive">
              <table className="table">
                <thead style={{ backgroundColor: '#f4f4f4' }}>
                  <td>ServiceId</td>
                  <td>ServiceName</td>
                  <td>ServiceTypeID</td>
                  <td>ServiceType</td>

                </thead>
                {services.length === 0 ? <div className="d-flex justify-content-center mx-auto mt-5"><div className="text-center fs-4 fw-bold">Cart List Not Found</div></div> :
                  services.map((item) => {

                    return (<>
                      <tbody>
                        {/* {JSON.stringify(item.products.part_number,"gjgjhg")} */}
                        <tr>
                          <td>{item.ServiceId}</td>
                          <td>{item.ServiceName}</td>
                          <td>{item.ServiceTypeID}</td>
                          <td>{item.ServiceType}</td>
                         
                        </tr>
                      </tbody>
                    </>)
                  }
                  )}

              </table>
              {/* <div className='d-flex justify-content-end  p-2' style={{ backgroundColor: '#f4f4f4' }}>
                <Button style={{ background: '#4CB5E2', borderColor: '#4CB5E2', }}>Check Out</Button>
                <Button className='ms-1' style={{ background: '#4CB5E2', borderColor: '#4CB5E2' }} onClick={() => { navigate('/shipperlist') }}>Shipper List</Button>
              </div> */}

            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default ShipperList
