import { Navigate } from "react-router-dom";
import Profile from "../../../Components/Profile/Profile";
import About from "../../../Content/About";
import Certifications from "../../../Content/Certification/Certifications";
import Content from "../../../Content/Content";
import Login from "../../../Content/Login";
import Register from "../../../Content/Register";
import AdroitMainPage from "../AdroitMainPage";
import AllCategory from "../AllCategory";
import CategoryProduct from "../CategoryProduct";
import AllProductList from "../AllProductList";
import BulkImport from "../Bulkimport/BulkImport";
import Dashboard from "../Dashboard";
import DashboardProduct from "../DashboardProduct";
import Fulllayout from "../Layout/Fulllayout";
import FulllayoutLogin from "../Layout/FullLayoutLogin";
import FullLayoutSales from "../Layout/FullLayoutSales";
import FulllayoutWeb from "../Layout/FullLayoutWeb";
import ProductDetail from "../ProductDetail";
import DashboardSales from "../Sales/DashboardSales";
import HomeSKeleton from "../skeleton/HomeSkeleton";
import Contact from "../../../Components/contact/Contact";
import NotFound from "../404/NotFound";
import Fulllayout404 from "../Layout/FullLayout404";
import Manufacturing from "../../../Content/Manufacture/Manufacturing";
import Technical from "../../../Content/Technical/Technical";
import Manufacture from "../Manufacture";
import Application from "../Application";
import ComingSoon from "../Comingsoon";
import OurProduct from "../../../Content/OurProduct/OurProduct";
import Engineering from "../../../Content/Engineering/Engineering";
import Quality from "../../../Content/Quality/Quality";
import ShowAllcart from "../Showaddcart/ShowAllcart";
import ShipperList from "../ShipmentInfo/ShipperList";
import Products from "../../../Content/OurProduct/Products";
import WebProductDetails from "../../../Content/OurProduct/webProductDetails";
import Fundamentals from "../../../Content/Fundamentals";
import Leadership from "../../../Content/Leadership";
import SocialResponsibility from "../../../Content/SocialResponsibility";
import Adroithistory from "../../../Content/Adroithistory";
import MileStone from "../../../Content/MileStone";
import Career from "../../../Content/Career/Career";
import Events from "../../../Content/Events/Events";
import CheckoutPage from "../CheckoutPage/CheckoutPage";
import BulkExcel from "../Bulkimport/BulkExcel";
import ForgetPassword from "../../../ForgetPassword/ForgetPassword";
import OtpSend from "../../../ForgetPassword/OtpSend";

const Themeroutes = [
    {
        path: "/",
        element: <FulllayoutWeb />,
        children: [
            // { path: "/", element: <Navigate to='/home' /> },
            { path: "/", element: <Content />},
            { path: "/home", exact: true, element: <Content /> },
            { path: "/certifications", exact: true, element: <Certifications /> },
            { path: "/milestone", exact: true, element: <MileStone /> },
            { path: "/manufacture", exact: true, element: <Manufacturing/> },
            { path: "/technical", exact: true, element: <Technical/> },
            { path: "/career", exact: true, element: <Career/> },
            { path: "/aboutus", exact: true, element: <About /> },
            { path: "/fundamentals", exact: true, element: <Fundamentals/> },
            { path: "/leadership", exact: true, element: <Leadership/> },
            { path: "/socialresponsiblity", exact: true, element: <SocialResponsibility/>},
            { path: "/adroithistory", exact: true, element: <Adroithistory/>},
            { path: "/ourproduct", exact: true, element: <OurProduct/> },
            { path: "/products", exact: true, element: <Products /> },
            { path: "/contactus", exact: true, element: <Contact /> },
            { path: "/engineering", exact: true, element: <Engineering /> },
            { path: "/quality", exact: true, element: <Quality /> },
            { path: "/events", exact: true, element: <Events /> },
            { path: "/webdetail/:id", exact: true, element: <WebProductDetails /> },
            { path: "/webdetail", exact: true, element: <WebProductDetails /> },
            { path: "/notfound", exact: true, element: <NotFound /> },
            { path:'*', exact: true, element:<Navigate to={'/notfound'} />} 
        ]
    },
    {
        path: "/notfound",
        element: <Fulllayout404 />,
        children: [
            { path: "/notfound", exact: true, element: <NotFound /> },
            { path: '*', exact: true, element: <Navigate to={'/notfound'} /> } 
        ]
    },
    {
        path: "/",
        element: <FulllayoutLogin />,
        children: [
            { path: "/", element: <Navigate to='/login' /> },
            { path: "login", exact: true, element: <Login /> },
            { path: "/register", exact: true, element: <Register /> },
            { path: "/Forgetpassword", exact: true, element: <ForgetPassword/> },
            { path: "/otp", exact: true, element: <OtpSend/> }


        ]
    },
    {
        path: "/",
        element: <Fulllayout />,
        children: [
            { path: "/", element: <Navigate to='/app/home' /> },
            { path: "/app/home", exact: true, element: <AdroitMainPage /> },
            { path: "/app/home/:id", exact: true, element: <AdroitMainPage /> },
            { path: "/app/dashboard", exact: true, element: <Dashboard /> },
            { path: "/all", exact: true, element: <CategoryProduct /> },
            { path: "/all/:id", exact: true, element: <CategoryProduct /> },
            { path: "/category", exact: true, element: <AllCategory /> },
            // { path: "/all", exact: true, element: <AllProduct /> },
            // { path: "/all/:id", exact: true, element: <AllProduct /> },
            // { path: "/category", exact: true, element: <AllCategory /> },
            { path: "/productdetail", exact: true, element: <ProductDetail /> },
            { path: "/productdetail/:id", exact: true, element: <ProductDetail /> },
            // { path: "/allproduct", exact: true, element: <DashboardProduct /> },
            { path: "/productlist", exact: true, element: <AllProductList /> },
            { path: "/app/manufacture", exact:true, element:<Manufacture/>},
            { path: "/app/application", exact:true, element:<Application/>},
            { path: "/app/contact", exact:true, element:<Application/>},
            { path: "/app/investor", exact:true, element:<Application/>},
            { path: "/app/technical", exact:true, element:<Application/>},
            { path: "/profile", exact: true, element: <Profile /> },
            { path: "/bulkimport", exact: true, element: <BulkImport /> },
            { path: "/showallcart", exact: true, element: <ShowAllcart/> },
            { path: "/shipperlist", exact: true, element: <ShipperList /> },
            { path: "/checkout", exact: true, element: <CheckoutPage/> },
            { path: "/skeleton", exact: true, element: <HomeSKeleton /> },
            { path: "/bulkexcel", exact: true, element: <BulkExcel/> },

        ]
    },
    {
        path: "/",
        element: <FullLayoutSales />,
        children: [
            { path: "/", element: <Navigate to='/sales' /> },
            { path: "adroitmainsales", exact: true, element: <AdroitMainPage /> },
            { path: "sales", exact: true, element: <DashboardSales /> },
            { path: "/profilesales", exact: true, element: <Profile /> },
            { path: "/sales/application", exact:true, element:<ComingSoon/>},            
            { path: "/sales/technical", exact:true, element:<ComingSoon/>},
            { path: "/sales/contact", exact:true, element:<ComingSoon/>},
            { path: "/sales/investor", exact:true, element:<ComingSoon/>},
            { path: "/sales/manufacture", exact:true, element:<ComingSoon/>},
            { path: "/sales/product", exact:true, element:<ComingSoon/>},


        ]
    }
]
export default Themeroutes;