import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
import { handleError } from "../util/HandleErrors";
import { useNavigate } from "react-router-dom";
const ForgetPassword = () => {
    const navigate = useNavigate()
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [email, setEmail] = useState('');
    const [apiResponse, setApiResponse] = useState(null);

    const handleSendOtp = async (e) => {
        e.preventDefault();
        setLoading1(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASEURL}forgot-password/`, { email_or_num: email });
            // Assuming your API responds with some useful information
            setApiResponse(response.data);
            navigate('/otp')
            toast.success("Send OTP Successful!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
                theme: "colored",
            })
            
        } catch (error) {
            console.error('Error sending OTP:', error);
            if (error !== {}) {
                const getErrRes = handleError(error);
                toast.error(getErrRes ? getErrRes : "Something went wrong.", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                    theme: "colored",
                });
            }
        }
        setLoading1(false);
    };

    return (
        <>
        <ToastContainer />
        <div style={{ marginBottom: '120px', marginTop: '100px', fontFamily: 'roboto', alignItems: 'center' }}>
            <div className="d-flex justify-content-center ">
                <Col lg={4}>
                    <div className="card card-body p-5 " style={{ boxShadow: '-1px 1px 15px 4px #9289897a' }}>
                        <form className="p-3" onSubmit={handleSendOtp}>
                            <h5 className="text-center mt-2 mb-3">Forgot Password</h5>
                            <p className="text-secondary">
                                Please enter your registered email or phone number.
                                <br /> OTP will be sent to your entered email or phone number.
                            </p>
                            <div className="form-group d-flex flex-column justify-content-start " style={{ marginTop: '20px' }}>
                                <input
                                    type="email"
                                    required
                                    name="email_or_num"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="form-control col-12 p-2"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="Enter Your Email ID "
                                    style={{ height: '50px' }}
                                />
                            </div>

                            {loading1 ? (
                                <button className="btn col-12" disabled style={{ background: '#4CB5E2', color: 'white' }} type="submit">
                                    Send Otp...
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                </button>
                            ) : (
                                <button className="btn col-12" style={{ background: '#4CB5E2', color: 'white' }} type="submit">
                                    Send Otp
                                </button>
                            )}
                        </form>
                    </div>
                </Col>
            </div>
        </div>
        </>
    );
};

export default ForgetPassword;
