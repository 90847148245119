import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { handleError } from "../util/HandleErrors";
import { useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";

const Register = () => {
  const navigate = useNavigate()
  const {
    register: registerForm,
    handleSubmit: handleregisterForm,
    formState: { errors: registerErrors },
    getValues,
  } = useForm();

  const [loading, setLoading] = useState(false);

  const onSubmitRegisterForm = (data) => {
    setLoading(true);
    let body = {
      name: data.name,
      password: data.password,
      email: data.email,
      mobile_no: data.mobile_number,
    };

    let url = process.env.REACT_APP_BASEURL + "registration/";
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
      },
    };
    axios
      .post(url, body, config)
      .then((res) => {
        setLoading(false);
        if (res.data.data.access_token) {
          localStorage.setItem(
            process.env.REACT_APP_LOCALSTORAGE_KEY,
            res.data.data.access_token
          );
          navigate('/')
          toast.success("Registration successful!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error != {}) {
          const getErrRes = handleError(error);
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
            theme: "colored",
          });
        }
      });
  };

  return (
    <div style={{ marginBottom: '30px' }}>

      <h2 style={{ marginTop: "80px" }}>Create Your Account</h2>
      <div className="d-flex justify-content-center">
        <Col lg={6} >
          <div className="card card-body p-5 mt-3" style={{ boxShadow: 'rgb(146 137 137 / 33%) 0px 2px 4px 3px' }}>
            <form onSubmit={handleregisterForm(onSubmitRegisterForm)}>
              <div className="form-group d-flex flex-column  justify-content-start ">

                <input
                  type="text"
                  className="form-control col-12 p-3"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter name"
                  {...registerForm("name", { required: true })}
                />
                {registerErrors?.name?.type === "required" && (
                  <span className="text-danger col-12" style={{ textAlign: 'left' }}> This field is required</span>
                )}
              </div>
              <div className="form-group d-flex flex-column  justify-content-start " style={{ marginTop: '-10px' }}>
                <input
                  type="email"
                  className="form-control col-12 p-3"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  {...registerForm("email", {
                    required: true,
                    pattern:
                      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  })}
                />
                {registerErrors?.email?.type === "required" && (
                  <span className="text-danger col-12" style={{ textAlign: 'left' }}> This field is required</span>
                )}
                {registerErrors?.email?.type === "pattern" && (
                  <span className="text-danger col-12" style={{ textAlign: 'left' }}> Invalid email address</span>
                )}

              </div>
              <div className="form-group d-flex flex-column  justify-content-start" style={{ marginTop: '-10px' }}>
                <input
                  type="number"
                  className="form-control col-12 p-3"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter Mobile number"
                  {...registerForm("mobile_number", {
                    required: true,
                    pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                  })}
                />
                {registerErrors?.mobile_number?.type === "required" && (
                  <span className="text-danger col-12" style={{ textAlign: 'left' }}> This field is required</span>
                )}
                {registerErrors?.mobile_number?.type === "pattern" && (
                  <span className="text-danger col-12" style={{ textAlign: 'left' }}> Mobile number not valid</span>
                )}
              </div>
              <div className="form-group d-flex flex-column  justify-content-start" style={{ marginTop: '-10px' }}>
                <input
                  type="password"
                  className="form-control col-12 p-3"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  {...registerForm("password", { required: true })}


                />
                {registerErrors?.password?.type === "required" && (
                  <span className="text-danger col-12" style={{ textAlign: 'left' }}> This field is required</span>
                )}
              </div>
              <div className="form-group d-flex flex-column  justify-content-start " style={{ marginTop: '-10px' }}>
                <input
                  type="password"
                  className="form-control col-12 p-3"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="confirm password"
                  {...registerForm("confirm_password", {
                    required: true,
                    validate: (value) => {
                      const { password } = getValues();
                      return password === value || "Passwords should match!";
                    },
                  })}
                />
                {registerErrors?.confirm_password?.type === "validate" && (
                  <p className="text-danger col-12" style={{ textAlign: 'left' }}> Passwords should match</p>
                )}
              </div>

              {loading ? (
                <button type="button" disabled className="btn btn-primary col-12">
                  Submit...
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                </button>
              ) : (
                <button type="submit" className="btn btn-primary col-12">
                  Submit
                </button>
              )}
            </form>
          </div>
        </Col>
      </div>
    </div>
  );
};

export default Register;
