import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown';
import { AiOutlineSearch } from "react-icons/ai";
import { Input } from "reactstrap";
import axios from "axios";
import { handleError } from '../../util/HandleErrors';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import PlaceholderImage from "../../Assets/images/image_placeholder.jpg"
import { BiRupee } from 'react-icons/bi';
import { MdOutlineArrowForwardIos } from "react-icons/md";
import AllCategory from "./AllCategory";
import Brands from "./Brands";
import Categories from "./Categories";
import DashboardProduct from "./DashboardProduct";
import Products from "./ProductsDemo";
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from "react-router";
import WebProducts from "../../Content/OurProduct/WebProducts";
import WebProductSKeleton from "./skeleton/WebProductSkeleton";
import AllProducts from "./AllProducts";
import AdroitmainSkelerton from "../adroitmainskeloten";
// import Webnavbar from "./Webnavbar/Webnavbar";
function AdroitMainPage() {
    const [category, setCategory] = useState()
    const [publicId, setPublicId] = useState('')
    const [categoryName, setCategoryName] = useState('')
    const [showAllProduct, setShowAllProdcut] = useState(true)
    const [series, setSeries] = useState('')
    const [seriesName, setSeriesName] = useState('')
    const [categoryList, setCategoryList] = useState([])
    const [seriesList, setSeriesList] = useState([])
    const [searchValue, setSearchValue] = useState('');
    let [universalJoint, setUniversalJoint] = useState('')
    const [productList, setProductList] = useState([])
    const [universalList, setUniversalList] = useState([])
    const [loading, setLoading] = useState(false)
    let navigate = useNavigate()
    useEffect(() => {
        categoryListData();
        seriesListData();
        UniversalListData();
    }, [])
    const productListData = (public_id) => {
        setLoading(true);
        let url = process.env.REACT_APP_BASEURL_TWO + `product_list/?search=${searchValue}&category_id=${public_id}&series=${seriesName}&universal=${universalJoint}`
        const config = {
            headers: {
                "Authorization": localStorage.getItem("token"),
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
            }
        };
        axios.get(url, config)
            .then(res => {
                setProductList(res.data.data)

                setLoading(false)
            }
            ).catch(err => {

                setLoading(false);
            })
    }
    const productListDataSearch = () => {

        setLoading(true);
        setSeriesName('All Series');
        // let url = process.env.REACT_APP_BASEURL_TWO + `product_list/?search=${searchValue}&category_id=${publicId}`
        let url = process.env.REACT_APP_BASEURL_TWO + `product_list/?search=${searchValue}&category_id=${publicId}&series=${seriesName}&universal=${universalJoint}`
        const config = {
            headers: {
                "Authorization": localStorage.getItem("token"),
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
            }
        };
        axios.get(url, config)
            .then(res => {
                setProductList(res.data.data)

                setLoading(false)
            }
            ).catch(err => {

                setLoading(false);
            })
    }
    const productListDataSeries = (name) => {

        setLoading(true);
        // let url = process.env.REACT_APP_BASEURL_TWO + `product_list/?search=${name}&category_id=`
        let url = process.env.REACT_APP_BASEURL_TWO + `product_list/?search=${searchValue}&category_id=${publicId}&series=${name}&universal=${universalJoint}`
        const config = {
            headers: {
                "Authorization": localStorage.getItem("token"),
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
            }
        };
        axios.get(url, config)
            .then(res => {
                setProductList(res.data.data)

                setLoading(false)
            }
            ).catch(err => {

                setLoading(false);
            })
    }
    const categoryListData = () => {
        setLoading(true)

        let url = process.env.REACT_APP_BASEURL_TWO + "category/"
        const config = {
            headers: {
                "Authorization": localStorage.getItem("token"),
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
            }
        };
        axios.get(url, config)
            .then(res => {

                setCategoryList(res.data.data)
                setLoading(false)
            }
            ).catch(err => {
                setLoading(false)
                const getErrRes = handleError(err)
            })

    }
    const UniversalListData = () => {
        setLoading(true)

        let url = process.env.REACT_APP_BASEURL_TWO + "universal-joint-list/"
        const config = {
            headers: {
                // "Authorization": localStorage.getItem("token"),
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
            }
        };
        axios.get(url, config)
            .then(res => {

                setUniversalList(res.data)
                setLoading(false)
            }
            ).catch(err => {
                setLoading(false)
                const getErrRes = handleError(err)

            })

    }
    const searchUniversalJoint = (de) => {
        setLoading(true);
        // let url = process.env.REACT_APP_BASEURL_TWO + `product_list/?search=${searchValue}&category_id=${publicId}`
        let url = process.env.REACT_APP_BASEURL_TWO + `product_list/?search=${searchValue}&category_id=${publicId}&series=${seriesName}&universal=${de}`
        const config = {
            headers: {
                "Authorization": localStorage.getItem("token"),
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
            }
        };
        axios.get(url, config)
            .then(res => {
                setProductList(res.data.data)
                setLoading(false)
            }
            ).catch(err => {
                setLoading(false);
            })
    }
    const seriesListData = () => {
        setLoading(true)

        let url = process.env.REACT_APP_BASEURL_TWO + "series/"
        const config = {
            headers: {
                "Authorization": localStorage.getItem("token"),
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
            }
        };
        axios.get(url, config)
            .then(res => {

                setSeriesList(res.data)
                setLoading(false)
            }
            ).catch(err => {
                setLoading(false)
                const getErrRes = handleError(err)

            })

    }
    return (
        <>
            {/* <Webnavbar/> */}
            <div className="" style={{ marginTop: '60px', fontFamily: 'roboto' }}>
                <Container fluid >
                    {/* <div style={{ width: "75vw", paddingLeft: '50px' }} className="d-flex justify-content-center" >
                    <Carousel variant="primary">
                        <Carousel.Item >
                            <img className="d-block w-80" src={require('../../Assets/images/slider_dashboard.png')} alt="img" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-80" src={require('../../Assets/images/slider_dashboard.png')} alt="img" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-80" src={require('../../Assets/images/slider_dashboard.png')} alt="img" />
                        </Carousel.Item>
                    </Carousel>
                </div> */}
                    <div className='d-flex justify-content-center'>
                        <div className="row col-12 d-flex justify-content-start mt-2" >
                            <div className="col-sm-12 col-md-9 mt-2 ">
                                <div className="input-group input-group d-flex justify-content-center" >
                                    <Row style={{ width: "80%" }}>
                                        <Col className="mt-3" lg={1} >Search</Col>
                                        <Col lg={11} className="mt-2">
                                            <form className="d-flex col-12 " onSubmit={(e) => { e.preventDefault(); productListDataSearch(); setShowAllProdcut(false); setSeriesName('') }}>
                                                <Input name="search" required className='col-12 border border-secondary' value={searchValue} onChange={(e) => { setSearchValue(e.target.value) }} type="text" placeholder="Search by Part No,Product Type" style={{ backgroundColor: '#F4F4F4' }} />
                                                <button className='btn border border-secondary ' style={{ background: '#fff', border: "#0F328E", marginLeft: '-30px' }} > <AiOutlineSearch /></button>
                                            </form>
                                        </Col>
                                    </Row>
                                    <Row className="col-12 d-flex justify-content-center mt-4">
                                        {/* <Col className="mt-3">  <span className="">Filters</span></Col>*/}
                                        <Col className="mt-3" lg={1} >Filters</Col>
                                        <Col lg={3} className='mt-2'>
                                            <Dropdown >
                                                <Dropdown.Toggle id="dropdown-basic" className=" border border-secondary" style={{ paddingBottom: '9px', background: '#fff', color: 'black', minWidth: '210px' }}>
                                                    {categoryName === '' ? "All Categories" : categoryName}
                                                </Dropdown.Toggle>
                                                {categoryList.length === 0 ? <Dropdown.Menu><Dropdown.Item disabled>No Data Available</Dropdown.Item></Dropdown.Menu> : <Dropdown.Menu style={{ height: '30vh', overflowX: "hidden" }}>
                                                    <Dropdown.Item onClick={() => { setShowAllProdcut(true); setCategoryName(''); setPublicId(''); setCategory('') }}>All Categories</Dropdown.Item>
                                                    {
                                                        categoryList.map((item) => {
                                                            return (
                                                                <Dropdown.Item onClick={() => { setPublicId(item.public_id); setCategory(item.name); setCategoryName(item.name); productListData(item.public_id); setShowAllProdcut(false) }} >{item.name}</Dropdown.Item>
                                                            )
                                                        })
                                                    }

                                                </Dropdown.Menu>}
                                            </Dropdown>
                                        </Col>
                                        <Col lg={3} className='mt-2'>
                                            <Dropdown >
                                                <Dropdown.Toggle id="dropdown-basic" className=" border border-secondary text-black" style={{ paddingBottom: '9px', background: '#fff', width: '210px' }}>
                                                    {seriesName === '' ? "All Series" : seriesName}
                                                </Dropdown.Toggle>
                                                {seriesList.length === 0 ? <Dropdown.Menu><Dropdown.Item disabled>No Data Available</Dropdown.Item></Dropdown.Menu> : <Dropdown.Menu style={{ height: '30vh', overflowX: "hidden" }}>
                                                    <Dropdown.Item onClick={() => { setShowAllProdcut(true); setSeriesName(''); }}>All Series</Dropdown.Item>
                                                    {
                                                        seriesList.map((item) => {
                                                            return (
                                                                <Dropdown.Item onClick={() => { productListDataSeries(item.series); setSeries(item.series); setSeriesName(item.series); setShowAllProdcut(false) }} >{item.series}</Dropdown.Item>
                                                            )
                                                        })
                                                    }


                                                </Dropdown.Menu>}
                                            </Dropdown>
                                        </Col>
                                        <Col lg={3} className='mt-2'>
                                            <Dropdown >
                                                <Dropdown.Toggle id="dropdown-basic" className=" border border-secondary text-black" style={{ paddingBottom: '9px', background: '#fff', width: '210px' }}>
                                                    {universalJoint === '' ? "Universal Joint" : universalJoint}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu style={{ height: '30vh', overflowX: "hidden" }}>
                                                <Dropdown.Item onClick={() => { setShowAllProdcut(true); setUniversalJoint(''); }}>Universal Joint</Dropdown.Item>
                                                {
                                                    universalList.map((item) => {
                                                        return (
                                                            <Dropdown.Item onClick={() => { setUniversalJoint(item.universal_joint_size); searchUniversalJoint(item.universal_joint_size);setShowAllProdcut(false)}} >{item.universal_joint_size}</Dropdown.Item>
                                                        )
                                                    })
                                                }

                                                </Dropdown.Menu>
                                            </Dropdown>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <AllCategory /> */}
                    <Row className="mt-5">
                        <Col className='col-lg-11 d-flex flex-direction-row flex-wrap justify-content-center ' >
                            {showAllProduct ? <AllProducts /> : <div className="mt-2">
                                <div className="d-flex justify-content-start" style={{}}>
                                    <div style={{ fontWeight: 'bold', color: '#0F328E', borderBottom: "2px solid #0F328E", width: '200px', }}>All Products </div>
                                </div>
                                {loading ? <AdroitmainSkelerton /> : <div className='row  col-12' >
                                    <div class=" flex-wrap d-flex justify-content-start mt-4 mb-4" >
                                        {productList.length === 0 ? <div className="d-flex justify-content-start mx-auto mt-5"><div className="text-center fs-4 fw-bold">Coming Soon, Check back later.</div></div> :
                                            productList.map((prdouct) => {
                                                return (<>
                                                    <Card onClick={() => navigate(`/productdetail/${prdouct.public_id}`)} style={{ width: '230px', height: 'auto', borderColor: '#A9ADB0', borderRadius: '16px', boxShadow: "0px 97px 39px rgba(0, 0, 0, 0.01), 0px 54px 33px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.04), 0px 6px 13px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)", margin: '10px', cursor: 'pointer' }} >
                                                        <div style={{ background: '#F5F5F5', borderRadius: '16px' }}>
                                                            <Card.Img variant="top" onClick={() => navigate(`/webdetail/${prdouct.public_id}`)} src={prdouct.images === "" ? PlaceholderImage : prdouct.images} style={{ background: '#F5F5F5', height: 'auto', width: 'auto', borderRadius: '16px', }} />
                                                        </div>
                                                        <Card.Body>
                                                            <Card.Title onClick={() => navigate(`/productdetail/${prdouct.public_id}`)} style={{ color: '#0F328E', textAlign: 'left', marginTop: '10PX', fontSize: '16px' }}>{prdouct.part_number}</Card.Title>
                                                            <Card.Text onClick={() => navigate(`/productdetail/${prdouct.public_id}`)} style={{ color: 'grey', textAlign: 'left', fontSize: '13px', lineHeight: '22px' }}>
                                                                {prdouct.description}
                                                                <div className='d-flex justify-content-between mt-1' >
                                                                    <p style={{ textAlign: 'left', fontSize: '17px', fontWeight: '500', color: 'black' }}> $ {prdouct.product_price}</p>
                                                                    {/* <h5 style={{ color: 'green', fontSize: '16px' }}>Save-<BiRupee />{Math.floor(Math.random() * 10000)}</h5> */}
                                                                </div>
                                                                <hr style={{ marginTop: "-3px" }}></hr>
                                                            </Card.Text>

                                                            <div className='d-flex justify-content-center mt-1 '>

                                                                <Button style={{ background: '#0F328E', borderColor: '#0F328E' }} onClick={() => navigate(`/productdetail/${prdouct.public_id}`)}>Buy Now</Button>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </>)
                                            })
                                        }
                                    </div>
                                </div>}
                            </div>}
                        </Col>
                    </Row>
                    {/* <div className="d-flex justify-content-between col-10" style={{ paddingLeft: "50px", marginTop: '50px' }}>
                    <div style={{ fontWeight: 'bold', color: '#0F328E', borderBottom: "2px solid #0F328E", width: '200px', textAlign: 'left' }}>All Products</div>
                    <div style={{ fontWeight: 'bold', display: 'flex', cursor: 'pointer' }} onClick={() => { navigate('/productlist') }}>View All</div>
                </div>
                <DashboardProduct /> */}
                    {/* <div className="d-flex justify-content-between col-10" style={{ paddingLeft: "50px", marginTop: '50px' }}>
                    <div style={{ fontWeight: 'bold', color: '#0F328E', borderBottom: "2px solid #0F328E", width: '200px', textAlign: 'left' }}>Top Brand</div>
                    <div style={{ fontWeight: 'bold', display: 'flex' }}>View All<MdOutlineArrowForwardIos style={{ fontSize: '14px', marginTop: '5px', color: '#4CB5E2' }} /></div>
                </div>
                <Brands /> */}
                </Container>
            </div>

        </>
    )

}
export default AdroitMainPage;