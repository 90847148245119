
import React, { useState } from 'react'
import { FaBars, FaTimes, FaUserCircle } from 'react-icons/fa';
import { FiLogOut } from "react-icons/fi";
import { HiChevronDown } from 'react-icons/hi';
import { AiFillDashboard } from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom';
import logo from '../Assets/images/adroitlogo.png'
import './Header.css'
import "../../node_modules/bootstrap/dist/css/bootstrap.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle";

const Header = () => {
let navigate  = useNavigate()
//setting mobile nav
    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)
    //change nav color while scrolling 
    const [color,setColor] = useState(false)
    const changeColor=()=>{
        if(window.scrollY >=80){
            setColor(true)
        }else{
            setColor(false)
        }
    }
    window.addEventListener('scroll',changeColor)
    // close menu on click
    const closeMenu = () => setClick(false)

    return (
        <div className={color ? 'header header-bg': 'header'} style={{zIndex:'999 ',height:'80px'}}>
            <nav className='navbar'>
                {/* <a href='/' className='logo'> */}
                <Link to={"/"}>
                    <img src={logo} alt='logo' style={{width:'140px',marginTop:'-16px'}}/>
                    </Link>
                {/* </a> */}
                <div className='hamburger' onClick={handleClick}>
                    {click ? (<FaTimes size={30} style={{ color: '#ffffff' }} />)
                        : (<FaBars size={30} style={{ color: '#ffffff' }} />)}

                </div>
                <ul className={click ? "nav-menu active" : "nav-menu"}>
                    <div className='nav-item'>
                    <Link to={"/home"} style={{ color: '#000', margin: '5px',textDecoration:'none' }} onClick={closeMenu}>Home</Link>
                    </div>
                    <div className='nav-item'>
                        <div class="dropdown">
                            <button class="dropbtn" style={{fontSize:'14px'}} >About Us<HiChevronDown /></button>
                            <div class="dropdown-content ">
                                <Link to='/aboutus' className='text-dark' ><a onClick={closeMenu}>About Group</a></Link> 
                                <Link to='/adroithistory' className='text-dark' onClick={closeMenu}> <a >Adroit's History</a></Link>
                                <Link to='/fundamentals' className='text-dark' onClick={closeMenu}> <a>Fundamentals</a>   </Link>
                                <Link to='/milestone' className='text-dark' onClick={closeMenu}><a>Milestone</a></Link>
                                <Link to='/leadership' className='text-dark' onClick={closeMenu}><a>Leadership</a></Link>
                                {/* <Link to='/milestone' className='text-dark' onClick={closeMenu}><a>Milestone</a></Link> */}
                                <Link to='/socialresponsiblity' className='text-dark' onClick={closeMenu}><a>Social Resposibility & Sustainability</a></Link>
                               
                                
                            </div>
                        </div>
                    </div>
                    {/* <div className='nav-item'>
                    <Link to={"/manufacture"} style={{ color: '#000', margin: '5px',textDecoration:'none' }} onClick={closeMenu}>Manufacturing</Link>
                     </div> */}
                    <div className='nav-item'>
                        <Link to={"/products"} style={{ color: '#000', margin: '5px', textDecoration: 'none' }} onClick={closeMenu}>Catalogue</Link>
                    </div>
                    <div className='nav-item'>
                        <div class="dropdown">
                            <button class="dropbtn" style={{ fontSize: '14px' }} >Technology<HiChevronDown /></button>
                            <div class="dropdown-content ">
                                <Link to='/engineering' className='text-dark' ><a onClick={closeMenu}>Engineering</a></Link>
                                <Link to='/manufacture' className='text-dark' onClick={closeMenu}> <a>Manufacturing</a></Link>
                                <Link to='/quality' className='text-dark' onClick={closeMenu}> <a>Quality</a>   </Link>
                                <Link to='/ourproduct' className='text-dark' onClick={closeMenu}><a>Our Products</a></Link>
                           
                            </div>
                        </div>
                    </div>
                    
                    <div className='nav-item'>
                    <Link to={"/technical"} style={{ color: '#000', margin: '5px',textDecoration:'none' }} onClick={closeMenu}>Technical</Link>
                    </div>
                    <div className='nav-item'>
                        <Link to={"/career"} style={{ color: '#000', margin: '5px', textDecoration: 'none' }} onClick={closeMenu}>Career</Link>
                    </div>
                    <div className='nav-item'>
                        <Link to={"/events"} style={{ color: '#000', margin: '5px', textDecoration: 'none' }} onClick={closeMenu}>Events</Link>
                    </div>
                    {/* <div className='nav-item'>
                        <Link style={{ color: '#000', margin: '5px', textDecoration: 'none' }} onClick={closeMenu}>Certifications</Link>
                    </div> */}
                    <div className='nav-item'>
                    <Link to='/contactus' style={{ color: '#000', margin: '5px',textDecoration:'none' }} onClick={closeMenu}>Contact Us</Link>
                     </div>
                    <div className='nav-item'>
                    {/* <Link to={"/login"} style={{ color: 'white', marginBottom: '5px',textDecoration:'none' }} onClick={closeMenu}> */}
                    <a rel="icon" href="http://signet_admin.radixforce.com/login">
                    <button className="btn" style={{ background: '#4CB5E2', color: "white" ,marginTop:'-4px'}} type="submit">Login</button>
                    </a>
                  
                    {/* </Link> */}
                    </div>
                    {/* <div className='nav-item'>
                        {localStorage.getItem('token') ? <div class="dropdown">
                            <button class="dropbtn" ><FaUserCircle/> Hi User<HiChevronDown /></button>
                            <div class="dropdown-content text-left">
                                <a onClick={() => { navigate('/app/home') }}><AiFillDashboard /> Dashboard</a>
                                <a onClick={() => { localStorage.clear(); navigate('/home') }}><FiLogOut/> Logout</a>
                              
                                
                            </div>
                        </div> : <Link to={"/login"} style={{ color: 'white', marginBottom: '5px', textDecoration: 'none' }} onClick={closeMenu}>
                            <button className="btn" style={{ background: '#4CB5E2', color: "white", marginTop: '-4px' }} type="submit">Login</button>
                        </Link>}
                    </div> */}
                </ul>
            </nav>
        </div>
    )
}

export default Header

// import React, { useState } from "react";
// import { Row } from "react-bootstrap";
// import { BsBuilding } from "react-icons/bs";
// import { FaBars, FaTimes } from 'react-icons/fa'
// import { Link, useNavigate } from "react-router-dom";
// import logo from '../Assets/images/adroitlogo.png'
// // import './style.css'
// import "./Header.css"
// function Header() {
//   //setting mobile nav
//     const [click, setClick] = useState(false)
//     const handleClick = () => setClick(!click)
//     //change nav color while scrolling 
//     const [color,setColor] = useState(false)
//     const changeColor=()=>{
//         if(window.scrollY >=90){
//             setColor(true)
//         }else{
//             setColor(false)
//         }
//     }
//     window.addEventListener('scroll',changeColor)
//     // close menu on click
//     const closeMenu = () => setClick(false)
//   let navigate = useNavigate()
//   let [navColor, setNavColor] = useState(0)
//   return (
//     <>
//       <Row>
//         <div >
//           <div  className={color ? 'header header-bg row': 'header row'} style={{ position: 'fixed', top: 0, right: 0, left: 0, zIndex: 1030, margin: '0px', padding: '0px' }} >
//             <div className="mx-auto col-10">

//               <nav className="navbar navbar-expand-lg" >
//                 <div className="container-fluid ">
//                   <a className="navbar-brand  text-white" href="#">
//                   <img src={logo} alt='logo' style={{height:'60px',width:'120px'}}/>
//                   </a>
//                   {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//                     <span className="navbar-toggler-icon"></span>
//                   </button> */}
//                    <div className='hamburger' onClick={handleClick}>
//                      {click ? (<FaTimes size={30} style={{ color: '#ffffff' }} />)
//                         : (<FaBars size={30} style={{ color: '#ffffff' }} />)}

//                 </div>
//                   <div className="collapse navbar-collapse " id="navbarSupportedContent">
//                     <ul className={click ? "nav-menu active navbar-nav ms-auto mb-2 mb-lg-0 " : "nav-menu navbar-nav ms-auto mb-2 mb-lg-0 "} >
//                       <li className="nav-item ">
//                         <a className="nav-link  " aria-current="page" style={{ color: navColor == 0 ? '#ff5f13' : '#fff' }}
//                           onClick={() => { navigate('/home'); setNavColor(0) }}>Home</a>
//                       </li>
                     
                      
//                       <li className="nav-item dropdown ">
//                         <a className="nav-link dropdown-toggle text-white" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"
//                           style={{ color: navColor == 2 ? '#ff5f13' : '#fff' }}
//                         >
//                           About us
//                         </a>
//                         <ul className="dropdown-menu">
//                           <li><a className="dropdown-item " href="#" onClick={() => { navigate('/recentevnet'); setNavColor(2) }}>Recent Events</a></li>
//                           <li><a className="dropdown-item" href="#" onClick={() => { navigate('/upcomingevnet'); setNavColor(2) }}>Upcomint Events</a></li>


//                         </ul>
//                       </li>

//                       <li className="nav-item ">
//                         <a className="nav-link " aria-current="page"
//                           style={{ color: navColor == 3 ? '#ff5f13' : '#fff' }}
//                           onClick={() => { navigate('/contact'); setNavColor(3) }}>Products</a>
//                       </li>

//                       <li className="nav-item ">
//                         <a className="nav-link  " aria-current="page"
//                           style={{ color: navColor == 4 ? '#ff5f13' : '#fff' }}
//                           onClick={() => { navigate('/gallery'); setNavColor(4) }}>Technology</a>
//                       </li>
//                       <li className="nav-item ">
//                         <a className="nav-link  " aria-current="page"
//                           style={{ color: navColor == 6 ? '#ff5f13' : '#fff' }}
//                           onClick={() => { navigate('/socialmediacorner'); setNavColor(6) }}>Manufacturing</a>
//                       </li>
//                       <li className="nav-item ">
//                         <a className="nav-link  " aria-current="page"
//                           style={{ color: navColor == 7 ? '#ff5f13' : '#fff' }}
//                           onClick={() => { navigate('/gallery'); setNavColor(7) }}>Application</a>
//                       </li>
//                       <li className="nav-item ">
//                         <a className="nav-link  " aria-current="page"
//                           style={{ color: navColor == 8? '#ff5f13' : '#fff' }}
//                           onClick={() => { navigate('/socialmediacorner'); setNavColor(8) }}>Contact us</a>
//                       </li>


//                       <li className="nav-item text-white">
//                         <a href="http://society-web.radixforce.com/admin/"><button className="btn" style={{ background: '#ff5f13', color: "white" }} type="submit">Login</button></a>
//                       </li>
//                     </ul>

//                   </div>
//                 </div>
//               </nav>
//             </div>
//           </div>
//         </div>
//       </Row>
//     </>
//   )
// }
// export default Header