import React from 'react'
import { Row, Col, Button } from "reactstrap";

const SocialResponsibility = () => {
    return (
        <div style={{ marginTop: '80px' }} className='d-flex justify-content-center'>
            <div className='background_contain'>
            <div className='d-flex justify-content-center mt-5 mb-5'>
                <Row style={{ background: '#E7ECF5' }} className='col-10'>

                    <Col lg={6} md={10} className='d-flex justify-content-center '  >
                            <img src={require("../Assets/images/about01.png")} alt="social" style={{ height: '360px', aspectRatio: '16/9' }}></img>
                    </Col>
                    <Col lg={6} md={12} className='d-flex flex-column justify-content-center p-3' >

                        <sapn style={{ fontSize: '20px', fontWeight: '500', color: '#4a4949', textAlign: 'left', }}>Social Responsibility and Sustainbility:</sapn>
                        <div className=" d-flex flex-column justify-content-center text-justify " >
                            <p style={{ fontSize: '14px', color: '#767676' }}>
                                Social responsibility and sustainability are key considerations for businesses in today&#39;s
                                world. Signet Group recognizes the importance of its impact on society and the
                                environment, and strive to integrate responsible practices into its operations. Here are
                                some aspects of social responsibility and sustainability that we focus on:
                            </p>
                            <ul style={{ fontSize: '14px', color: '#767676' }}>
                                <li><span style={{ color: '#4a4949', fontWeight: '500', fontSize: '16px' }}>Environmental Stewardship :</span><br></br>
                                    By investing in renewable energy sources, adopting
                                    eco-friendly technologies, and implementing waste management practices, we
                                    prioritize environmental stewardship and actively work towards minimizing the
                                    carbon footprint.</li>
                                <li><span style={{ color: '#4a4949', fontWeight: '500', fontSize: '16px' }}>Ethical Business Practices:</span><br></br>
                                    Maintaining high ethical standards in their business
                                    practices ensures transparency, integrity, and fairness in dealings with
                                    stakeholders. We strongly adhere to the policies against corruption,
                                    discrimination, and unfair labor practices.This commitment to ethical business
                                    practices fosters trust and credibility in the industry.</li>

                            </ul>

                        </div>
                    </Col>
                    <Col>
                        <ul style={{ fontSize: '14px', color: '#767676', textAlign: 'left' }}>
                            <li><span style={{ color: '#4a4949', fontWeight: '500', fontSize: '16px' }}>Employee Welfare :</span><br></br>
                                We place a strong emphasis on employee welfare, providing
                                a safe and healthy work environment, fair compensation, and opportunities for
                                growth and development. By promoting work-life balance and embracing
                                diversity and inclusion, we create a supportive workplace culture.</li>
                            <li><span style={{ color: '#4a4949', fontWeight: '500', fontSize: '16px' }}>Community Engagement :</span><br></br>
                                We actively engage with the communities in which
                                they operate. This involve supporting local initiatives, contributing to social
                                causes, and participating in community development programs. We encourage
                                employee volunteering and engagement in community service activities.</li>
                            <li><span style={{ color: '#4a4949', fontWeight: '500', fontSize: '16px' }}>Supply Chain Responsibility :</span><br></br>
                                We ensure that our supply chain adheres to ethical
                                and sustainable practices. This includes working with suppliers who uphold
                                social and environmental standards, promoting responsible sourcing of raw
                                materials, and monitoring and mitigating risks related to human rights and
                                environmental impacts.</li>
                            <li><span style={{ color: '#4a4949', fontWeight: '500', fontSize: '16px' }}>Product Responsibility :</span><br></br>
                                We prioritize product responsibility by focusing on the
                                safety, quality, and sustainability of our products. Investing in research and
                                development for innovative and environmentally friendly products demonstrates
                                our dedication to providing sustainable solutions to our global customers.</li>
                        </ul>
                    </Col>
                </Row>
            </div>
            </div>
        </div>
    )
}

export default SocialResponsibility
