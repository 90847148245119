import React from 'react'
import { Row, Col, Button } from "reactstrap";

const Leadership = () => {
    return (
        <div style={{ marginTop: '120px', marginBottom: '50px', fontFamily: 'roboto' }}>
            <div className='background_contain'>
                <p style={{ fontSize: '32px', fontWeight: '500' }}>Leadership</p>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Row style={{ background: "#F2F7FA" }} className='col-10 mt-5' >
                        <Col lg={4} md={10} className='d-flex justify-content-center p-3 align-items-center'  >
                            <img src={require('../../src/Assets/images/mukeshji.png')} alt="leadership"></img>
                        </Col>
                        <Col lg={8} md={12} className='d-flex flex-column justify-content-center ' >

                            <div className=" d-flex flex-column justify-content-center text-justify p-1 ps-3" >
                                <p style={{ fontSize: '15px', color: '#767676' }}>
                                    <span style={{ fontSize: '20px', fontWeight: '500', color: '#4A4949' }} >Mukesh Sangla :</span>
                                    <br></br> Mr. Mukesh Sangla, a graduate of Ujjain University, serves as the dynamic and visionary the
                                    Chairman of the Signet Group. With a rich background in diverse industries such as petrochemicals,
                                    polymers, plastics, and auto components like drive shafts, Mr. Sangla has accumulated over four
                                    decades of experience as a first-generation entrepreneur. Under his visionary leadership, Signet
                                    has emerged as a prominent polymer distributor in Central India and has swiftly established a strong
                                    presence in the plastic manufacturing sector.<br></br>
                                    Renowned for his expertise in the field of polymers, Mr. Sangla's financial acumen and strategic
                                    decision-making have been instrumental in ensuring the overall success of the company. He brings
                                    a hands-on approach to his role, effectively driving the performance of the internal team and fostering
                                    a culture of systematic organization. Despite embarking on his entrepreneurial journey at a young age, Mr.
                                    Sangla initially ventured into coal and book distribution before diversifying into various other business
                                    streams and excelling in them.<br></br>

                                </p>
                            </div>
                        </Col>

                    </Row>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Row style={{ background: "#F2F7FA" }} className='col-10 mt-5'>

                        <Col lg={8} md={12} className='d-flex flex-column justify-content-center p-2 pt-3 pe-3' >
                            {/* <h2 className="title text-left">Leadership:</h2> */}
                            <div className=" d-flex flex-column justify-content-center text-justify " >
                                <p style={{ fontSize: '15px', color: '#767676' }}>
                                    <span style={{ fontSize: '20px', fontWeight: '500', color: '#4A4949' }} >Saurabh Sangla:</span>

                                    <br></br>  Saurabh Sangla is a second-generation entrepreneur and currently serves as the
                                    Executive Director at Signet Industries Ltd. and Managing Director at Adroit Industries
                                    Limited. He obtained his Bachelor of Science degree in Industrial Engineering and
                                    Operations Research from the University of California, Berkeley in 2002. Notably, he
                                    holds the distinction of being one of the world's fastest Six Sigma Certified Champion.<br></br>
                                    Saurabh Sangla has extensive experience of over 20 years in various industries,
                                    including torque transmission, automotive components, polymers, plastic products, and
                                    modern irrigation techniques. He has a strong passion for innovation, embracing new
                                    technologies, and nurturing young talent. He believes in challenging norms and
                                    promoting system automation.<br></br>
                                    Under his guidance and leadership, both Signet Industries Ltd. and Adroit Industries
                                    Limited have achieved remarkable success. Adroit has developed a diverse range of
                                    over 4000 torque transmission components and assemblies, as well as more than 1500
                                    products in Signet. His commitment to excellence has been recognized globally, leading
                                    to numerous awards for leadership and quality.<br></br>
                                    Additionally, he has been actively involved in industry associations. He served as the
                                    immediate past Chairman of the Confederation of Indian Industry (CII) Madhya Pradesh
                                    and currently holds the position of Co-Chairman for International Business in the
                                    Western Region of CII. His contributions in these roles have furthered the development
                                    and growth of the business community.
                                </p>
                            </div>
                        </Col>
                        <Col lg={4} md={10} className='d-flex justify-content-center p-4 align-items-center'  >
                            <img src={require('../../src/Assets/images/saurabhji.png')} alt="leadership" style={{ height: '90%', aspectRatio: "" }}></img>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default Leadership

