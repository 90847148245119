import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { BsCartPlus } from "react-icons/bs";
import { HiInboxIn } from "react-icons/hi"
import { TbBox } from "react-icons/tb"
import { SlSocialDropbox } from "react-icons/sl"
import { RiDeleteBin6Line } from "react-icons/ri"
import { Row, Col, Container, Card, Input, Button } from 'reactstrap'
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Modal } from "react-bootstrap";
import ExcelDownload from './ExcelDownload';
function BulkImport(props) {
    const dispatch = useDispatch()
    let navigate = useNavigate()
    const [show, setShow] = useState(false);
    let [loading, setLoading] = useState(false)
    const [compareProd, setCompareProd] = useState([])
    const [bulklist, setBulklist] = useState([])
    const [deleteCartLoading, setDeleteCartLoading] = useState({})
    const [needsReload, setNeedsReload] = useState(false)
    const [selectAll, setSelectAll] = useState(false)

    // const [loading, setLoading] = useState(false);
    const ExcleExportData = [
        {
            "Part Number": "",
            Quantity: "",
            "Requested Shipping Date(DD/MM/YYYY)": ""
        }
    ]

    useEffect(() => {
        getbulkcart()
    }, [needsReload]);

    const getbulkcart = () => {
        // setNeedsReload(true)
        if (localStorage.getItem('token')) {
            let url = process.env.REACT_APP_BASEURL_TWO + 'bulk-import/';
            const config = {
                headers: {
                    Authorization: localStorage.getItem('token'),
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                },
            };
            axios.get(url, config)
                .then((res) => {
                    // setLoading(false);
                    setBulklist(res.data.data);
                    console.log(">>>>>>>>>>>>>", res.data.data)
                    setNeedsReload(false)
                    // dispatch(updateCartQty(res.data.data.item_list.length))
                })
                .catch((err) => {
                    // setLoading(false);
                    setNeedsReload(false)
                });
        } else {

            toast.error('Your session has expired. Please login again.', {
                autoClose: 1000,
                theme: 'colored',
            });
        }
    };

    const deleteCart = (publicIds) => {
        // Here we'll use Promise.all to make multiple delete requests concurrently
        Promise.all(
            publicIds.map((public_id) => {
                // Set loading state for each item to true
                setDeleteCartLoading((prevCartLoading) => ({
                    ...prevCartLoading,
                    [public_id]: true,
                }));

                return axios.delete(
                    process.env.REACT_APP_BASEURL_TWO + `bulk-import-remove/${public_id}/`,
                    {
                        headers: {
                            Authorization: localStorage.getItem('token'),
                            'Access-Control-Allow-Origin': '*',
                            'Content-type': 'application/json',
                        },
                    }
                );
            })
        )
            .then((responses) => {
                // All delete requests are successful
                setLoading(false);
                setNeedsReload(true);
                getbulkcart();
            })
            .catch((error) => {
                // Handle error if any of the delete requests fails
                setLoading(false);
            })
            .finally(() => {
                // Reset the loading state for all items after deletion
                publicIds.forEach((public_id) => {
                    setDeleteCartLoading((prevCartLoading) => ({
                        ...prevCartLoading,
                        [public_id]: false,
                    }));
                });
            });
    };

    const handleRemoveClick = () => {
        // Get the public_ids of selected items to remove
        const selectedItems = bulklist.filter((item) => compareProd.includes(item));
        const publicIdsToRemove = selectedItems.map((item) => item.public_id);
        // Call the modified deleteCart function with the public_ids to remove
        deleteCart(publicIdsToRemove);
        setTimeout(() => {
            setSelectAll(false)
            setCompareProd([]);
        }, 1000)

    };
    const checkboxClick = (item, e) => {
        const isChecked = e.target.checked;

        if (isChecked) {

            setCompareProd((prevCompareProd) => [...prevCompareProd, item]);
        } else {
            setCompareProd((prevCompareProd) =>
                prevCompareProd.filter((prevItem) => prevItem.public_id !== item.public_id)
            );

        }
    };

    //select all  function 

    const toggleSelectAll = () => {
        setSelectAll((prevSelectAll) => !prevSelectAll);

        if (!selectAll) {
            // Select all items
            setCompareProd([...bulklist]);
        } else {
            // Deselect all items
            setCompareProd([]);
        }
    };
    // const checkboxClick = (item, e) => {
    //     if (e.target.checked === true) {
    //         setCompareProd((compareProd) => [...compareProd, item])
    //         console.log(compareProd)
    //     }
    //     console.log(e.target.checked)

    // }
    const checkUserCompare = () => {
        // setLoading(true)
        const compareData = compareProd.map((obj) => ({
            'product_id': parseInt(obj.product_public_id),
            'quantity': parseInt(obj.quantity),

        }));
        // setSendPublicId(compareData)
        let url = process.env.REACT_APP_BASEURL_TWO + 'cart/';
        const config = {
            headers: {
                Authorization: localStorage.getItem('token'),
                'Access-Control-Allow-Origin': '*',
                'Content-type': 'application/json',
            },
        };
        const body = {
            cart_data: compareData
        };
        axios
            .post(url, body, config)
            .then((res) => {
                // setLoading(false);

                navigate('/showallcart')
            })
            .catch((err) => {
                // setLoading(false);
            })

    }

    return (<>
        <div className="d-flex justify-content-between col-10 mt-5" style={{ paddingLeft: "50px" }}>
            <div style={{ color: '#797979', width: '200px', textAlign: 'left' }}>Home <IoIosArrowForward style={{ color: '#008ECC' }} /><span style={{ color: '#0F328E' }}> Bulk Import</span></div>
            {/* <div style={{ display: 'flex', }}>View as <BsGrid style={{ color: '#4CB5E2', marginTop: "6px", marginLeft: '5px' }} /> <GiHamburgerMenu style={{ color: '#4CB5E2', marginTop: "5px", marginLeft: '5px', fontSize: '18px' }} /> </div> */}
        </div>
        <Row className="  d-flex justify-content-center" style={{ fontFamily: 'roboto', alignItems: 'center' }}>
            {/* <Col lg={3} md={5} className='mt-3' >
                <div className="mb-3 d-flex justify-content-start ms-2"><span style={{ border: '1px solid #EDEDED', padding: '4px 10px 4px 10px', margin: '3px' }}><HiInboxIn />  Create List</span> <span style={{ border: '1px solid #EDEDED', padding: '4px 10px 4px 10px', margin: '3px' }}><TbBox /> Manage List</span></div>
                <aside className="card" style={{ height: "80vh", marginRight: '18px' }}>
                    <ul style={{ listStyleType: 'none', textAlign: 'left', }}>
                        <li className="me-2 mt-2 pl-2" ><Input type="text" placeholder="Search List" > </Input></li>
                        <li className="mt-4  d-flex justify-content-between"><div style={{ fontSize: '13px', paddingLeft: "10px" }}>PO 3984(20)</div> <div><IoIosArrowForward className="me-3" style={{ fontSize: '13px' }} /></div> </li>
                        <li className="mt-4  d-flex justify-content-between"><div style={{ fontSize: '13px', paddingLeft: "10px" }}>PO 3950(25)</div> <div><IoIosArrowForward className="me-3" style={{ fontSize: '13px' }} /></div> </li>
                        <li className="mt-4  d-flex justify-content-between"><div style={{ fontSize: '13px', paddingLeft: "10px" }}>PO 3930(20)</div> <div><IoIosArrowForward className="me-3" style={{ fontSize: '13px' }} /></div> </li>
                        <li className="mt-4  d-flex justify-content-between"><div style={{ fontSize: '13px', paddingLeft: "10px" }}>PO 3948 Stock Order(20)</div> <div><IoIosArrowForward className="me-3" style={{ fontSize: '13px' }} /></div> </li>
                        <li className="mt-4  d-flex justify-content-between"><div style={{ fontSize: '13px', paddingLeft: "10px" }}>Jan 24 Stock Order(28)</div> <div><IoIosArrowForward className="me-3" style={{ fontSize: '13px' }} /></div> </li>
                        <li className="mt-4  d-flex justify-content-between"><div style={{ fontSize: '13px', paddingLeft: "10px" }}>Jan 20 Stock Order(50)</div> <div><IoIosArrowForward className="me-3" style={{ fontSize: '13px' }} /></div> </li>
                        <li className="mt-4  d-flex justify-content-between"><div style={{ fontSize: '13px', paddingLeft: "10px" }}>Feb 15 Stock Order(50)</div> <div><IoIosArrowForward className="me-3" style={{ fontSize: '13px' }} /></div> </li>
                        <li className="mt-4  d-flex justify-content-between"><div style={{ fontSize: '13px', paddingLeft: "10px" }}>Feb 20 Stock Order(60)</div> <div><IoIosArrowForward className="me-3" style={{ fontSize: '13px' }} /></div> </li>

                    </ul>

                </aside>
            </Col> */}
            <Col lg={9} md={6} className=' mt-2  ' style={{ paddingLeft: '0.5rem', paddingRight: '1.25rem' }}>
                <Row style={{ background: '#F4F4F4', padding: '12px', boxShadow: '0px 6px 4px 1px grey' }}>
                    <Row className=" mb-2 d-flex justify-content-start ">
                        <Col className="d-flex justify-content-start" sm={12} lg={3} onClick={() => setShow(true)}>  <Button style={{ border: '1px solid #EDEDED', color: '#000', background: 'white', cursor: 'pointer' }}><SlSocialDropbox /> Add/Upload Parts</Button> </Col>
                        <Modal show={show} onHide={() => { setShow(false) }} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton style={{ backgroundColor: '#fff' }}>
                            </Modal.Header>
                            <Modal.Body closeButton>
                                {/* {stepModal === 1 ? <BulkExcel sendstep={setStepmodal} /> : stepModal === 2 ?
                                    <BulkTable sendstep2={setStepmodal} /> : stepModal === 3 ?
                                        <BulkConfirm /> : "null"} */}

                                {/* <BulkExcel closeModal={setShow} reload={setNeedsReload} /> */}
                                <Container style={{ fontFamily: 'roboto' }}>
                                    <Row >
                                        <p className='d-flex justify-content-center' style={{ fontWeight: '500' }}>Download & Upload Excel File</p>
                                        <Col lg={12}>
                                            <div className='d-flex  mt-3'>
                                                <ExcelDownload excelData={ExcleExportData} sendClose={setShow} reloading={setNeedsReload} fileName={"file"} />
                                            </div>
                                        </Col>

                                    </Row>
                                </Container>
                            </Modal.Body>

                        </Modal>
                        <Col></Col>
                        {/* <Col className="d-flex justify-content-start" sm={12} lg={3}> <span><Input type="text" placeholder="Search Part No/Part Name" className="ms-2 me-2" /></span>   </Col> */}
                        <Col className="d-flex justify-content-start" sm={12} lg={3}><Button onClick={handleRemoveClick} style={{ background: '#fff', border: '1px solid #EDEDED', color: '#000' }}><RiDeleteBin6Line style={{ width: '20px', height: '20px' }} />&nbsp;&nbsp;Remove</Button></Col>
                        <Col className="d-flex justify-content-end" sm={12} lg={3}>{compareProd.length > 0 ? <Button onClick={() => { checkUserCompare() }} style={{ background: '#fff', border: '1px solid #EDEDED', color: '#000' }}><BsCartPlus style={{ width: '20px', height: '20px' }} />&nbsp;&nbsp;Add to Cart</Button> :
                            <Button style={{ background: '#fff', border: '1px solid #EDEDED', color: '#000' }} disabled><BsCartPlus style={{ width: '20px', height: '20px' }} />&nbsp;&nbsp;Add to Cart</Button>}</Col>
                        {/* <Col className="d-flex justify-content-start" sm={12} lg={3}>  <button  onClick={handleRemoveClick} ><RiDeleteBin6Line /> Remove</button>    </Col> */}
                        {/* <Col className="d-flex justify-content-start" style={{ cursor: 'pointer' }} sm={12} lg={3} onClick={() => { checkUserCompare() }}> <span style={{ border: '1px solid #EDEDED', padding: '4px 10px 4px 10px', background: 'white', marginLeft: '20px' }} ><BsCartPlus /> Add to Cart</span>    </Col> */}
                    </Row>
                    <Card className="p-2 d-flex flex-column flex-wrap" style={{ border: 'none' }} >
                        <Row>
                            {bulklist.length === 0 ? null : <div className="d-flex">
                                <Input
                                    type="checkbox"
                                    checked={selectAll}
                                    onChange={toggleSelectAll}
                                    id="selectAllCheckbox"
                                />
                                <span className="ms-4">Select All</span>
                            </div>}

                            {bulklist.length === 0 ? <div className="d-flex justify-content-center mx-auto mt-5"><div className="text-center fs-4 fw-bold">No Data Found</div></div> :
                                bulklist.map((item) => {
                                    return (<>

                                        <Col lg={4}>
                                            <Card className="m-2">
                                                <Row>
                                                    <Col lg={3} style={{ backgroundColor: '#DDF1FA' }} className='py-2 ps-2'><Input type="checkbox" style={{ border: "1px solid #4CB5E2", }} onChange={(e) => { checkboxClick(item, e) }}
                                                        checked={compareProd.some((cmpItem) => cmpItem.public_id === item.public_id)}
                                                    ></Input>
                                                        <img src={item.image} className='mt-2 ' style={{ width: '90%' }} alt="img"></img>
                                                    </Col>
                                                    <Col lg={8} className='py-2 ms-3' style={{ textAlign: 'left' }} ><span style={{ color: '#0F328E', fontWeight: 'bold' }}>{item.part_number}</span><br></br>
                                                        <span className="ms-1">Qty</span>   <span className="ms-4">Req Ship Date</span><br></br>
                                                        <div className="py-2"><span style={{ border: '1px solid #D9D9D9', padding: ' 1px 13px' }}>{item.quantity}</span>   <span style={{ border: '1px solid #D9D9D9', padding: '1px  17px' }}>{item.requested_ship_date}</span></div>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>


                                    </>)
                                })
                            }
                        </Row>

                    </Card>
                </Row>

            </Col>

        </Row>
    </>)
}
export default BulkImport;

