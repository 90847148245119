import React from "react";
import Milestone from '../Assets/images/companyMileStone.svg'
import { Row, Col, Button } from "reactstrap";
function MileStone(){
return(
    <>
        <Row className="mt-5 p-5 d-flex justify-content-center">
            <Col className="mt-2">
                <div>
                    <img src={Milestone} style={{ width: "100vw" }} alt="milestone"></img>
                </div>
            </Col>
        </Row>
    </>
)
}
export default MileStone;