import React from 'react'
import { Row, Col, Button } from "reactstrap";

const Adroithistory = () => {
    return (
        <div className='background_contain'>
        <div className='d-flex justify-content-center' style={{marginTop:'100px',marginBottom:'50px'}}>
           
            <Row style={{ backgroundColor: '#d1cdcd3d', }} className='col-10'>
                <Col lg={6} md={10} className='d-flex justify-content-center '  >
                        <img src={require('../../src/Assets/images/adroitHistory.png')} alt="history" style={{ height: '360px', aspectRatio: '16/9' }}></img>

                </Col>

                <Col lg={6} md={12} className='d-flex flex-column justify-content-center p-3 mt-4' >
                 <span style={{ fontSize: '20px', fontWeight: '500', color: '#4a4949', textAlign: 'left' }}> Adroit History</span>
                    <div className=" d-flex flex-column justify-content-center text-justify " >
                        <p className="about-inner-content" style={{ fontSize: '14px', color: '#767676' }}>
                            Adroit has a rich history that spans several decades. It was <b>established in 1966 </b>
                            and has grown to become one of the largest propeller shaft and component
                            manufacturing plants in India. The company initially started as a small
                            manufacturing setup with the aim of serving the Indian auto industry. It quickly
                            gained recognition and expanded its business nationwide, primarily catering to
                            the after-market industry.<br></br>
                            In the mid-1970s, Adroit expanded its customer base by supplying products to
                            the Indian Railways and the Indian Military. However, in the early 1980s, the
                            company faced a major setback when its factory was set on fire during a period
                            of local political instability. Despite this setback, Adroit emerged stronger and
                            invested in modern infrastructure to enhance the quality of its products.<br></br>
                          
                        </p>
                    </div>
                </Col>
                <Col style={{ fontSize: '14px', color: '#767676',textAlign:'left' }}>  With the advent of the new foreign policy in the early 1990s, Adroit seized the
                    opportunity to explore international markets. The company received its first-ever
                    export order from the United Kingdom, which encouraged further expansion. The
                    United States also welcomed Adroit as a quality supplier, prompting the company
                    to invest in a forging plant for backward integration. This integration proved
                    crucial in strengthening the company&#39;s quality control measures.<br></br>

                    At the turn of the century, the Sangla Family took over Adroit, marking a
                    significant turning point for the company. Under their leadership, Adroit
                    experienced substantial growth and became a system-driven organization. The
                    company adopted new modern technologies at its plants, earning accolades from
                    clients. Emphasizing new product development, aesthetics, and maintaining
                    consistent quality became key priorities for Adroit.<br></br>
                    Over the years, these efforts have shaped Adroit into what it is today. In 2022,
                    the company expanded its production facilities, enabling it to manufacture
                    approximately 5000 components per day. <b>Adroit takes pride in having the
                        second-largest range of cardan shafts and components made from
                        forgings in the world.</b></Col>
            </Row>
         </div>
        </div>
    )
}

export default Adroithistory
