import React from "react";
import { Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { MdOutlineArrowForwardIos } from "react-icons/md";
function HomeSKeleton() {
    return (
        <>
            <div style={{ marginTop: '80px', marginBottom: '40px' }}>
                <div style={{ width: "75vw", paddingLeft: '50px' }} >
                    <Skeleton className="  d-block w-80 " style={{ height: '306px' }}></Skeleton>
                </div>
                <div className="d-flex justify-content-between col-10 mt-5" style={{ paddingLeft: "50px" }}>
                    <div style={{ fontWeight: 'bold', color: '#0F328E', borderBottom: "2px solid #0F328E", width: '200px', textAlign: 'left' }}>All Categories</div>
                    <div style={{ fontWeight: 'bold', display: 'flex', cursor: 'pointer' }}>View All</div>
                </div>
                <div className='row mt-5' style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                    <div class=" flex-wrap d-flex justify-content-start " >

                        <Col lg={2} md={2} sm={3} style={{ margin: '5px' }} className='d-flex flex-column'  >
                            <Skeleton style={{ background: '#F5F5F5', borderRadius: '50%', padding: '15px', height: '120px', maxWidth: '120px', boxShadow: "0px 97px 39px rgba(0, 0, 0, 0.01), 0px 54px 33px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.04), 0px 6px 13px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)" }}></Skeleton>
                        </Col>
                        <Col lg={2} md={2} sm={3} style={{ margin: '5px' }} className='d-flex flex-column'  >
                            <Skeleton style={{ background: '#F5F5F5', borderRadius: '50%', padding: '15px', height: '120px', maxWidth: '120px', boxShadow: "0px 97px 39px rgba(0, 0, 0, 0.01), 0px 54px 33px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.04), 0px 6px 13px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)" }}></Skeleton>
                        </Col>
                        <Col lg={2} md={2} sm={3} style={{ margin: '5px' }} className='d-flex flex-column'  >
                            <Skeleton style={{ background: '#F5F5F5', borderRadius: '50%', padding: '15px', height: '120px', maxWidth: '120px', boxShadow: "0px 97px 39px rgba(0, 0, 0, 0.01), 0px 54px 33px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.04), 0px 6px 13px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)" }}></Skeleton>
                        </Col>
                        <Col lg={2} md={2} sm={3} style={{ margin: '5px' }} className='d-flex flex-column'  >
                            <Skeleton style={{ background: '#F5F5F5', borderRadius: '50%', padding: '15px', height: '120px', maxWidth: '120px', boxShadow: "0px 97px 39px rgba(0, 0, 0, 0.01), 0px 54px 33px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.04), 0px 6px 13px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)" }}></Skeleton>
                        </Col>
                        <Col lg={2} md={2} sm={3} style={{ margin: '5px' }} className='d-flex flex-column'  >
                            <Skeleton style={{ background: '#F5F5F5', borderRadius: '50%', padding: '15px', height: '120px', maxWidth: '120px', boxShadow: "0px 97px 39px rgba(0, 0, 0, 0.01), 0px 54px 33px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.04), 0px 6px 13px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)" }}></Skeleton>
                        </Col>

                    </div>
                </div>
                <div className="d-flex justify-content-between col-10" style={{ paddingLeft: "50px", marginTop: '50px' }}>
                    <div style={{ fontWeight: 'bold', color: '#0F328E', borderBottom: "2px solid #0F328E", width: '200px', textAlign: 'left' }}>All Products</div>
                    <div style={{ fontWeight: 'bold', display: 'flex', cursor: 'pointer' }} >View All</div>
                </div>
                <div className='row  col-12' style={{ paddingLeft: "40px" }}>
                    <div class=" flex-wrap d-flex justify-content-start " >
                        <Skeleton style={{ width: '230px', height: '330px', borderColor: '#A9ADB0', borderRadius: '16px', boxShadow: "0px 97px 39px rgba(0, 0, 0, 0.01), 0px 54px 33px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.04), 0px 6px 13px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)", margin: '10px' }}></Skeleton>
                        <Skeleton style={{ width: '230px', height: '330px', borderColor: '#A9ADB0', borderRadius: '16px', boxShadow: "0px 97px 39px rgba(0, 0, 0, 0.01), 0px 54px 33px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.04), 0px 6px 13px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)", margin: '10px' }}></Skeleton>
                        <Skeleton style={{ width: '230px', height: '330px', borderColor: '#A9ADB0', borderRadius: '16px', boxShadow: "0px 97px 39px rgba(0, 0, 0, 0.01), 0px 54px 33px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.04), 0px 6px 13px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)", margin: '10px' }}></Skeleton>
                        <Skeleton style={{ width: '230px', height: '330px', borderColor: '#A9ADB0', borderRadius: '16px', boxShadow: "0px 97px 39px rgba(0, 0, 0, 0.01), 0px 54px 33px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.04), 0px 6px 13px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)", margin: '10px' }}></Skeleton>
                        
                    </div>
                </div>
                <div className="d-flex justify-content-between col-10" style={{ paddingLeft: "50px", marginTop: '50px' }}>
                    <div style={{ fontWeight: 'bold', color: '#0F328E', borderBottom: "2px solid #0F328E", width: '200px', textAlign: 'left' }}>Top Brand</div>
                    <div style={{ fontWeight: 'bold', display: 'flex' }}>View All<MdOutlineArrowForwardIos style={{ fontSize: '14px', marginTop: '5px', color: '#4CB5E2' }} /></div>
                </div>

            </div><div style={{ width: "75vw", paddingLeft: '50px' }} >
                <Skeleton className="  d-block w-80 " style={{ height: '306px' }}></Skeleton>
            </div>
        </>
    )
}
export default HomeSKeleton;